import React from "react";

type S = HTMLElement & { value: any };

export type UseInputHandler<
  T extends S = HTMLInputElement | HTMLTextAreaElement
> = {
  value: string;
  onChange: (v: React.ChangeEvent<T> | string) => void;
};

export type UseInputOptions = {
  transform?: (v: string) => any;
  validate?: (v: string) => boolean;
  onError?: (v: string) => void;
};

const useInput = <T extends S = HTMLInputElement | HTMLTextAreaElement>(
  initialValue: string = "",
  {
    transform = (v: string) => v,
    validate = () => true,
    onError = () => {},
  }: UseInputOptions = {}
): UseInputHandler<T> => {
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e: React.ChangeEvent<T> | string) => {
    const value = typeof e === "string" ? e : e.target.value;
    if (validate(value)) setValue(transform(value));
    else onError(value);
  };
  return { value, onChange };
};

export default useInput;
