import PremiumCard from '@/components/premium-card';
import { usePricing } from '@/hooks/usePricing';
import useR from '@/hooks/useR';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface IPremiumPaywallProps {
    open: boolean;
    setOpen(open: boolean): void;
}

const PremiumPaywall: React.FunctionComponent<IPremiumPaywallProps> = (props) => {
    const { company } = useR()
    const pricing = usePricing({ premium: true });
    const navigate = useNavigate()

    React.useEffect(() => {
        if (props.open) {
            navigate("/app/premium")
        }
    }, [props.open])

    return null;
    /*if (!props.open) return null
    return <div
        className="fixed inset-0 flex justify-center items-center bg-background_dark/40 z-20 backdrop-blur cursor-pointer"
        onClick={() => props.setOpen(false)}
    >
        <div className="w-[800px] h-[500px] border border-background_light px-10 py-8 bg-konnectz_background rounded-2xl flex-col justify-start items-start gap-6 inline-flex">
            <div className="flex justify-between items-center w-full">
                <div className="text-white text-xl font-semibold tracking-tight">
                    Débloquez les fonctionnalités Premium
                </div>

            </div>
            <div>
                <div className="text-white text-lg font-semibold tracking-tight">

            </div>

        </div>
    </div>*/
};

export default PremiumPaywall;
