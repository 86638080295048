import { useQuery } from "@tanstack/react-query";
import { getCategories } from "@/service/timenjoy.service";

export interface IUseCategoriesParams {
  event?: boolean;
  immo?: boolean;
  emploi?: boolean;
}
export function useCategories(params: IUseCategoriesParams) {
  return useQuery({
    queryKey: ["categories", params],
    queryFn: async () => {
      const categories = await getCategories();
      return categories.filter((category) => {
        const isImmo = category.name.toLowerCase().startsWith("immo");
        const isEmploi = category.name.toLowerCase().startsWith("emploi");
        if (params.immo && isImmo) return true;
        if (params.emploi && isEmploi) return true;
        if (params.event && !isImmo && !isEmploi) return true;
        return false;
      });
    },
    refetchInterval: 1000 * 60 * 60,
  });
}
