import { Pricing } from "@/hooks/usePricing";
import useR from "@/hooks/useR";
import { createPaymentCompany } from "@/service/payment.service";
import { isPlanPremium } from "@/utils/isPlanPremium";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export function useStartTrial() {
  const navigate = useNavigate();
  const toast = useToast();
  const { company, user, client } = useR();
  return useMutation({
    async mutationFn(plan: Pricing) {
      if (!company || !user) throw new Error("No company");
      return createPaymentCompany(company, user.email, plan.subscriptionId);
    },
    onSuccess(data, variables) {
      client.setQueryData(["subscription", company?.id], data);
      navigate("/onboarding/widget");
      if (!isPlanPremium(variables)) return;
      toast({
        title: "Votre essai gratuit a commencé",
        description:
          "Vous pouvez désormais profiter de toutes les fonctionnalités de Konnectz pendant 30 jours.",
        status: "success",
        isClosable: true,
        duration: 5000,
      });
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      console.error(error);
      toast({
        title: "Une erreur est survenue",
        description:
          "Veuillez réessayer ultérieurement. Si le problème persiste, contactez-nous.",
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    },
  });
}
