import { useEffect } from "react";

export interface IUseKeyboardParams {
    [key: string]: (event: KeyboardEvent) => void;
}
export function useKeyboard(params: IUseKeyboardParams) {
    const handleKeyDown = (event: KeyboardEvent) => {
        if (params[event.key]) {
            params[event.key](event);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
}