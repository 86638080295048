import { Pricing } from '@/hooks/usePricing';
import { CheckIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import * as React from 'react';
import Button from '../button';
import { useNavigate } from 'react-router-dom';
import useR from '@/hooks/useR';
import { usePermission } from '@/hooks/usePermissions';
import { useStartTrial } from '@/mutations/start-trial';
import { isPlanPremium } from '@/utils/isPlanPremium';

interface IPremiumCardProps {
    plan: Pricing;
    overridedCta?: React.ReactNode;
}

const PremiumCard: React.FunctionComponent<IPremiumCardProps> = ({ plan, overridedCta }) => {
    const navigate = useNavigate();
    const { isFreemium } = usePermission()
    const trial = useStartTrial()
    const isHighlited = plan.name === "Standard"
    function checkout() {
        if (plan.cta!.external) return window.open(plan.cta!.link)
        navigate(plan.cta!.link)
    }
    async function startTrial() {
        await trial.mutateAsync(plan)
        navigate("/app")
    }
    return <div
        key={plan.name}
        className={clsx(
            "relative flex-1 flex items-stretch flex-col rounded-xl border mt-6 sm:mt-0 bg-konnectz_background",
            isHighlited ? "border-konnectz_accent" : "border-gray-800"
        )}
        style={{
            backgroundImage:
                isHighlited
                    ? "radial-gradient(130.39% 130.39% at 51.31% -0.71%, #1F2937 0%, rgba(31, 41, 55, 0) 100%)"
                    : undefined,
        }}
    >
        <div className="p-8 space-y-4 border-b border-gray-800 text-center">
            <span className="text-konnectz_accent font-semibold">{plan.name}</span>
            <div className="text-title text-3xl font-semibold">
                €{plan.price}{" "}
                <span className="text-xl text-gray-400 font-normal">
                    /mois
                </span>
            </div>
            <p className="text-subtitle">{plan.description}</p>
        </div>
        <div className="p-8 flex flex-col justify-between h-full">
            <ul className="space-y-3">
                {plan.features.map((feature) => (
                    <li
                        key={feature.label}
                        className="flex items-center gap-5 text-subtitle"
                    >
                        <CheckIcon
                            className={clsx(
                                "w-5 h-5",
                                feature.checked ? "text-konnectz_accent" : "text-konnectz_accent/30"
                            )}
                        />
                        {feature.label}
                    </li>
                ))}
            </ul>
            <div className={clsx("pt-8", isPlanPremium(plan) || "hidden")}>
                {overridedCta ?? (isFreemium ?
                    (<Button
                        className="w-full"
                        children="Essayer gratuitement"
                        variant="tertiary"
                        onClick={startTrial}
                    />) :
                    (plan.cta && (
                        <Button
                            className="w-full"
                            children={plan.cta.label}
                            variant="tertiary"
                            onClick={checkout}
                        />
                    )))}
            </div>
        </div>
    </div>
};

export default PremiumCard;
