import dayjs from "dayjs";
type T = Parameters<typeof dayjs>[0];

export function displayDatetimeRange(_start: T, _end: T) {
    const start = dayjs(_start)
    const end = dayjs(_end)
    const now = dayjs()
    const isSameDay = start.isSame(end, "day");
    const isSameMonth = start.isSame(end, "month");
    const isToday = start.isSame(now, "day");
    const hasStarted = start.isBefore(now);
  
    if (isToday) {
      if (isSameDay) {
        if (hasStarted) {
          return `Aujourd'hui jusqu'à ${end.format("HH:mm")}`;
        } else {
          return `Aujourd'hui à ${start.format("HH:mm")}`;
        }
      } else {
        if (hasStarted) {
          return `Jusqu'au ${end.format("DD MMM à HH:mm")}`;
        }
        return `Aujourd'hui à ${start.format("HH:mm")} au ${end.format(
          "DD MMM à HH:mm"
        )}`;
      }
    } else {
      if (isSameDay) {
        return `Le ${start.format("DD MMM YYYY à HH:mm")}`;
      } else if (isSameMonth) {
        return `Du ${start.format("DD")} au ${end.format("DD MMM YYYY")}`;
      } else {
        return `Du ${start.format("DD MMM YYYY")} au ${end.format(
          "DD MMM YYYY"
        )}`;
      }
    }
  }
  