import { ADVERTISEMENT_TYPE, BuildingEvent, useBuildingEvent } from '@/hooks/useBuildingEvent';
import { useToast } from '@chakra-ui/react';
import * as React from 'react';
import WebsiteInput from '@/components/inputs/website';
import CheckboxInput from '@/components/inputs/checkbox';
import { useCategories } from '@/hooks/useCategories';
import { usePlacesWidget } from 'react-google-autocomplete';
import TextInput from '@/components/inputs/text';
import dayjs from 'dayjs';
import { captureException } from '@sentry/react';


interface IInfos2EventBuilderProps {
}


const wording = {
    [ADVERTISEMENT_TYPE.IMMO]: {
        address: "Adresse du bien",
        place: "Nom du lieu",
    },
    [ADVERTISEMENT_TYPE.EMPLOI]: {
        address: "Adresse de la société",
        place: "Nom du lieu",
    }
} as const

const Infos2EventBuilder: React.FunctionComponent<IInfos2EventBuilderProps> = (props) => {
    const event = useBuildingEvent()
    const availableCategories = useCategories({ immo: true, emploi: true, event: false })
    const toast = useToast()
    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
        language: "fr",
        // libraries: ["places", "maps", "core"],
        options: {
            types: ["geocode", "establishment"],
            fields: ["formatted_address", "geometry.location", "name"],
        },
        onPlaceSelected: (place) => {
            console.log(place);
            if (!place.geometry) {
                captureException(new Error("Invalid place selected"), { extra: { variables: { place, value: ref.current?.value } } })
                return toast({
                    title: "Adresse invalide",
                    description: "Veuillez sélectionner une adresse valide",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
            event.update(data => ({
                ...data,
                place: data.place ?? place.name,
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            }))
        }
    });
    React.useEffect(() => {
        if (!event.categories?.length) event.update(data => ({
            ...data,
            categories: (event.type === "immo" ? availableCategories.data?.filter(a => a.name.toLowerCase().includes("immo")) : availableCategories.data?.filter(a => a.name.toLowerCase().includes("emploi"))) ?? []
        }))
    }, [event, availableCategories.data]);
    React.useEffect(() => {
        if (!event.date || !event.datefin) {
            event.update(data => ({
                ...data,
                date: dayjs().subtract(1, "day").toISOString(),
                datefin: dayjs().add(3, "year").toISOString(),
            }))
        }
    }, [event.date, event.datefin])

    function updateEvent(property: keyof BuildingEvent) {
        return function (value: BuildingEvent[typeof property]) {
            event.update(data => ({
                ...data,
                [property]: value
            }))
        }
    }

    if (!event.type || event.type === ADVERTISEMENT_TYPE.EVENT) return null

    return <div className='w-full h-full max-w-xl flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
            <h1>{wording[event.type].address}</h1>
            <TextInput
                ref={ref}
                defaultValue={event.address}
                onChange={(e) => updateEvent("address")(e.target.value)}
                placeholder='Nice Jazz Festival'
            />
        </div>
        <div className='flex flex-col gap-2'>
            <h1>{wording[event.type].place}</h1>
            <TextInput
                value={event.place}
                onChange={(e) => updateEvent("place")(e.target.value)}
                placeholder='Stade Allianz Riviera'
            />
        </div>
        <div className='flex flex-col gap-2'>
            <h1>Lien de l'annonce</h1>
            <WebsiteInput
                value={event.url_ticketing}
                onChange={(e) => updateEvent("url_ticketing")(e.target.value)}
            />
        </div>
        <div className='flex flex-col gap-2'>
            <h1>Statut</h1>
            <CheckboxInput
                checked={event.published}
                onClick={() => {
                    event.update(data => ({
                        ...data,
                        published: !event.published
                    }))
                }}
            >
                Publier dans le catalogue Konnectz
            </CheckboxInput>
        </div>
    </div>
};

export default Infos2EventBuilder;
