import Button from "@/components/button";
import TextInput from "@/components/inputs/text";
import { useDebounceEffect } from "@/hooks/useDebounceEffect";
import useInput from "@/hooks/useInput";
import useR from "@/hooks/useR";
import { useSet } from "@/hooks/useSet";
import { getEvents } from "@/service/timenjoy.service";
import { TimenjoyEvent, TimenjoyListResponse } from "@/types/timenjoy.types";
import { useInfiniteQuery } from "@tanstack/react-query";
import * as React from "react";
import DateFilter from "@/components/date-filter";
import CatalogItem from "@/components/list-item/catalog-item";
import dayjs from "dayjs";
import CityFilter from "@/components/city-filter";
import { XCircleIcon } from "@heroicons/react/24/solid";
import Spinner from "@/components/spinner";
import CategoryFilter from "@/components/category-filter";
import { capitalize } from "@/utils/capitalize";
import { useKeyboard } from "@/hooks/useKeyboard";
import { useAddToSelection } from "@/mutations/add-to-selection";
import { useCategories } from "@/hooks/useCategories";
import usePersist from "@/hooks/usePersist";

interface IScheduleAdvertisementModalProps {
  open: boolean;
  setOpen(open: boolean): void;
  ids?: string[];
}

const PER_PAGE = 20;

const ScheduleAdvertisementModal: React.FunctionComponent<
  IScheduleAdvertisementModalProps
> = ({ open, setOpen, ids = [] }) => {
  const { currentApiAccount, company } = useR();
  const stack = useSet<string>();
  const [searchString, setSearchString] = usePersist<string>({
    key: "schedule-advertisement-search",
    defaultValue: "",
  });
  const searchInput = useInput(searchString);
  const [city, setCity] = usePersist<string>({
    key: "schedule-advertisement-city",
    defaultValue: "",
  });
  const [categories, setCategories] = usePersist<string[]>({
    key: "schedule-advertisement-categories",
    defaultValue: [],
  });
  const cityInput = useInput(city);
  const [dates, setDates] = usePersist<[Date, Date] | null>({
    key: "schedule-advertisement-dates",
    defaultValue: [new Date(), dayjs().add(7, "days").toDate()],
    parser(value) {
      if (value === "null") return null;
      const [from, to] = JSON.parse(value) as [string, string];
      return [new Date(from), new Date(to)];
    },
  });
  const submit = useAddToSelection();

  useDebounceEffect(
    () => {
      setSearchString(searchInput.value);
      setCity(cityInput.value);
    },
    500,
    [searchInput.value, cityInput.value],
  );

  const availableCategories = useCategories({ event: true });

  const computedCategories = React.useMemo(
    function () {
      if (!availableCategories.data) return [];
      if (!categories.length) return availableCategories.data.map((c) => c.id);
      const lowerCasedCategories = categories.map((c) => c.toLowerCase());
      return availableCategories.data
        .filter((a) =>
          lowerCasedCategories.includes(a.name.toLocaleLowerCase()),
        )
        .map((c) => c.id);
    },
    [categories, availableCategories.data],
  );
  const hasFilters =
    !!searchString || !!cityInput.value || !!dates || !!categories.length;

  const query = useInfiniteQuery<TimenjoyListResponse<TimenjoyEvent>>({
    queryKey: [
      "events",
      searchString,
      computedCategories,
      city,
      dates,
      open,
      // ids,
    ],
    enabled: open && computedCategories.length > 0,
    queryFn: async ({ pageParam = 0 }) => {
      if (!open) return { rows: [], metaData: { currentPage: "0", count: 0 } };
      const response = await getEvents({
        page: pageParam + 1,
        city,
        number: PER_PAGE,
        distance: 100,
        blackListed: false,
        search: searchString,
        categories: computedCategories,
        app_secret: currentApiAccount?.app_secret,
        // notIds: ids,
        published: true,
        date: dates?.[0],
        datefin: dates?.[1],
      });
      return response;
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, count } = lastPage.metaData;
      const maxPage = Math.ceil(count / PER_PAGE);
      if (+currentPage >= maxPage) return undefined;
      return +currentPage;
    },
    refetchInterval: 1000 * 60 * 5,
  });

  function close(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === event.currentTarget) setOpen(false);
  }
  const lastElementRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!lastElementRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          query.fetchNextPage();
        }
      },
      { threshold: 1 },
    );
    observer.observe(lastElementRef.current);
    return () => observer.disconnect();
  }, [query]);

  useKeyboard({
    Escape: () => setOpen(false),
  });
  const [eventCache, setEventCache] = React.useState<
    Record<string, TimenjoyEvent>
  >({});
  React.useEffect(() => {
    if (!query.data) return;
    const newCache = structuredClone(eventCache);
    for (const page of query.data.pages)
      for (const event of page.rows)
        if (stack.has(event.id)) newCache[event.id] = { ...event };

    setEventCache(newCache);
  }, [query.data, stack.values]);

  React.useEffect(() => {
    setEventCache({});
    stack.reset();
  }, [open]);
  async function addEvents() {
    if (!stack.size) return;
    const cacheEntries = Object.entries(eventCache);
    const eventsToAdd = cacheEntries.map(([id, event]) => ({
      id,
      from: Date.now(),
      to: +dayjs(event.datefin).endOf("day"),
      filler: event.companies[0]?.id !== company?.id,
    }));
    await submit.mutateAsync(eventsToAdd);
    setEventCache({});
    stack.reset();
    // setOpen(false)
  }
  if (!open) return null;
  return (
    <div
      className="bg-background_dark/40 fixed inset-0 z-20 flex cursor-pointer items-center justify-center backdrop-blur"
      onClick={close}
    >
      <div className="border-background_light bg-konnectz_background inline-flex h-[615px] w-[1014px] flex-col items-start justify-start gap-6 rounded-2xl border px-10 py-8">
        <div className="flex w-full items-center justify-between">
          <div className="text-xl font-semibold tracking-tight text-white">
            Ajouter des événements
          </div>
          <div className="flex gap-2">
            {submit.isLoading || (
              <Button variant="quiet" onClick={() => setOpen(false)}>
                Annuler
              </Button>
            )}
            <Button
              variant="tertiary"
              className="flex items-center gap-[6px]"
              disabled={submit.isLoading}
              onClick={addEvents}
            >
              Ajouter{" "}
              {submit.isLoading ? (
                <Spinner className="w5 h-5" />
              ) : (
                <div className="text-konnectz_accent flex h-6 w-6 items-center justify-center rounded-full bg-white pb-px text-sm leading-none">
                  {stack.size}
                </div>
              )}
            </Button>
          </div>
        </div>
        <div className="inline-flex items-center justify-start gap-16">
          <TextInput
            placeholder="Rechercher"
            className=" w-72"
            {...searchInput}
          />
          <div className="flex items-center justify-start gap-8">
            <div className="text-right text-sm font-semibold tracking-tight text-white">
              Filtrer par{" "}
            </div>
            <div className="flex items-center justify-start gap-6">
              <CategoryFilter
                value={categories}
                onChange={setCategories}
                data={
                  availableCategories.data
                    ?.map((a) => a.name)
                    .map(capitalize) ?? []
                }
              >
                <div className="py-2 text-right text-sm font-semibold tracking-tight text-white underline">
                  Catégorie
                </div>
              </CategoryFilter>
              <DateFilter value={dates} onChange={setDates}>
                <div className="py-2 text-right text-sm font-semibold tracking-tight text-white underline">
                  Date
                </div>
              </DateFilter>
              <CityFilter {...cityInput}>
                <div className="py-2 text-right text-sm font-semibold tracking-tight text-white underline">
                  Ville
                </div>
              </CityFilter>
            </div>
          </div>
        </div>
        {hasFilters && (
          <div className="noscrollbar -mb-6 flex w-full items-start gap-2 overflow-x-auto overflow-y-hidden pb-6">
            {searchInput.value && (
              <span className="bg-konnectz_accent flex shrink-0 cursor-default items-center rounded-full py-1.5 pl-2 pr-3 text-sm font-semibold tracking-tight text-white">
                <XCircleIcon
                  className="mr-1 inline-block h-5 w-5 cursor-pointer"
                  onClick={() => searchInput.onChange("")}
                />
                Nom: {searchInput.value}
              </span>
            )}
            {dates && (
              <span className="bg-konnectz_accent flex shrink-0 cursor-default items-center rounded-full py-1.5 pl-2 pr-3 text-sm font-semibold tracking-tight text-white">
                <XCircleIcon
                  className="mr-1 inline-block h-5 w-5 cursor-pointer"
                  onClick={() => setDates(null)}
                />
                {dates.map((d) => dayjs(d).format("D MMM")).join(" - ")}
              </span>
            )}
            {cityInput.value && (
              <span className="bg-konnectz_accent flex shrink-0 cursor-default items-center rounded-full py-1.5 pl-2 pr-3 text-sm font-semibold tracking-tight text-white">
                <XCircleIcon
                  className="mr-1 inline-block h-5 w-5 cursor-pointer"
                  onClick={() => cityInput.onChange("")}
                />
                Ville: {cityInput.value}
              </span>
            )}
            {categories.map((category) => (
              <span
                className="bg-konnectz_accent flex shrink-0 cursor-default items-center rounded-full py-1.5 pl-2 pr-3 text-sm font-semibold tracking-tight text-white"
                key={category}
              >
                <XCircleIcon
                  className="mr-1 inline-block h-5 w-5 cursor-pointer"
                  onClick={() =>
                    setCategories(categories.filter((c) => c !== category))
                  }
                />
                {category}
              </span>
            ))}
          </div>
        )}
        <div className="flex h-5 items-center text-right text-[15px] font-semibold tracking-tight text-white">
          {query.isLoading ? (
            <Spinner className="h-5 w-5" />
          ) : (
            query.data?.pages?.[0]?.metaData.count ?? 0
          )}{" "}
          Evénements
        </div>
        <div className="noscrollbar flex h-[376px] flex-col items-start justify-start gap-1 self-stretch overflow-y-auto">
          {query.data?.pages.map((page) => {
            return page.rows.map((item) => {
              return (
                <CatalogItem
                  {...item}
                  key={item.id}
                  checked={stack.has(item.id)}
                  setChecked={(checked) => {
                    if (checked) stack.add(item.id);
                    else stack.remove(item.id);
                  }}
                />
              );
            });
          })}
          {query.hasNextPage && (
            <div
              className="flex h-10 w-full items-center justify-center"
              onClick={() => {
                query.fetchNextPage();
              }}
              ref={lastElementRef}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleAdvertisementModal;
