import React from "react";
import FaqData from "@/assets/faq.json";
import SearchButton from "@/components/button/search-button";
import SearchOverlay from "@/components/search-overlay";
type Props = {};

type Faq = {
  id: string;
  content: string;
  tag?: string;
  attributes?: Record<string, any>;
  keywords: string[];
  children?: Faq[];
};

const TagsToJSX: Record<string, JSX.Element> = {
  h1: <h1 className="text-2xl font-semibold mt-6" />,
  h2: <h2 className="text-lg font-semibold text-gray-600 mt-5" />,
  h3: <h3 className="text-md font-semibold text-gray-600 mt-4" />,
  h4: <h4 className="text-sm font-semibold text-gray-600 mt-3" />,
  h5: <h5 className="text-xs font-semibold text-gray-600 mt-2" />,
  h6: <h6 className="text-xs font-semibold text-gray-600 mt-1" />,
  p: <p className="text-sm font-semibold text-gray-600 mt-1" />,
  a: <a className="text-sm font-semibold text-gray-600 mt-1" />,
};

function renderFaq(elements?: Faq[]): JSX.Element[] {
  if (!elements?.length) return [];
  return elements.map(({ children, content, tag, attributes }) => {
    return React.cloneElement(
      TagsToJSX[tag ?? "p"] ?? TagsToJSX.p,
      attributes ?? {},
      [content, ...renderFaq(children)]
    );
  });
}

const Support = (props: Props) => {
  const [searchMode, setSearchMode] = React.useState(false);
  return (
    <section className="h-full w-full flex flex-col px-16">
      <div className="w-full flex justify-center">
        <SearchButton
          placeholder="Rechercher"
          className="max-w-screen-sm shadow-md"
          onClick={() => setSearchMode(true)}
        />
      </div>
      <section className="w-full h-full overflow-scroll pt-8 pb-12">
        <h1 className="text-2xl font-semibold">Support</h1>
        <div className="mt-8">
          <h2 className="text-lg font-semibold text-gray-600">FAQ</h2>
          {renderFaq(FaqData)}
        </div>
      </section>
      {searchMode && <SearchOverlay close={() => setSearchMode(false)} />}
    </section>
  );
};

export default Support;
