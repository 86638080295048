export const numberFormatter = (value: number) =>
  Intl.NumberFormat("fr", { maximumFractionDigits: 1 })
    .format(value || 0)
    .toString();
export const percentageFormatter = (value: number) => {
  try {
    return Intl.NumberFormat("fr", {
      maximumFractionDigits: 2,
      style: "percent",
    })
      .format(value || 0)
      .toString();
  } catch {
    return (
      Intl.NumberFormat("fr", { maximumFractionDigits: 2 })
        .format((value || 0) * 100)
        .toString() + "%"
    );
  }
};
export const groupFormatter = (value: number) => {
  if (value < 1000) return numberFormatter(value);
  if (value < 1_000_000) return numberFormatter(value / 1000) + "k";
  if (value < 1_000_000_000) return numberFormatter(value / 1_000_000) + "M";
  return numberFormatter(value / 1_000_000_000) + "B";
};
