import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { z } from "zod";

export default function useValidation<T extends any>(schema: z.ZodSchema<T>) {
  const [isValid, setIsValid] = useState(false);
  const toast = useToast();
  const validate = useCallback(
    (data: any, silent: boolean = false) => {
      const parsingResult = schema.safeParse(data);
      setIsValid(parsingResult.success);
      if (!parsingResult.success) {
        if (silent) return;
        toast({
          title: "Echec de l'enregistrement",
          description: Array.from(new Set(parsingResult.error.issues
            .map((issue) => issue.message)
            .filter(Boolean)))
            .map((message) => <p>{message}</p>),
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      return parsingResult.data;
    },
    [schema, setIsValid, toast]
  );

  return [isValid, validate] as const;
}
