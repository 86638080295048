import { DependencyList, useEffect, useRef } from "react";

type Destructor = ReturnType<React.EffectCallback>;

type EffectCallback = () => Destructor | void | Promise<Destructor | void>;

export function useDebounceEffect(
  fn: EffectCallback,
  ms: number,
  deps: DependencyList
) {
  const firstRender = useRef(true);
  const timeoutId = useRef<number>(0);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    let cleanEffect: Destructor;
    timeoutId.current = setTimeout(async () => {
      cleanEffect = await fn();
    }, ms) as unknown as number;
    return () => {
      clearTimeout(timeoutId.current);
      cleanEffect?.();
    };
  }, deps);
  return () => {
    clearTimeout(timeoutId.current);
    return fn();
  };
}
