import Button from "@/components/button";
import Select from "@/components/inputs/select";
import Spinner from "@/components/spinner";
import useR from "@/hooks/useR";
import { useAddToSelection } from "@/mutations/add-to-selection";
import { Selection } from "@/types/manager.types";
import { TimenjoyApiAccount, TimenjoyEvent } from "@/types/timenjoy.types";
import { isCustomSelection } from "@/utils/isCustomSelection";
import { cleanName } from "@/utils/name";
import { useToast } from "@chakra-ui/react";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import DatePicker from "rsuite/DatePicker";
import frFR from "rsuite/locales/fr_FR";

type TimenjoyEventInfos = Pick<
  TimenjoyEvent,
  "id" | "title" | "picture" | "date"
>;
export interface IAddToSelectionComponentProps {
  active: boolean;
  next(): void;
  close(): void;
  events: [TimenjoyEventInfos, ...TimenjoyEventInfos[]];
}

export interface IAddToSelectionItem
  extends Record<
    string,
    {
      from: Dayjs;
      to: Dayjs;
      filler: boolean;
    }
  > {}

export default function AddToSelectionComponent({
  active,
  next,
  events,
  close,
}: IAddToSelectionComponentProps) {
  const [items, setItems] = React.useState<IAddToSelectionItem>({});
  const {
    apiAccounts,
    client,
    currentSelection,
    selections: selectionQueries,
  } = useR();
  const widgets = React.useMemo(() => {
    return apiAccounts
      .map((apiAccount) => {
        return client.getQueryData<TimenjoyApiAccount>([
          "apiAccount",
          apiAccount,
        ]);
      })
      .filter(Boolean) as TimenjoyApiAccount[];
  }, [apiAccounts, client]);

  const selections = selectionQueries
    .map((q) => q.data)
    .filter(Boolean)
    .filter(isCustomSelection)
    .map((s) => s as Selection)
    .map((s) => ({
      ...s,
      name:
        s.name === "ma-selection"
          ? widgets.find(
              (w) => w.app_secret === encodeURIComponent(s.app_secret),
            )?.name ?? s.name
          : s.name,
    }))
    .sort((a, b) => {
      if (a!.id === currentSelection?.id) return -1;
      if (b!.id === currentSelection?.id) return 1;
      return 0;
    });

  const toast = useToast();

  const addToSelection = useAddToSelection();

  const nextWidgetToAdd = selections.find(
    (s) => !Object.keys(items).includes(s.id),
  );

  React.useEffect(() => {
    if (active && nextWidgetToAdd && !Object.keys(items).length)
      setItems({
        [nextWidgetToAdd.id]: {
          from: dayjs().startOf("day"),
          to: dayjs().endOf("day"),
          filler: false,
        },
      });
    else setItems({});
  }, [active]);

  if (!active) return null;

  function addNewItem() {
    if (!nextWidgetToAdd) return;
    setItems((items) => {
      const newItems = { ...items };
      newItems[nextWidgetToAdd!.id] = {
        from: dayjs().startOf("day"),
        to: dayjs().endOf("day"),
        filler: false,
      };
      return newItems;
    });
  }
  function removeItem(selectionId: string) {
    setItems((items) => {
      const newItems = { ...items };
      delete newItems[selectionId];
      return newItems;
    });
  }
  function onWidgetChange(
    originId: string,
  ): React.ChangeEventHandler<HTMLSelectElement> {
    return function (event) {
      const targetName = event.target.value;
      const targetId = selections.find(
        (s) => cleanName(s.name) === targetName,
      )?.id;
      if (!targetId) {
        alert(targetName + " n'est pas un widget valide");
        return;
      }
      setItems((items) => {
        const newItems = { ...items };
        newItems[targetId] = items[originId];
        delete newItems[originId];
        return newItems;
      });
    };
  }
  function onDateChange(selectionId: string, position: "from" | "to") {
    return function (value: Date | null) {
      const newDate = dayjs(value);
      if (!newDate.isValid()) return;
      setItems((items) => {
        const newItems = { ...items };
        newItems[selectionId] = items[selectionId] ?? {
          from: newDate,
          to: newDate,
          filler: true,
        };
        newItems[selectionId][position] = newDate;
        newItems[selectionId].from = newItems[selectionId].from.startOf("day");
        newItems[selectionId].to = newItems[selectionId].to.endOf("day");

        return newItems;
      });
    };
  }
  function toggleFiller(selectionId: string) {
    setItems({
      ...items,
      [selectionId]: {
        ...items[selectionId],
        filler: !items[selectionId].filler,
      },
    });
  }

  async function submit() {
    if (addToSelection.isLoading) return;
    const responses = await Promise.allSettled(
      Object.entries(items).map(async ([selectionId, item]) => {
        const matchingSelection = selections.find((s) => s.id === selectionId);
        if (!matchingSelection?.config)
          return Promise.reject(new Error("Invalid Selection"));
        const { config } = matchingSelection;
        await addToSelection.mutateAsync(
          events.map((event) => ({
            config,
            id: event.id,
            from: +item.from,
            to: +item.to,
            filler: item.filler,
          })),
        );
        setItems((items) => {
          const newItems = { ...items };
          delete newItems[selectionId];
          return newItems;
        });
      }),
    );
    const errors = responses.filter(
      (r) => r.status === "rejected",
    ) as PromiseRejectedResult[];
    if (!errors.length) {
      toast({
        title: "Événements ajoutés",
        description: "Les événements ont bien été ajoutés aux sélections",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      return next();
    }

    toast({
      title: "Échec de l'ajout",
      description:
        "Les événements suivants n'ont pas pu être ajoutés à la sélection",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }

  return (
    <div
      className="w-full max-w-2xl border border-background_light px-6 py-8 bg-konnectz_background rounded-2xl flex-col justify-start items-start gap-6 inline-flex"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center w-full">
        <div className="text-white text-xl font-semibold tracking-wide">
          Ajouter à mes sélections
        </div>
      </div>

      <div className="w-full">
        <h6 className="text-white text-sm font-semibold tracking-wide flex items-center">
          Événements sélectionnés
        </h6>
        <div className="w-full flex flex-col items-stretch mt-3 text-white gap-2 max-h-32 overflow-y-auto">
          {events.map((event) => (
            <div key={event.id} className="flex items-center gap-3">
              <img
                src={event.picture}
                alt=""
                className="h-6 rounded-sm w-12 object-cover"
              />
              <span className="grow">{event.title}</span>
              <span>
                {dayjs(event.date).format("[le] DD.MM.YYYY [à] HH[h]mm")}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <h6 className="text-white text-sm font-semibold tracking-wide flex items-center">
          Ajouter à...
        </h6>
        <div className="w-full flex flex-col items-stretch mt-3 text-white gap-3">
          {Object.entries(items).map(([selectionId, { from, to, filler }]) => {
            const matchingWidget = selections.find((s) => s.id === selectionId);
            if (!matchingWidget) {
              setItems((items) => {
                const newItems = { ...items };
                delete newItems[selectionId];
                return newItems;
              });
              return null;
            }
            return (
              <div className="w-full flex items-center gap-3" key={selectionId}>
                <StarIcon
                  className={clsx(
                    "w-5 h-5 cursor-pointer",
                    filler ? "text-background_light" : "text-emphasis",
                  )}
                  title="Sponsoriser"
                  onClick={() => toggleFiller(selectionId)}
                />
                <Select
                  className="!py-1.5 h-[38px] !w-60"
                  options={selections.map((s) => cleanName(s.name))}
                  value={cleanName(matchingWidget.name)}
                  onChange={onWidgetChange(selectionId)}
                />
                <DatePicker
                  locale={frFR.DatePicker}
                  value={from.toDate()}
                  onChange={onDateChange(selectionId, "from")}
                  size="md"
                  ranges={[]}
                  placement="autoVertical"
                />
                <DatePicker
                  locale={frFR.DatePicker}
                  value={to.toDate()}
                  onChange={onDateChange(selectionId, "to")}
                  size="md"
                  ranges={[]}
                  placement="autoVertical"
                />
                <XMarkIcon
                  className="w-5 h-5"
                  title="Supprimer"
                  onClick={() => removeItem(selectionId)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {nextWidgetToAdd && (
        <div className="w-full">
          <Button
            variant="primary"
            className="flex gap-2 items-center"
            onClick={addNewItem}
          >
            Ajouter à une autre sélection
          </Button>
        </div>
      )}
      <div className="flex justify-end mt-4 gap-4 items-center w-full">
        <Button
          variant="quiet"
          className="flex items-center justify-between gap-3 px-3"
          onClick={close}
        >
          Passer
        </Button>
        <Button
          variant="tertiary"
          className="flex items-center justify-between gap-3 px-3"
          disabled={!Object.keys(items).length || addToSelection.isLoading}
          onClick={submit}
          clickThroughDisabled
        >
          <div className="w-5" />
          Valider{" "}
          {addToSelection.isLoading ? (
            <Spinner className="w-5 h-5 fill-konnectz_accent" />
          ) : (
            <div className="w-5" />
          )}
        </Button>
      </div>
    </div>
  );
}
