import AddToSelectionComponent from "@/components/add-to-selection";
import { TimenjoyEvent } from "@/types/timenjoy.types";
import { FloatingPortal } from "@floating-ui/react";

export type TimenjoyEventInfos = Pick<
  TimenjoyEvent,
  "id" | "title" | "picture" | "date"
>;
export interface IAddToSelectionModalProps {
  open: boolean;
  events: TimenjoyEventInfos[];
  next(): void;
  skip(): void;
}

export default function AddToSelectionModal({
  open,
  events,
  skip,
  next,
}: IAddToSelectionModalProps) {
  if (!open || !events.length) return null;

  return (
    <FloatingPortal preserveTabOrder>
      <div
        className="fixed inset-0 flex justify-center items-center bg-background_dark/40 z-40 backdrop-blur cursor-default"
        onClick={close}
      >
        <AddToSelectionComponent
          events={events as [TimenjoyEventInfos, ...TimenjoyEventInfos[]]}
          active={open}
          next={next}
          close={skip}
        />
      </div>
    </FloatingPortal>
  );
}
