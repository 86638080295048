import React from "react";

const Step: React.FC<{ nb: number; label?: string }> = ({ nb, label }) => {
  return (
    <h5 className="text-title font-semibold mb-4">
      Étape {nb}
      {label ? ": " + label : null}
    </h5>
  );
};

type WithChildren = {
  children: React.ReactNode | React.ReactNode[];
};
const Title: React.FC<WithChildren> = ({ children }) => {
  return (
    <h4 className="text-title text-xl mb-6 border-b-4 border-emphasis w-fit">
      {children}
    </h4>
  );
};

const Bloc: React.FC<WithChildren> = ({ children }) => {
  return <div className="mb-8">{children}</div>;
};

const Paragraph: React.FC<WithChildren> = ({ children }) => {
  return <p className="text-subtitle mb-6">{children}</p>;
};

const Implementing = () => {
  return (
    <section className="text-subtitle font-medium text-md max-w-3xl w-full mx-auto h-full overflow-scroll noscrollbar pb-24">
      <img
        src={import.meta.env.BASE_URL + "img/logo.png"}
        width={120}
        className="mb-8"
      />
      <div className="mb-12">
        <h2 className="text-lg font-semibold text-subtitle/80">Guide</h2>
        <h1 className="text-4xl font-semibold text-title">
          Comment implémenter mon widget&nbsp;?
        </h1>
      </div>
      <Bloc>
        <Title>Intégration du widget agenda d'événements sur votre site</Title>
        <Paragraph>
          Voici les étapes pour ajouter un widget HTML tiers à votre site
          WordPress :
        </Paragraph>
        <div>
          <Step nb={1} label="Copiez le code HTML" />
          <Paragraph>
            Pour commencer, copiez le code HTML fourni et intégrez-le dans votre
            site internet à l'emplacement souhaité. Si vous avez besoin d'aide
            pour intégrer un code HTML en fonction de votre site ou du CMS
            utilisé, contactez-nous à l'adresse{" "}
            <a href="mailto:contact@konnectz.io" className="text-emphasis">
              contact@konnectz.io
            </a>
          </Paragraph>
          <Step nb={2} label="Enregistrez et publiez votre site" />
          <Paragraph>
            Une fois le code HTML intégré à l'emplacement approprié sur votre
            site, enregistrez les modifications et publiez votre site.
          </Paragraph>
          <Step nb={3} label="Admirez votre sélection d'événements" />
          <Paragraph>
            Après la publication, vous verrez apparaître votre sélection
            d'événements en fonction du format choisi (Carrousel ou page
            agenda).
          </Paragraph>
          <Step nb={4} label="Ajoutez d'autres agendas d'événements" />
          <Paragraph>
            Vous pouvez copier-coller le code HTML à différents endroits de
            votre site internet pour avoir plusieurs agendas d'événements.
          </Paragraph>
          <Step
            nb={5}
            label="Personnalisez et gérez vos sélections d'événements"
          />
          <Paragraph>
            Si vous souhaitez personnaliser vos sélections d'événements, créer
            plusieurs widgets, changer les liens de redirection, ajouter vos
            propres événements ou planifier leur diffusion, pensez à passer à
            l'abonnement premium. Pour plus d'informations ou pour une
            démo,&nbsp;
            <a
              className="text-emphasis"
              href="https://calendly.com/d/cd9-9yr-c3z/timenjoy-connect-demo?embed_domain=app.konnectz.io"
            >
              contactez notre équipe
            </a>
            .
          </Paragraph>
        </div>
      </Bloc>
      <Bloc>
        <Title>Comment intégrer le widget sur WordPress avec Elementor?</Title>
        <Paragraph>
          Elementor propose une balise HTML pour ajouter facilement du code HTML
          personnalisé à votre page ou publication WordPress. Voici comment
          procéder :
        </Paragraph>
        <div>
          <Step nb={1} label="Accédez à votre tableau de bord WordPress" />
          <Paragraph>
            Connectez-vous à votre tableau de bord WordPress en entrant vos
            identifiants. Cliquez ensuite sur "Pages" dans le menu de gauche
            pour accéder à la liste de vos pages.
          </Paragraph>
          <Step nb={2} label="Sélectionnez la page à modifier avec Elementor" />
          <Paragraph>
            Identifiez la page sur laquelle vous souhaitez intégrer le widget
            HTML et cliquez sur "Modifier avec Elementor". Si vous souhaitez
            créer une nouvelle page, cliquez sur "Ajouter une nouvelle" et
            choisissez "Modifier avec Elementor".
          </Paragraph>
          <Step nb={3} label={`Ajoutez le widget "Code HTML"`} />
          <Paragraph>
            Dans la barre latérale gauche d'Elementor, recherchez "HTML" ou
            "Code HTML" en utilisant la barre de recherche. Faites glisser le
            widget "Code HTML" à l'emplacement souhaité sur votre page.
          </Paragraph>
          <Step nb={4} label="Collez le code HTML du widget" />
          <Paragraph>
            Cliquez sur le widget "Code HTML" ajouté à votre page pour afficher
            les options de modification. Copiez le code HTML de votre widget et
            collez-le dans la zone de texte "Code HTML" d'Elementor.
          </Paragraph>
          <Step nb={5} label="Enregistrez les modifications" />
          <Paragraph>
            Cliquez sur le bouton "Mettre à jour" en bas de la barre latérale
            gauche d'Elementor pour enregistrer vos modifications.
          </Paragraph>
          <Step nb={6} label="Vérifiez l'affichage du widget sur votre site" />
          <Paragraph>
            Une fois les modifications enregistrées, cliquez sur l'icône en
            forme d'œil située en bas à gauche d'Elementor pour prévisualiser
            votre page. Vérifiez que le widget s'affiche correctement à
            l'emplacement souhaité.
          </Paragraph>
          <Step nb={7} label="Publiez ou mettez à jour la page" />
          <Paragraph>
            Si tout est en ordre, retournez à l'éditeur Elementor et cliquez sur
            le bouton "Publier" (si c'est une nouvelle page) ou "Mettre à jour"
            (si vous modifiez une page existante) pour finaliser l'intégration
            du widget.
          </Paragraph>
          <Step
            nb={8}
            label="Vérifiez l'affichage du widget sur votre site en mode public"
          />
          <Paragraph>
            Visitez votre site en mode public et naviguez jusqu'à la page où
            vous avez intégré le widget HTML. Assurez-vous qu'il s'affiche
            correctement et fonctionne comme prévu pour les visiteurs de votre
            site. Et voilà&nbsp;! Vous avez réussi à intégrer un widget HTML sur
            votre site WordPress en utilisant Elementor. N'hésitez pas à répéter
            ces étapes pour ajouter d'autres widgets sur différentes pages de
            votre site.
          </Paragraph>
        </div>
      </Bloc>
    </section>
  );
};

export default Implementing;
