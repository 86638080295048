import DateRangePicker from "rsuite/DateRangePicker";
import frFR from "rsuite/locales/fr_FR";
import React from "react";

export interface IDateFilterProps {
  children?: React.ReactElement;
  value: [Date, Date] | null;
  onChange: (value: [Date, Date] | null) => void;
}

const DateFilter: React.FunctionComponent<IDateFilterProps> = ({
  value,
  onChange,
  children,
}) => {
  const Element = children ? React.useCallback(() => children, [children]) : undefined
  return (
    <DateRangePicker
      value={value}
      onChange={onChange}
      showOneCalendar
      size="md"
      locale={frFR}
      toggleAs={Element}
      ranges={[]}
      placement="autoVertical"
    />
  );
};

export default DateFilter;
