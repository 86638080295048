import { TimenjoyEvent } from "@/types/timenjoy.types";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import CheckboxInput from "../../inputs/checkbox";
import { displayDates } from "@/utils/display-dates";

interface ICatalogItemProps extends TimenjoyEvent {
  checked: boolean;
  setChecked(checked: boolean): void;
}

const CatalogItemWithoutRef: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ICatalogItemProps
> = (props, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        "self-stretch justify-start items-center gap-6 inline-flex hover:bg-background_light pl-3 pr-2 py-1 rounded-md",
        props.checked && "bg-background_light"
      )}
      onClick={() => window.open(props.url_ticketing, "_blank")}
    >
      <CheckboxInput
        checked={props.checked}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          props.setChecked(!props.checked)
        }}
      />
      <img src={props.picture} className="w-[82.24px] h-10 rounded-[3px]" />
      <div className="grow shrink basis-0 text-white text-xs font-medium tracking-tight">
        {props.title}
      </div>
      <div className="flex gap-2">
        {props.categories.slice(0, 2).map((category) => (
          <div
            key={category.id}
            className="px-[7px] py-1.5 bg-fuchsia-700 rounded-sm justify-start items-center gap-2.5 flex"
          >
            <div className="text-white text-[10px] font-medium tracking-tight">
              {category.name}
            </div>
          </div>
        ))}
      </div>
      <div className="w-24 text-white text-xs text-ellipsis">{displayDates(props.date, props.datefin)}</div>
      <div className="w-40 text-white text-xs text-ellipsis">{props.place}</div>
    </div>
  );
};

const CatalogItem = React.forwardRef(CatalogItemWithoutRef);

export default CatalogItem;
