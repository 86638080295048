import Button from "@/components/button";
import PasswordInput from "@/components/inputs/password";
import Spinner from "@/components/spinner";
import useInput from "@/hooks/useInput";
import {
  fetchResetPasswordSession,
  resetPassword,
} from "@/service/timenjoy.service";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as React from "react";
import { useParams } from "react-router-dom";

export interface IRecoverPasswordProps { }

export default function RecoverPassword(props: IRecoverPasswordProps) {
  const toast = useToast();
  const password = useInput();
  const passwordCheck = useInput();
  const { id } = useParams<{ id: string }>();
  const recoverSession = useQuery<string, Error>({
    queryKey: ["recoverSession", id],
    queryFn: async () => {
      if (!id) throw new Error("Une erreur est survenue");
      return fetchResetPasswordSession(id);
    },
    enabled: !!id,
    retry: false,
    refetchInterval: false,
  });
  const resetPasswordMutation = useMutation({
    async mutationFn() {
      if (!recoverSession.data) throw new Error("Une erreur est survenue");
      await resetPassword(
        recoverSession.data,
        password.value,
        passwordCheck.value,
      );
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
    onSuccess() {
      toast({
        title: "Votre mot de passe a été modifié",
        description: "Vous pouvez maintenant vous connecter",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  if (recoverSession.isLoading) {
    return <Layout>Chargement...</Layout>;
  }
  if (recoverSession.isError) {
    return <Layout>{recoverSession.error.message}</Layout>;
  }

  function submit(e?: React.FormEvent) {
    e?.preventDefault();
    if (recoverSession.isLoading || recoverSession.isError) return;
    if (password.value !== passwordCheck.value) {
      return toast({
        title: "Les mots de passe ne correspondent pas",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    resetPasswordMutation.mutate();
  }
  return (
    <Layout>
      <form onSubmit={submit} className="w-full space-y-5">
        <div className="w-full">
          <label className="font-medium">Nouveau mot de passe</label>
          <PasswordInput className="mt-2 w-full" {...password} />
        </div>
        <div className="w-full">
          <label className="font-medium">Confirmation</label>
          <PasswordInput className="mt-2 w-full" {...passwordCheck} />
        </div>
        <input type="submit" className="hidden" />
      </form>
      <Button
        className="!mt-8 grid w-full grid-cols-3 gap-3 py-3 text-base"
        disabled={resetPasswordMutation.isLoading}
        onClick={() => submit()}
      >
        <div />
        Confirmer{" "}
        {resetPasswordMutation.isLoading ? (
          <Spinner className="fill-emphasis h-5 w-5" />
        ) : (
          <div />
        )}
      </Button>
    </Layout>
  );
}

function Layout(props: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <main className="flex h-full w-full flex-col items-center justify-center pb-10 sm:pb-16">
      <div className="text-subtitle w-full max-w-sm">
        <div className="text-center">
          <img
            src={import.meta.env.BASE_URL + "img/logo.png"}
            width={200}
            className="mx-auto"
          />
          <div className="mt-5 space-y-2">
            <h3 className="text-title text-xl font-bold sm:text-2xl">
              Réinitialiser mon mot de passe
            </h3>
          </div>
        </div>
        <div className="mt-8 flex w-full flex-col items-center justify-center">
          {props.children}
        </div>
      </div>
    </main>
  );
}
