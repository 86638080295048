import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import * as React from "react";
import {
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  CheckBadgeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import useR from "@/hooks/useR";
import { unscheduleAdvertisement } from "@/service/konnectz.service";
import { useToast } from "@chakra-ui/react";
import { useSponsorScheduleMutation } from "@/mutations/sponsor-schedule";
import { Advertisement, RotationStrategy } from "@/types/konnectz.types";
import UpdateScheduleModal from "@/modals/update-schedule";
import { useSchedules } from "@/hooks/useSchedules";
import { captureException } from "@/service/exceptions";
import { useNavigate } from "react-router-dom";
import useAuth from "@/hooks/useAuth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CopyIcon } from "lucide-react";
import dayjs from "dayjs";

export interface IActionButtonProps {
  id: string;
  name: string;
  url: string;
  picture: string;
  location: string;
  from: number;
  to: number;
  sponsorized: boolean;
  advertisement: Advertisement;
  lockDrag: (value: boolean) => void;
  editable?: boolean;
}

function useRemoveFromSelection() {
  const toast = useToast();
  const [, refetch] = useSchedules();
  return useMutation({
    mutationFn: unscheduleAdvertisement,
    onSuccess() {
      toast({
        title: "Evénement supprimé",
        description: "L'événement a été supprimé de la sélection",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Erreur",
        description:
          "Une erreur est survenue lors de la suppression de l'événement",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
    onSettled: refetch,
  });
}

const SelectionMenu: React.FunctionComponent<IActionButtonProps> = (props) => {
  const { currentSelection, permissions } = useR();
  const { policy } = useAuth();
  const { isPremium } = permissions;
  const [open, setOpen] = React.useState(false);
  const [scheduleOpen, setScheduleOpen] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    props.lockDrag(open);
  }, [open]);

  function close() {
    setScheduleOpen(false);
  }

  const removeFromSelection = useRemoveFromSelection();
  const sponsorizeMutation = useSponsorScheduleMutation();

  const showAnalyticsMenu = policy?.canConsultStatistics && isPremium;

  return (
    <>
      <DropdownMenu onOpenChange={setOpen}>
        <DropdownMenuTrigger className="bg-transparent duration-200 transition-all p-1 rounded hover:bg-white/40">
          <EllipsisVerticalIcon className="w-5 h-5" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>Ma programmation</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              navigator.clipboard.writeText(
                `${props.id}:${props.advertisement.id}`,
              );
            }}
          >
            <CopyIcon className="w-5 h-5 mr-2" />
            <span>Copier la référence</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => window.open(props.url, "_blank")}>
            <ArrowTopRightOnSquareIcon className="w-5 h-5 mr-2" />
            <span>Voir en ligne</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {props.editable && (
            <DropdownMenuItem
              onClick={() =>
                navigate(`/app/events/${props.advertisement.timenjoyId}`)
              }
            >
              <PencilSquareIcon className="w-5 h-5 mr-2" />
              <span>Modifier le contenu</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() =>
              sponsorizeMutation
                .mutateAsync({
                  id: props.advertisement.id,
                  config: currentSelection!.config,
                  rotation_strategy: props.sponsorized
                    ? RotationStrategy.FILLER
                    : RotationStrategy.ROTATE,
                })
                .then(close)
            }
          >
            <CheckBadgeIcon className="w-5 h-5 mr-2" />
            <span>
              {props.sponsorized ? "Ne plus sponsoriser" : "Sponsoriser"}
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setScheduleOpen(true)}>
            <CalendarDaysIcon className="w-5 h-5 mr-2" />
            <span>Planifier</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {showAnalyticsMenu && (
            <DropdownMenuItem
              onClick={() =>
                navigate(
                  `/app/analytics?tab=1&selected=${props.advertisement.id}&from=${dayjs(props.from).toISOString()}&to=${dayjs(props.to).toISOString()}`,
                )
              }
            >
              <ChartBarIcon className="w-5 h-5 mr-2" />
              <span>Voir les stats</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            className="bg-destructive text-destructive-foreground hover:!bg-white hover:!text-destructive"
            onClick={() =>
              removeFromSelection.mutateAsync(props.id).then(close)
            }
          >
            <TrashIcon className="w-5 h-5 mr-2" />
            <span>Supprimer</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <UpdateScheduleModal
        open={scheduleOpen}
        setOpen={setScheduleOpen}
        {...props}
      />
    </>
  );
};

export default SelectionMenu;
