import { captureException } from "@/service/exceptions";
import { useQuery } from "@tanstack/react-query";
import useR from "./useR";
import { getAdvertisementsStats } from "@/service/analytics.service";

export default function useAdvertisementsListAnalytics(
  from: string,
  to: string,
) {
  const { currentSelection } = useR();
  const query = useQuery({
    queryKey: ["ads-stats", currentSelection, `${from}|${to}`],
    async queryFn() {
      if (!currentSelection) throw new Error("No selection");
      return getAdvertisementsStats(currentSelection.config, from, to);
    },
    enabled: !!currentSelection,
    onError(err) {
      captureException(err);
      console.error(err);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: 3,
  });
  return query;
}
