import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export interface INotFoundProps {
}

export default function NotFound(props: INotFoundProps) {
    const navigate = useNavigate()
    React.useEffect(() => {
        navigate("/app")
    }, []);
    return null
}
