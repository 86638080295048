import { clearQueryClient } from "@/utils/clear-query-client";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Logout: React.FC = () => {
  const client = useQueryClient();
  const [query] = useSearchParams()
  const navigate = useNavigate();
  React.useEffect(() => {
    clearQueryClient(client);
    localStorage.removeItem("lastLogin");
    navigate("/auth/login?redirect=" + query.get('redirect'));
  }, []);
  return null;
};

export default Logout;
