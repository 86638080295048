import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import {
  ArrowTopRightOnSquareIcon,
  ListBulletIcon,
  PencilSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { TimenjoyEvent } from "@/types/timenjoy.types";
import { useNavigate } from "react-router-dom";
import AddToSelectionModal, {
  TimenjoyEventInfos,
} from "@/modals/add-to-selection";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CopyIcon, CopyPlusIcon, Loader, Trash2Icon } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { duplicateEvent } from "@/service/timenjoy.service";
import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";
import { cn } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cleanName } from "@/utils/name";
import { useAssociatedSelections } from "@/hooks/useAssociatedSelections";

export interface IEventMenuProps
  extends Pick<
    TimenjoyEvent,
    | "id"
    | "picture"
    | "title"
    | "published"
    | "categories"
    | "place"
    | "url_ticketing"
    | "site"
    | "company_events"
    | "date"
  > {
  selectionButton?: boolean;
}

const EventMenu: React.FunctionComponent<IEventMenuProps> = (props) => {
  const { user } = useAuth();
  const toast = useToast();
  const { user: legacyUser, client } = useR();
  const navigate = useNavigate();
  // const [duplicatedEventData, setDuplicatedEventData] =
  //   React.useState<TimenjoyEventInfos | null>(null);
  const [openAddToSelectionModal, setOpenAddToSelectionModal] =
    React.useState(false);
  const [openDeleteEventModal, setOpenDeleteEventModal] = React.useState(false);
  const duplicateMutation = useMutation({
    async mutationFn({ id, userId }: { id: string; userId: string }) {
      const newEvent = await duplicateEvent(id, userId);
      // setDuplicatedEventData(newEvent);
      toast({
        title: "Événement dupliqué",
        status: "success",
      });
      return newEvent;
    },
    onSuccess(data) {
      navigate(`/app/events/${data.id}?lastStep=true`);
    },
    onSettled() {
      client.invalidateQueries(["events"]);
    },
  });
  return (
    <div className="w-full h-full flex items-center justify-center pr-5 gap-2">
      {props.selectionButton ? (
        <Popover>
          <PopoverTrigger
            className="bg-transparent duration-200 transition-all p-1 rounded hover:bg-white/40"
            title="Voir les sélections dans lesquelles cette annonce est programmée"
          >
            <ListBulletIcon className="w-5 h-5" />
          </PopoverTrigger>
          <PopoverContent className="w-96" side="left">
            <WidgetPopover {...props} />
          </PopoverContent>
        </Popover>
      ) : null}
      <DropdownMenu>
        <DropdownMenuTrigger className="bg-transparent duration-200 transition-all p-1 rounded hover:bg-white/40">
          <EllipsisVerticalIcon className="w-5 h-5" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>Mon annonce</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              try {
                navigator.clipboard.writeText(btoa(props.id));
              } catch (e) {
                navigator.clipboard.writeText(props.id);
              }
            }}
          >
            <CopyIcon className="w-5 h-5 mr-2" />
            <span>Copier la référence</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() =>
              window.open(props.url_ticketing ?? props.site, "_blank")
            }
          >
            <ArrowTopRightOnSquareIcon className="w-5 h-5 mr-2" />
            <span>Voir en ligne</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => navigate("/app/events/" + props.id)}>
            <PencilSquareIcon className="w-5 h-5 mr-2" />
            <span>Modifier</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center "
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (duplicateMutation.isLoading) return;
              duplicateMutation.mutate({
                id: props.id,
                userId: user.legacyId ?? legacyUser!.id,
              });
            }}
          >
            <CopyPlusIcon className="w-5 h-5 mr-2" />
            <span className="grow">Dupliquer</span>
            <div>
              <Loader
                className={cn(
                  duplicateMutation.isLoading ? "block" : "hidden",
                  "animate-spin w-5 h-5",
                )}
              />
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setOpenAddToSelectionModal(true)}>
            <PlusIcon className="w-5 h-5 mr-2" />
            <span>Ajouter à une sélection</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="bg-destructive text-destructive-foreground hover:!bg-white hover:!text-destructive"
            onClick={() => setOpenDeleteEventModal(true)}
          >
            <Trash2Icon className="h-5 w-5 mr-2" />
            Supprimer
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DeleteEventButton
        id={props.company_events[0].id}
        open={openDeleteEventModal}
        close={() => setOpenDeleteEventModal(false)}
      />
      <AddToSelectionModal
        open={openAddToSelectionModal}
        next={() => setOpenAddToSelectionModal(false)}
        skip={() => setOpenAddToSelectionModal(false)}
        events={[props]}
      />
      {/* <AddToSelectionModal
        open={!!duplicatedEventData}
        skip={() => setDuplicatedEventData(null)}
        next={() => setDuplicatedEventData(null)}
        events={duplicatedEventData ? [duplicatedEventData] : []}
      /> */}
    </div>
  );
};

export default EventMenu;

interface IWidgetPopoverProps extends Pick<TimenjoyEvent, "id"> {}

function WidgetPopover(props: IWidgetPopoverProps) {
  const [query, displayedIn] = useAssociatedSelections(props.id);
  return (
    <div className="text-sm">
      <h1 className="font-semibold">Présent dans:</h1>
      {query.isLoading && <span>Chargement...</span>}
      {query.isError && <span>Une erreur est survenue</span>}
      {query.isSuccess ? (
        displayedIn.length ? (
          <span>
            {displayedIn.map((cta) => (
              <div key={cta.id}>{cleanName(cta.account?.name)}</div>
            ))}
          </span>
        ) : (
          <span>Cette annonce n'est programmée dans aucune sélection</span>
        )
      ) : null}
    </div>
  );
}

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useDeleteEventMutation } from "@/mutations/delete-event";
import { useToast } from "@chakra-ui/react";

interface IDeleteEventButtonProps {
  id: string;
  open: boolean;
  close: () => void;
}

function DeleteEventButton({ id, open, close }: IDeleteEventButtonProps) {
  const deleteEventMutation = useDeleteEventMutation();
  return (
    <AlertDialog open={open}>
      <AlertDialogContent className="border-border max-w-sm bg-konnectz_background">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-white">
            Etes-vous sûr de vouloir supprimer l'annonce ?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Cette action est définitive
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={close}
            className="border-white text-white bg-konnectz_background hover:bg-background_light"
          >
            Annuler
          </AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-600 text-white hover:text-white hover:bg-red-800"
            onClick={() => {
              deleteEventMutation.mutateAsync({ id }).then(close);
            }}
          >
            Confirmer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
