import {
  ADVERTISEMENT_TYPE,
  BuildingEvent,
  fullEventSchema,
  useBuildingEvent,
} from "@/hooks/useBuildingEvent";
import { usePermission } from "@/hooks/usePermissions";
import useR from "@/hooks/useR";
import {
  addCoordinates,
  addEventCategory,
  addEventToCompany,
  addPicture,
  createEvent,
  deleteEventCategory,
  getEventById,
  getPictureOfEvent,
  updateCoordinates,
  updateEvent,
  updatePicture,
} from "@/service/timenjoy.service";
import { addCData } from "@/utils/c-data";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAdvertisements,
  refreshAdvertisement,
  throttledRefreshConfig,
} from "@/service/konnectz.service";

export function useSaveEvent() {
  const navigate = useNavigate();
  const { currentApiAccount, user, company } = useR();
  const { canUseEventBuilder } = usePermission();
  const toast = useToast();
  const buildingEvent = useBuildingEvent();
  const [s] = useSearchParams();
  return useMutation({
    mutationFn: async ({ ...data }: Partial<BuildingEvent>) => {
      if (!currentApiAccount) throw new Error("No account selected");
      if (!user) throw new Error("No user found");
      if (!company) throw new Error("No company found");
      if (!canUseEventBuilder) {
        toast({
          title: "Fonctionnalité réservée aux comptes premium",
          description:
            "Vous pouvez créer un événement en vous abonnant à Konnectz Premium.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        throw new Error("You need to be premium to use this feature");
      }
      if (data.type === ADVERTISEMENT_TYPE.IMMO) {
        data.description = addCData(data.description ?? "", {
          surface: data.surface,
          rooms: data.rooms,
          price: data.price,
        });
      } else if (data.type === ADVERTISEMENT_TYPE.EMPLOI) {
        data.description = addCData(data.description ?? "", {
          entreprise: data.entreprise,
        });
      }
      const event = fullEventSchema.parse(data) as BuildingEvent;
      if (
        data.type === ADVERTISEMENT_TYPE.IMMO ||
        data.type === ADVERTISEMENT_TYPE.EMPLOI
      ) {
        event.published = false;
      }
      console.log({ event, data });
      if (event.id) {
        // update event
        const originalEvent = await getEventById(event.id);
        const basicUpdate = updateEvent(event.id, {
          title: event.title,
          description: event.description?.trim() || "Pas de description",
          place: event.place,
          date: event.date,
          datefin: event.datefin,
          url_ticketing: event.url_ticketing,
          published: event.published,
        });
        const addCategoriesUpdate = Promise.allSettled(
          event.categories.map(async (category) => {
            if (
              !originalEvent.categories.find(
                (originalCategory) => originalCategory.id === category.id,
              )
            ) {
              return addEventCategory(event.id!, category.id);
            }
            return true;
          }),
        );

        const removeCategoriesUpdate = Promise.allSettled(
          originalEvent.categories.map(async (category) => {
            if (
              !event.categories.find(
                (originalCategory) => originalCategory.id === category.id,
              )
            ) {
              return deleteEventCategory(category.EventCategory.id);
            }
            return true;
          }),
        );

        const coordinatesUpdate = updateCoordinates(
          originalEvent.coordinate.id,
          {
            lat: event.lat,
            lng: event.lng,
            address: event.address,
          },
        );

        let pictureUpdate = Promise.resolve(true);
        if (event.picture && event.picture !== originalEvent.picture) {
          pictureUpdate = (async () => {
            try {
              const pictureId = await getPictureOfEvent(event.id!);
              return updatePicture(pictureId, event.picture!);
            } catch (e) {
              return addPicture(event.id!, event.picture!);
            }
          })();
        }

        const result = await Promise.allSettled([
          basicUpdate,
          addCategoriesUpdate,
          removeCategoriesUpdate,
          coordinatesUpdate,
          pictureUpdate,
        ]);
        if (result.some((result) => result.status === "rejected")) {
          try {
            console.error(
              result.map((result, index) => [
                index,
                result.status,
                "reason" in result ? result.reason : result.value,
              ]),
            );
            captureException(result, { extra: { event, result } });
            throw new Error("Error while updating event", {
              cause: result,
            });
          } catch (e) {
            console.error(e);
            throw new Error("Error while updating event", {
              cause: result,
            });
          }
        }
        const _ = (async (id) => {
          try {
            const refresh = refreshAdvertisement(id);
            const [ad] = await getAdvertisements({
              timenjoyId: [id],
              include: ["configToAdvertisement"],
            });
            const configs =
              ad?.ConfigToAdvertisements?.map((c) => c.config) ?? [];
            if (!configs.length) return;
            await refresh;
            configs.forEach(throttledRefreshConfig);
          } catch (e) {
            captureException(e, { extra: { id } });
            console.error(e);
          }
        })(event.id);
        return getEventById(event.id);
      } else {
        // create event
        const createdEvent = await createEvent({
          title: event.title,
          description: event.description?.trim() || "Pas de description",
          place: event.place,
          date: event.date,
          datefin: event.datefin,
          url_ticketing: event.url_ticketing,
          published: event.published,
        });

        await addEventToCompany(company.id, user.id, createdEvent.id);

        const categoriesCreation = Promise.allSettled(
          event.categories.map(async (category) => {
            return addEventCategory(createdEvent.id, category.id);
          }),
        );

        const coordinatesCreation = addCoordinates(createdEvent.id, {
          lat: event.lat,
          lng: event.lng,
          address: event.address,
        });

        const pictureCreation = addPicture(createdEvent.id, event.picture!);

        const result = await Promise.allSettled([
          categoriesCreation,
          coordinatesCreation,
          pictureCreation,
        ]);
        if (result.some((result) => result.status === "rejected")) {
          try {
            console.error(
              result.map((result, index) => [
                index,
                result.status,
                "reason" in result ? result.reason : result.value,
              ]),
            );
            captureException(result, { extra: { event, result } });
            throw new Error("Error while creating event", {
              cause: result,
            });
          } catch (e) {
            console.error(e);
            throw new Error("Error while creating event", {
              cause: result,
            });
          }
        }
        return getEventById(createdEvent.id);
      }
    },
    onSuccess(data, variables, context) {
      toast({
        title: "Succès",
        description: "L'événement a été enregistré avec succès",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      if (variables.id) {
        buildingEvent.update({});
        // navigate(`/app/events`);
      } else {
        buildingEvent.update(data);
      }
      navigate(
        `/app/events/${data.id}/finish?lastStep=${variables.id ? s.get("lastStep") : "true"}`,
      );
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Erreur",
        description:
          "Une erreur est survenue lors de l'enregistrement de l'événement",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
}
