import React from "react";
import { TimenjoyUser } from "@/types/timenjoy.types";
import { PublicAuthUser, listCompanyPolicies } from "@/service/auth.service";
import UserMenu from "@/components/context-menu/user-menu";
import useAuth from "@/hooks/useAuth";
import { useQuery } from "@tanstack/react-query";

interface IListItemProps {
  user: PublicAuthUser;
  legacyUser: TimenjoyUser;
}

const UserItem: React.FunctionComponent<IListItemProps> = ({
  legacyUser,
  user,
}) => {
  const auth = useAuth();
  const policy = useQuery({
    queryKey: ["companyPolicies", auth?.company?.id],
    async queryFn() {
      if (!auth?.company?.id) return Promise.reject();
      return listCompanyPolicies(auth.company.id);
    },
    enabled: !!auth?.company?.id,
  });
  return (
    <li className="hover:bg-background_light -mx-6 flex w-full cursor-pointer select-none items-center gap-2 rounded-md px-6 py-3 text-white">
      <div className="grow text-left">
        {legacyUser.firstname} {legacyUser.lastname}
        <div className="ml-3 inline-flex items-center justify-start gap-2.5 rounded-sm bg-fuchsia-700 px-[7px] py-1.5">
          <div className="text-[10px] font-medium tracking-tight text-white">
            {policy.data?.data?.find((p) => p.id === user.policyId)?.name}
          </div>
        </div>
      </div>
      <div className="w-80 shrink-0">{user.email}</div>
      <div className="flex gap-2"></div>
      <div className="w-20 shrink-0">
        {/* {auth?.user?.id !== user.id && (<UserMenu {...user} />)} */}
      </div>
    </li>
  );
};

export default UserItem;
