import KonnectzButton from "@/components/button";
import SelectionItem from "@/components/list-item/selection-item";
import Spinner from "@/components/spinner";
import WidgetHeadline from "@/components/widget-headline";
import { useDebounceEffect } from "@/hooks/useDebounceEffect";
import { usePermission } from "@/hooks/usePermissions";
import useR from "@/hooks/useR";
import { useSchedules } from "@/hooks/useSchedules";
import ScheduleAdvertisementModal from "@/modals/schedule-advertisements";
import { useReorderMutation } from "@/mutations/reorder-schedules";
import { RotationStrategy, Schedule } from "@/types/konnectz.types";
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Reorder } from "framer-motion";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Switch,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Text,
} from "@tremor/react";
import { useMainWorkPlaceSize } from "@/hooks/useMainWorkPlaceSize";
import { Separator } from "@/components/ui/separator";
import { useMutation } from "@tanstack/react-query";
import {
  getAdvertisements,
  throttledRefreshConfig,
  unscheduleAdvertisement,
  updateConfig,
} from "@/service/konnectz.service";
import { Info, Trash } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDelayedOff } from "@/hooks/useDelayedOff";
import { cn } from "@/lib/utils";
import { captureException } from "@/service/exceptions";
import ExcludeAdvertisementModal from "@/modals/exclude-advertisements";
import useExcludedAds from "@/hooks/useExcludedAds";
import ExclusionItem from "@/components/list-item/exclusion-item";
import { useToast } from "@chakra-ui/react";

interface ISelectionProps {}

function isSponsorized(item: Schedule) {
  return item.rotation_strategy !== RotationStrategy.FILLER;
}

const Selection: React.FunctionComponent<ISelectionProps> = (props) => {
  const { currentSelection, currentApiAccount, client } = useR();
  const [query, setQuery] = useSearchParams();
  const showActive = +(query.get("tab") ?? 0) === 0;
  const [scheduleModalOpen, setScheduleModalOpen] = React.useState(false);
  const [excludeModalOpen, setExcludeModalOpen] = React.useState(false);
  const { mutate, isLoading: isMutating } = useReorderMutation();
  const [active, setActive] = React.useState<Schedule[]>([]);
  const [future, setFuture] = React.useState<Schedule[]>([]);
  const [ended, setEnded] = React.useState<Schedule[]>([]);
  const { canUseSelection, isLoading: permissionLoading } = usePermission();
  const size = useMainWorkPlaceSize();
  const toast = useToast();

  const [excludedAds, refetch] = useExcludedAds();

  const rotationMutation = useMutation({
    async mutationFn({ rotation }: { rotation: boolean }) {
      return await updateConfig(currentSelection!.Config!.id!, { rotation });
    },
    onSuccess() {
      client.invalidateQueries(["selection", currentSelection!.id!]);
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
    },
  });

  const [{ data: schedules, isRefetching, isLoading }] = useSchedules();
  const isLoadingAds = isRefetching || isLoading;
  React.useEffect(() => {
    if (!schedules) return;
    const newActive: Schedule[] = [];
    const newFuture: Schedule[] = [];
    const newEnded: Schedule[] = [];
    schedules.forEach((item) => {
      const hasEnded = item.to < Date.now();
      if (hasEnded) return newEnded.push(item);
      const isActive = item.from < Date.now();
      if (isActive) newActive.push(item);
      else newFuture.push(item);
    });
    newEnded.sort((a, b) => {
      return b.to - a.to || b.from - b.from;
    });
    setActive(newActive);
    setFuture(newFuture);
    setEnded(newEnded);
  }, [schedules]);

  const currentList = showActive ? active : future;
  const updateCurrentList = showActive ? setActive : setFuture;

  const force = useDebounceEffect(
    () => {
      if (!schedules) return;

      const referenceItems: Schedule[] = schedules.filter((item) => {
        const hasEnded = item.to < Date.now();
        if (hasEnded) return false;
        const isActive = item.from < Date.now();
        return isActive === showActive;
      });
      const reorderedItems: Schedule[] = currentList;

      // if lists are the same, no need to reorder
      if (
        referenceItems.length === reorderedItems.length &&
        referenceItems.every(
          (item, index) => item.id === reorderedItems[index].id,
        )
      ) {
        return;
      }
      const controller = new AbortController();
      mutate({
        reorderedItems,
        referenceItems,
        signal: controller.signal,
      });
      return () => controller.abort();
    },
    1_000,
    [active, future],
  );

  function reorder(reorderedItems: string[]) {
    const newList = reorderedItems.map(
      (element) => currentList.find((i: Schedule) => i.id === element)!,
    );

    const orderedList = newList
      .filter(isSponsorized)
      .concat(newList.filter((i) => !isSponsorized(i)));
    updateCurrentList(orderedList);
  }

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!canUseSelection && !permissionLoading) {
      navigate("/app");
    }
  }, [currentApiAccount, currentSelection]);

  const delayedLoadingState = useDelayedOff(isLoading, 1_000);

  const unmask = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      try {
        const [{ ConfigToAdvertisements }] = await getAdvertisements({
          id: [id],
          include: ["configToAdvertisement"],
        });
        const matchingSchedule = ConfigToAdvertisements.find(
          (schedule) => schedule.config === currentSelection?.exclusionConfig,
        );
        if (matchingSchedule) {
          await unscheduleAdvertisement(matchingSchedule.id);
        }
      } catch (e) {
        console.error(e);
      }
    },
    onSuccess() {
      toast({
        title: "L'annonce est désormais visible",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onSettled() {
      throttledRefreshConfig(currentSelection!.config).catch(console.error);
      refetch();
    },
  });

  if (!currentApiAccount || !currentSelection?.Config) return null;

  return (
    <>
      <ScheduleAdvertisementModal
        open={scheduleModalOpen}
        key={+open}
        setOpen={setScheduleModalOpen}
        ids={schedules?.map((s) => s.Advertisement.timenjoyId)}
      />
      <ExcludeAdvertisementModal
        open={excludeModalOpen}
        setOpen={setExcludeModalOpen}
      />
      <div className="text-subtitle h-full w-full">
        <div className="noscrollbar flex max-h-full w-full flex-col overflow-scroll px-16">
          <WidgetHeadline />
          <div className="mt-8 flex w-full flex-wrap items-center justify-between">
            <h1 className="text-title flex select-none items-center gap-4 text-2xl font-semibold">
              Ma sélection{" "}
              {(isLoadingAds || isMutating) && <Spinner className="h-5 w-5" />}
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <Switch
                  checked={currentSelection.Config!.rotation}
                  onChange={() => {
                    if (rotationMutation.isLoading) return;
                    rotationMutation.mutate({
                      rotation: !currentSelection.Config!.rotation,
                    });
                  }}
                />
                <Text className="text-subtitle text-sm">
                  Rotation automatique
                </Text>

                <Tooltip>
                  <TooltipTrigger>
                    <Info
                      className="h-5 w-5"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="w-72">
                      Activer la rotation automatique modifiera l'ordre des
                      annonces de votre sélection{" "}
                      <span className="underline underline-offset-2">
                        toutes les 3 heures.
                      </span>
                      <br />
                      Les annonces{" "}
                      <span className="text-konnectz_accent">
                        sponsorisées&nbsp;
                      </span>
                      resteront toujours au début de la sélection.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
              <Separator orientation="vertical" />
              <KonnectzButton
                onClick={() => setScheduleModalOpen(true)}
                variant="tertiary"
                className="flex items-center gap-[6px]"
              >
                Ajouter <PlusIcon className="h-4 w-4" />
              </KonnectzButton>
            </div>
          </div>
          <TabGroup
            className="grid h-full shrink grid-rows-[38px_1fr] overflow-hidden"
            style={{ height: size.height - 122 }}
            defaultIndex={+(query.get("tab") ?? 0) || 0}
            onIndexChange={(tab) => {
              setQuery((prev) => {
                prev.set("tab", tab + "");
                prev.delete("old");
                return prev;
              });
            }}
          >
            <TabList>
              <Tab>En cours ({active.length})</Tab>
              <Tab>Programmées ({future.length})</Tab>
              <Tab>Terminées ({ended.length})</Tab>
              <Tab>Masquées ({excludedAds.length})</Tab>
            </TabList>
            <TabPanels
              className="flex h-full shrink flex-col"
              style={{ height: size.height - 122 - 38 }}
            >
              <TabPanel className="mt-0 h-full">
                <SelectionList
                  data={active}
                  emptyMessage={
                    <div
                      className={cn(
                        "bg-secondary/60 mx-2 flex h-full w-full flex-col items-stretch justify-center rounded-xl",
                        delayedLoadingState && "hidden",
                      )}
                      style={{ height: size.height - 122 - 38 - 16 - 32 }}
                    >
                      <div className="flex w-full flex-col items-center overflow-y-auto py-8">
                        <h1 className="text-title flex select-none items-center gap-4 text-2xl font-semibold">
                          Aucune annonce en cours
                        </h1>
                        <p className="text-md text-title mt-3 flex max-w-lg select-none items-center gap-4 font-normal">
                          Votre sélection est vide. Ajoutez des annonces
                          existantes pour les diffuser sur votre site.
                        </p>
                        <div className="mt-6 grid grid-cols-2 gap-3">
                          <div
                            className="border-konnectz_background bg-emphasis_dark flex aspect-[7_/_6] w-56 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 font-semibold text-white hover:border-white"
                            onClick={() => navigate("/app/events/new")}
                          >
                            <PlusCircleIcon className="h-12 w-12" />
                            Créer une annonce
                            <br />
                            &nbsp;
                          </div>
                          <div
                            className="border-konnectz_background bg-konnectz_accent flex aspect-[7_/_6] w-56 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 font-semibold text-white hover:border-white"
                            onClick={() => setScheduleModalOpen(true)}
                          >
                            <PlusCircleIcon className="h-12 w-12" />
                            Ajouter une annonce
                            <br />
                            existante
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  isLoading={isLoading}
                  reorder={reorder}
                  flush={force}
                  draggable
                />
              </TabPanel>
              <TabPanel className="mt-0 h-full">
                <SelectionList
                  data={future}
                  emptyMessage={
                    <p
                      className="text-md text-title mx-auto mt-6 flex w-full max-w-lg select-none items-center justify-center font-normal"
                      style={{ height: size.height - 122 - 38 - 16 - 32 }}
                    >
                      Vous n'avez aucune annonce programmé.
                    </p>
                  }
                  isLoading={isLoading}
                  reorder={reorder}
                />
              </TabPanel>
              <TabPanel className="mt-0 h-full">
                <SelectionList
                  data={ended}
                  emptyMessage={
                    <p
                      className="text-md text-title mx-auto mt-6 flex w-full max-w-lg select-none items-center justify-center font-normal"
                      style={{ height: size.height - 122 - 38 - 16 - 32 }}
                    >
                      Aucune annonce à afficher.
                    </p>
                  }
                  isLoading={isLoading}
                  reorder={reorder}
                />
              </TabPanel>
              <TabPanel className="mt-0 h-full">
                <div
                  className="noscrollbar -mr-16 box-content flex h-full w-full table-auto flex-col overflow-y-scroll pr-16 pt-4"
                  style={{ height: size.height - 122 - 38 - 16 }}
                >
                  {!isLoading && excludedAds.length === 0 ? (
                    <div className="flex w-full items-center justify-center">
                      <span className="text-subtitle w-full text-center">
                        <p
                          className="text-md text-title mx-auto mt-6 flex flex-col w-full max-w-lg select-none items-center justify-center font-normal"
                          style={{ height: size.height - 122 - 38 - 16 - 32 }}
                        >
                          Aucune annonce masquée.{" "}
                          <Button
                            className="mt-2"
                            onClick={() => setExcludeModalOpen(true)}
                          >
                            <PlusIcon className="h-4 w-4" /> Masquer une
                            nouvelle annonce
                          </Button>
                        </p>
                      </span>
                    </div>
                  ) : (
                    <p
                      className="hover:bg-muted ml-2 w-full cursor-pointer rounded-sm py-2"
                      onClick={() => setExcludeModalOpen(true)}
                    >
                      <span className=" ml-20 flex flex items-center gap-1 py-1">
                        <PlusIcon className="h-4 w-4" /> Masquer une nouvelle
                        annonce
                      </span>
                    </p>
                  )}
                  {(excludedAds.length !== 0 || isLoading) && (
                    <div className="oveflow-y-scroll display flex w-full flex-col gap-1">
                      {excludedAds.map((item) => (
                        <ExclusionItem
                          size="small"
                          item={{
                            ...item.Advertisement,
                            picture: item.Advertisement.Pictures[0].filename,
                            metaData: {},
                          }}
                          excluded={false}
                          actions={
                            <Button
                              size="icon"
                              variant="ghost"
                              className="hover:bg-muted-foreground"
                              onClick={() => {
                                unmask.mutate({ id: item.advertisement });
                              }}
                            >
                              <Trash className="h-4 w-4" />
                            </Button>
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </>
  );
};

export default Selection;

interface ISelectionListProps {
  data: Schedule[];
  reorder?: (data: string[]) => void;
  flush?: () => void;
  isLoading: boolean;
  emptyMessage: React.ReactNode;
  isMutating?: boolean;
  draggable?: boolean;
  head?: React.ReactNode;
}

function SelectionList({
  data,
  head,
  emptyMessage,
  reorder,
  isLoading,
  isMutating,
  draggable = false,
  flush,
}: ISelectionListProps) {
  const size = useMainWorkPlaceSize();
  return (
    <div
      className="noscrollbar -mx-16 box-content flex h-full w-full table-auto flex-col overflow-y-scroll px-14 pt-4"
      style={{ height: size.height - 122 - 38 - 16 }}
    >
      {!isLoading && data.length === 0 && (
        <div className="flex w-full items-center justify-center">
          <span className="text-subtitle w-full text-center">
            {emptyMessage}
          </span>
        </div>
      )}
      {(isLoading || !!data.length) && (
        <>
          <div className="mb-4 flex w-full gap-2 font-semibold">
            <div className="w-60 shrink-0 grow pl-28 text-left">
              {/* Image + */}Nom de l'événement
            </div>
            <div className="w-24 shrink-0">{/* Sponsorisé */}</div>
            <div className="w-40 shrink-0 break-words">Diffusion</div>
            <div className="w-52 shrink-0">Lieu</div>
            <div className="w-20 shrink-0">{/* Actions */}</div>
          </div>
          {head}
        </>
      )}
      {(data.length !== 0 || isLoading) && (
        <Reorder.Group
          as="ol"
          axis="y"
          values={data.map((i) => i.id)}
          onReorder={reorder ?? (() => {})}
          layoutScroll
          onMouseUp={flush}
          className="oveflow-y-scroll display flex w-full flex-col gap-1"
          animate={false}
        >
          {data.map((item) => (
            <SelectionItem
              key={item.id}
              id={item.id}
              advertisement={item.Advertisement}
              name={item.Advertisement.firstLine}
              url={item.Advertisement.originLink}
              picture={item.Advertisement.Pictures[0]?.filename}
              location={item.Advertisement.thirdLine}
              from={item.from}
              to={item.to}
              sponsorized={isSponsorized(item)}
              drag={draggable && !isMutating && !isLoading}
            />
          ))}
        </Reorder.Group>
      )}
    </div>
  );
}
