import { Timestamp } from "./apis.types";

export type CreateConfigPayload = {
  selection: string;
  source: string;
  source_id: string;
  origins?: string[];
  rotation?: boolean;
};

export type Picture = {
  id: string;
  filename: string;
  createdAt: string;
  updatedAt: string;
  Advertisement: Advertisement;
};

export type Advertisement = {
  id: string;
  timenjoyId: string;
  firstLine: string;
  secondLine: string;
  thirdLine: string;
  previewLink: string;
  originLink: string;
  type: "event" | "immo" | "emploi";
  createdAt: string;
  updatedAt: string;
  ConfigToAdvertisements: Schedule[];
  Pictures: Picture[];
  metaData: string;
};

export enum RotationStrategy {
  ROTATE = "rotate",
  FILLER = "filler",
  FIXED = "fixed",
}

export type Schedule = {
  id: string;
  from: number;
  to: number;
  rotation_strategy: RotationStrategy;
  createdAt: string;
  updatedAt: string;
  order: number;
  config: string;
  advertisement: string;
  Advertisement: Advertisement;
};

export type Configs = {
  id: string;
  app_secret: string;
  selection: string;
  source: string;
  source_id: string;
  rotation: boolean;
  Customization: {
    id: string;
    version: string | null;
    config: string;
    json: string;
  } & Timestamp;
  ConfigToAdvertisements: Schedule[];
  origin_config: Composition[];
} & Timestamp;

export type Composition = {
  id: string;
  origin: string;
  target: string;
  priority: number;
  type: CompositionType;
};

export enum CompositionType {
  EXCEPT = "EXCEPT",
  OTHER = "OTHER", // TODO: add other types
}

export type Customization = {
  json: string;
};

export type Origin = {
  id: string;
  origin: string;
  config_to_origins: {
    config: string;
    origin: string;
  } & Timestamp;
} & Timestamp;

export type WidgetContent = {
  id: string;
  meta: {
    name: string;
    issuedAt: number;
    expiresAt: number;
  };
  analytics: {
    source: string;
    source_id: string;
  };
  customization: Record<string, any>;
  compositions: string[];
  advertisements: {
    id: string;
    firstLine: string;
    picture: string;
    metaData: Partial<{
      entreprise: string;
      surface: number;
      rooms: number;
      price: number;
      categories: string[];
      zones: string[];
      dates: {
        start: string;
        end: string;
      }[];
    }>;
  }[];
};
