import WidgetHeadline from "@/components/widget-headline";
import useR from "@/hooks/useR";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Options from "@/components/options";

const UpdateWidget: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { client } = useR();
  const apiAccount = client.getQueryData<TimenjoyApiAccount>([
    "apiAccount",
    id,
  ]);

  React.useEffect(() => {
    if (!apiAccount) {
      client.invalidateQueries(["apiAccount", id]);
      navigate("/app");
      return;
    }
  }, [id]);

  function next() {
    navigate("/app/selection");
  }

  if (!apiAccount) return null;
  return (
    <div className="w-full min-h-full flex flex-col pb-12 px-16">
      <h1 className="text-2xl font-semibold text-title">
        {apiAccount ? "Modifier mon widget" : "Créer un nouveau widget"}
      </h1>
      <p className="mt-4 w-full max-w-3xl text-subtitle">
        Créer un nouveau widget à intégrer sur votre site internet. Indiquez les
        informations spécifiques à votre widget ci-dessous. N'oubliez pas de
        préciser le format, la catégorie d'événement et le trafic mensuel
        souhaité. À vous de jouer&nbsp;!
      </p>
      {apiAccount && (
        <div className="mt-8">
          <div className="flex justify-between">
            <WidgetHeadline />
          </div>
        </div>
      )}
      <section className="mt-8 h-full grow">
        <Options
          id={id}
          next={next}
          openPremiumPaywall
        />
      </section>
    </div>
  );
};

export default UpdateWidget;
