import dayjs from "dayjs";
import { z } from "zod";
import { getToken } from "./auth.service";
import { AUTH_URL } from "./environment";

// const ANALYTICS_URL: string = "http://localhost:5001/api";
// const ANALYTICS_URL: string = "https://tnj-smart-analytics.oriun.fr/api";
const ANALYTICS_URL = `${AUTH_URL}/analytics`;

function fillTimeSerie<T extends { hour: string }>(
  defaultValue: T,
  hours: number,
  end: dayjs.Dayjs = dayjs().startOf("hour"), // default to now if no end paramet
) {
  // fill the array with 0 if there is no data for a specific hour
  return (arr: T[]) => {
    const array = arr.map((item) => ({
      ...item,
      hour: dayjs(item.hour).startOf("hour").toISOString(),
    }));
    const newArray = [] as T[];
    for (let i = 0; i < hours; i++) {
      const hour = end.subtract(i, "hour");
      const item = array.find((item) => hour.toISOString() === item.hour);
      if (item) {
        newArray.push({ ...item, hour: hour.format("DD/MM HH[h]") });
      } else {
        newArray.push({
          ...defaultValue,
          hour: hour.format("DD/MM HH[h]"),
        });
      }
    }
    return newArray.reverse();
  };
}

const selectionSummarySchema = z.object({
  impression_per_origin: z
    .object({
      impressions: z.coerce.number(),
      origin: z.string(),
    })
    .array()
    .transform((v) => {
      return v.filter(
        (item) =>
          !item.origin.includes("konnectz") &&
          !item.origin.includes("widget.timenjoy.fr") &&
          !item.origin.includes("r2.dev") &&
          !item.origin.includes("localhost"),
      );
    }),
  timeSerie: z
    .object({
      impressions: z.coerce.number(),
      clics: z.coerce.number(),
      interactions: z.coerce.number(),
      hour: z.string(),
    })
    .array()
    .transform(
      fillTimeSerie(
        { hour: "", impressions: 0, clics: 0, interactions: 0 },
        24 * 30,
      ),
    ),
});
export async function getSelectionSummary(selection: string, period: string) {
  const res = await fetch(`${ANALYTICS_URL}/selection/${selection}/summary`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "include",
  });
  const data = await res.json();
  const response = selectionSummarySchema.parse(data);
  const todaysHours = dayjs().diff(dayjs().startOf("day"), "hour") || 1;
  console.log("period", period, todaysHours);
  switch (period) {
    case "14_days":
      return {
        ...response,
        timeSerie: response.timeSerie.slice(-24 * 14),
      };
    case "7_days":
      return {
        ...response,
        timeSerie: response.timeSerie.slice(-24 * 7),
      };
    case "yesterday":
      return {
        ...response,
        timeSerie: response.timeSerie.slice(-24 - todaysHours, -todaysHours),
      };
    case "today":
      return {
        ...response,
        timeSerie: response.timeSerie.slice(-todaysHours),
      };
    default:
      return response;
  }
}

export async function getAdvertisementsStats(
  selection: string,
  from: string,
  to: string,
) {
  const res = await fetch(
    `${ANALYTICS_URL}/v2/selection/${selection}/advertisements?from=${from}&to=${to}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      credentials: "include",
    },
  );
  const { data } = (await res.json()) as {
    data: {
      advertisement_id: string;
      total_impressions: number;
      total_clics: number;
      data: {
        hour: string;
        impressions: number;
        clics: number;
      }[];
    }[];
    compute_time: number;
  };
  let result = data;
  result.sort((a, b) => b.total_impressions - a.total_impressions);
  return result;
}
