import React from "react";
import clsx from "clsx";

type Props = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
};

const EmailInput = (props: Props) => {
  return (
    <div
      className={clsx(
        "flex items-center p-2 rounded-md bg-neutral-500/50 outline-none border border-konnectz_background focus:border-indigo-600",
        props.className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-borders w-7 h-7"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
      </svg>
      <input
        type="email"
        placeholder="hello@konnectz.io"
        id="email"
        value={props.value}
        onChange={props.onChange}
        autoComplete="email"
        className="w-full p-1 ml-3 text-title bg-transparent"
      />
    </div>
  );
};

export default EmailInput;
