export function standardize(str: string) {
    return str
        .toLowerCase()
        .replace(/[aàâäã]/gm, "a")
        .replace(/[eéèêë]/gm, "e")
        .replace(/[iìîïî]/gm, "i")
        .replace(/[oòôöõ]/gm, "o")
        .replace(/[uùûü]/gm, "u")
        .replace(/[cç]/gm, "c")
        .replace(/\W/gm, "")
}