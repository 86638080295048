import * as Sentry from "@sentry/react";
import AppMenu from "@/components/app-menu";
import TrialCountDownBanner from "@/components/trial-count-down-banner";
import { MainWorkPlaceSizeProvider } from "@/hooks/useMainWorkPlaceSize";
import { usePermission } from "@/hooks/usePermissions";
import { isLoggedIn, logout } from "@/service/auth.service";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const WebAppLayout: React.FC<Props> = ({ children }) => {
  const permissions = usePermission()
  const trialCountDownBanner = permissions.isTrial && !permissions.trialExpired && permissions.trialLeft < 7
  const navigate = useNavigate()
  useEffect(() => {
    Sentry.addIntegration(Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "dark",
      autoInject: true,
      id: "sentry-feedback",
      buttonLabel: "",
      isEmailRequired: false,
      isNameRequired: false,
      messageLabel: "Description",
      messagePlaceholder: "Décrivez le bug que vous avez rencontré",
      cancelButtonLabel: "Annuler",
      submitButtonLabel: "Envoyer",
      formTitle: "Signaler un bug",
      showBranding: false,
      showEmail: false,
      showName: false,
      themeDark: {
        background: "#161b22",
        backgroundHover: "#40444A",
        submitBackground: "#75BFD6",
        submitBorder: "#75BFD6",
        submitOutlineFocus: "none",
        submitBackgroundHover: "#75BFD6"
      },
      successMessageText: "Merci pour votre retour !",
    }));
  }, [])
  const location = useLocation()
  useQuery({
    queryKey: ["auth"],
    queryFn: async () => {
      return isLoggedIn()
    },
    refetchInterval: 1000 * 60 * 5,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: 0,
    async onError() {
      await logout()
      navigate("/auth/logout?redirect=" + location.pathname + location.search)
    }
  })
  return (
    <>
      <main className="w-screen h-screen flex items-stretch bg-background_dark p-8  bg-radial from-konnectz_background to-background_dark">
        <section className="w-56 shrink-0 pt-9 z-10 rounded-lg overflow-y-auto noscrollbar">
          <AppMenu />
        </section>
        <MainWorkPlaceSizeProvider>
          <section className="w-full shrink pt-12 overflow-auto noscrollbar relative rounded-lg border-background_light border shadow shadow-background_light/25 bg-radial from-konnectz_background/80 to-konnectz_background">
            <section className="w-full max-w-screen-xl">
              {children}
            </section>
          </section>
        </MainWorkPlaceSizeProvider>
      </main>
      <TrialCountDownBanner show={trialCountDownBanner} daysLeft={permissions.trialLeft} />
    </>
  );
};

export default WebAppLayout;
