import clsx from "clsx";
import * as React from "react";

interface ICheckboxInputProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  checked?: boolean;
}

const CheckboxInput: React.FunctionComponent<ICheckboxInputProps> = ({
  className,
  children,
  onClick,
  checked,
}) => {
  return (
    <div
      className={clsx("flex items-center py-2 cursor-pointer", className)}
      onClick={onClick}
    >
      <div
        className={clsx(
          "w-4 h-4 rounded-sm border border-white",
          checked && "bg-konnectz_accent"
        )}
      />
      <label className="ml-3 cursor-pointer flex items-center">{children}</label>
    </div>
  );
};

export default CheckboxInput;
