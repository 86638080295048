import Button from "@/components/button";
import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Title,
  Text,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  SparkAreaChart,
  MultiSelectItem,
  MultiSelect,
} from "@tremor/react";
import useAdvertisementsListAnalytics from "@/hooks/useAdvertisementsListAnalytics";
import { useQuery } from "@tanstack/react-query";
import { getAdvertisements } from "@/service/konnectz.service";
import Spinner from "../spinner";
import { numberFormatter, percentageFormatter } from "@/utils/numbers";
import { DatePickerWithPresets } from "../ui/date-picker-with-presets";
import dayjs from "dayjs";
import { sumArray } from "@/utils/array";

export interface IAdvertisementStatsListProps {}

export default function AdvertisementStatsList(
  _props: IAdvertisementStatsListProps,
) {
  const [query, setQuery] = useSearchParams();
  const {
    permissions: { isPremium },
  } = useR();
  const { policy } = useAuth();
  const navigation = useNavigate();
  React.useEffect(() => {
    if (!isPremium || !policy?.canConsultStatistics) {
      navigation("/app");
    }
  }, [isPremium, policy?.canConsultStatistics]);
  const { currentSelection } = useR();
  const oldSelection = React.useRef<string | undefined>();
  React.useEffect(() => {
    if (!oldSelection.current) return;
    if (oldSelection.current === currentSelection?.id) return;
    oldSelection.current = currentSelection?.id;
    query.delete("from");
    query.delete("to");
    query.delete("selected");
  }, [currentSelection]);
  const from =
    query.get("from") && dayjs(query.get("from")).isValid()
      ? dayjs(query.get("from")).startOf("day").toDate()
      : dayjs().subtract(30, "days").startOf("day").toDate();
  const to =
    query.get("to") && dayjs(query.get("to")).isValid()
      ? dayjs(query.get("to")).endOf("day").toDate()
      : dayjs().add(1, "day").endOf("day").toDate();

  const { data, isLoading, isError } = useAdvertisementsListAnalytics(
    from.toISOString(),
    to.toISOString(),
  );
  const adsQuery = useQuery({
    queryKey: [
      "advertisements",
      data?.map((item) => item.advertisement_id).sort(),
    ],
    async queryFn({ queryKey: [, ids = []] = [] }) {
      return getAdvertisements({
        id: ids as string[],
      });
    },
    staleTime: 1000 * 60 * 60,
  });
  const selectedAdvertisements = query.get("selected")?.split(",") || [];
  console.log(
    "ads list",
    selectedAdvertisements,
    query.toString(),
    Array.from(query.entries()),
    query.get("selected"),
  );
  function setSelectedAdvertisements(ids: string[]) {
    setQuery((prev) => {
      if (ids.length === 0) prev.delete("selected");
      else prev.set("selected", ids.join(","));
      return prev;
    });
  }

  const dataDisplayed = (data ?? [])
    .filter(
      (item) =>
        !selectedAdvertisements.length ||
        selectedAdvertisements.includes(item.advertisement_id),
    )
    .filter((item) => item.total_impressions > 1);
  function downloadCSV() {
    const days = Array.from(
      { length: dayjs(to).diff(from, "day") + 1 },
      (_, i) => dayjs(from).add(i, "days"),
    );
    const headers = [
      "Selection",
      "Annonce",
      "Impressions",
      "Clics",
      "CTR",
      ...days.flatMap((d) => [d.format("DD MMM"), ""]),
      "Id",
    ].join(";");
    const csv = dataDisplayed
      .flatMap((item) => {
        const advertisement = adsQuery.data?.find(
          (ad) => ad.id === item.advertisement_id,
        );
        if (!advertisement) return [];

        return [
          currentSelection!.name,
          advertisement.firstLine,
          numberFormatter(item.total_impressions),
          numberFormatter(item.total_clics),
          percentageFormatter(
            item.total_clics / (item.total_impressions || NaN),
          ),
          ...days.flatMap((d) => {
            const row = item.data
              .filter((r) => d.isSame(r.hour, "day"))
              .map(({ hour, ...d }) => d);
            return [
              sumArray(row, "impressions") || 0,
              sumArray(row, "clics") || 0,
            ];
          }),
          advertisement.id,
        ].join(";");
      })
      .join("\n");
    const blob = new Blob([headers + "\n" + csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `konnectz_analytics_${dayjs(from).format("DD[_]MMMM")}_${dayjs(to).format("DD[_]MMMM")}.csv`,
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between gap-3 py-2">
        <div className="w-max">
          <MultiSelect
            value={selectedAdvertisements}
            onValueChange={setSelectedAdvertisements}
            placeholder="Filtrer les annonces..."
            className="max-w-sm w-80"
          >
            {data?.map((item) => {
              const advertisement = adsQuery.data?.find(
                (ad) => ad.id === item.advertisement_id,
              );
              return (
                <MultiSelectItem
                  key={item.advertisement_id}
                  value={item.advertisement_id}
                >
                  {advertisement ? (
                    advertisement.firstLine
                  ) : (
                    <div className="w-40 h-4 bg-gray-200 rounded animate-pulse" />
                  )}
                </MultiSelectItem>
              );
            })}
          </MultiSelect>
        </div>
        <div className="w-full flex justify-start relative gap-2 shrink">
          <div className="flex items-center">
            <Text className="text-subtitle">Période&nbsp;:</Text>
          </div>
          <DatePickerWithPresets
            // key={to.toISOString()}
            className="w-full max-w-[200px]"
            onChange={(from, to) => {
              setQuery((prev) => {
                prev.set("from", from.toISOString());
                prev.set("to", dayjs(to).endOf("day").toISOString());
                return prev;
              });
            }}
            from={from}
            to={to}
          />
        </div>
        <Button
          variant="outline"
          className="border-tremor-border shrink-0"
          onClick={downloadCSV}
        >
          Exporter en CSV
        </Button>
      </div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Title className="text-4xl font-semibold text-title">
            Chargement
          </Title>
          <Spinner />
        </div>
      ) : null}
      {isError ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Title className="text-4xl font-semibold text-title">Erreur</Title>
          <Text className="text-lg text-subtitle">
            Une erreur est survenue lors du chargement des données.
          </Text>
          <Button className="mt-4" onClick={() => window.location.reload()}>
            Recharger
          </Button>
        </div>
      ) : null}
      {data && !data.length ? (
        <div className="flex flex-col items-center justify-center h-full">
          <Title className="text-4xl font-semibold text-title">
            Pas de données
          </Title>
          <Text className="text-lg text-subtitle">
            Vous n'avez pas encore de données.
          </Text>
        </div>
      ) : null}
      {data ? (
        <Table className="pb-2">
          <TableHead>
            <TableRow>
              <TableHeaderCell className=" sticky top-0 bg-background_dark">
                Annonce
              </TableHeaderCell>
              <TableHeaderCell className="text-left sticky top-0 bg-background_dark">
                Impressions
              </TableHeaderCell>
              <TableHeaderCell className="text-right sticky top-0 bg-background_dark">
                Clics
              </TableHeaderCell>
              <TableHeaderCell className="text-right sticky top-0 bg-background_dark">
                CTR
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataDisplayed.map((item) => {
              const advertisement = adsQuery.data?.find(
                (ad) => ad.id === item.advertisement_id,
              );
              return (
                <TableRow
                  title={item.advertisement_id}
                  key={item.advertisement_id}
                  className="cursor-pointer"
                >
                  <TableCell className="flex items-center">
                    {advertisement ? (
                      <img
                        src={`https://konnectz.timenjoy.fr/pictures/${advertisement.Pictures[0]?.filename}.webp`}
                        alt={advertisement.firstLine}
                        className="w-20 h-10 rounded-sm"
                      />
                    ) : (
                      <div className="w-20 h-10 bg-gray-200 rounded-sm animate-pulse shrink-0" />
                    )}
                    <span className="ml-6 text-ellipsis w-full">
                      {advertisement ? (
                        advertisement.firstLine
                      ) : (
                        <div className="w-40 h-4 bg-gray-200 rounded animate-pulse" />
                      )}
                    </span>
                  </TableCell>
                  <TableCell className="text-right">
                    <div className="flex flex-row items-center justify-start">
                      {numberFormatter(item.total_impressions)}
                    </div>
                  </TableCell>
                  <TableCell className="text-right">
                    {numberFormatter(item.total_clics)}
                  </TableCell>
                  <TableCell className="text-right">
                    {percentageFormatter(
                      item.total_clics / (item.total_impressions || NaN),
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : null}
    </div>
  );
}
