import { QueryClient } from "@tanstack/react-query";

export function clearQueryClient(
  client: QueryClient,
  { keepBrowserToken = true } = {}
) {
  const token = client.getQueryData(["browserToken"]);
  client.getQueryCache().clear();
  client.getMutationCache().clear();
  client.clear();
  if (keepBrowserToken) {
    client.setQueryData(["browserToken"], token);
  }
}
