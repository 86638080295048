if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function (callback, thisArg) {
    for (let i = this.length - 1; i >= 0; i--) {
      if (callback.call(thisArg, this[i], i, this)) return i;
    }
    return -1;
  };
}
if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    const i = index >= 0 ? index : this.length + index;
    return this[i];
  };
}

export {};
