import EventPreview from '@/components/event-preview';
import Steps from '@/components/steps';
import WidgetHeadline from '@/components/widget-headline';
import { ADVERTISEMENT_TYPE, ADVERTISEMENT_TYPE_LABELS, useBuildingEvent } from '@/hooks/useBuildingEvent';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface IEventBuilderLayoutProps {
    children: React.ReactNode;
}

// Must correspond to endpoints
export const enum EventBuilderStepsEnum {
    base = "base",
    location = "location",
    infos = "infos",
    infos2 = "infos-2"
}

const eventStepsList = Object.entries({
    [EventBuilderStepsEnum.base]: "Contenu",
    [EventBuilderStepsEnum.location]: "Date et lieu",
    [EventBuilderStepsEnum.infos]: "Informations"
})

const immoStepsList = Object.entries({
    [EventBuilderStepsEnum.base]: "Contenu",
    [EventBuilderStepsEnum.infos2]: "Informations"
})

const emploiStepsList = Object.entries({
    [EventBuilderStepsEnum.base]: "Contenu",
    [EventBuilderStepsEnum.infos2]: "Informations"
})

const StepsLists = {
    [ADVERTISEMENT_TYPE.EVENT]: eventStepsList,
    [ADVERTISEMENT_TYPE.IMMO]: immoStepsList,
    [ADVERTISEMENT_TYPE.EMPLOI]: emploiStepsList,
}

const EventBuilderLayout: React.FunctionComponent<IEventBuilderLayoutProps> = (props) => {
    const { id, type } = useBuildingEvent()
    const stepsList = StepsLists[type ?? ADVERTISEMENT_TYPE.EVENT]
    const navigate = useNavigate()
    const location = useLocation()
    const pathname = location.pathname.split('/').at(-1) ?? ""
    const currentStep = stepsList.findIndex(step => step[0] === pathname) + 1

    React.useEffect(() => {
        if (!type) navigate(`/app/events/${id ?? 'new'}`)
    }, [type])

    function setCurrentStep(stepNumber: number) {
        if (stepNumber >= stepsList.length) return
        if (stepNumber < 0) return
        navigate(stepsList[stepNumber][0])
    }

    if (!type) return null

    return <div className='text-white grid grid-cols-5 h-full w-full pb-16'>
        <div className='col-start-1 col-end-4 px-8 w-full flex flex-col items-start'>
            <WidgetHeadline hideIfSingle className="mb-4" />
            <h1 className='mb-6 font-semibold'>
                {type && <span className='bg-white text-konnectz_background rounded py-1 px-2 text-sm font-semibold mr-2'>{ADVERTISEMENT_TYPE_LABELS[type]}</span>}
                {id ? "Modifier mon annonce" : "Créer une annonce"}
            </h1>
            <Steps className='max-w-md mx-0 mb-8' stepsItems={stepsList.map(step => step[1])} currentStep={currentStep} setCurrentStep={setCurrentStep} />
            {props.children}
        </div>
        <div className='h-full w-full px-8 col-start-4 col-end-6'>
            <EventPreview />
        </div>
    </div>
};

export default EventBuilderLayout;
