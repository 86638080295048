import React from "react";
import { useNavigate } from "react-router-dom";

const AppEntryPoint: React.FC = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    return navigate("/app/dashboard", { replace: true });
  }, []);
  return null;
};

export default AppEntryPoint;
