import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { cn } from "@/lib/utils";

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  showLength?: boolean;
  containerClassName?: string;
}

const TextInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextInputProps
> = (
  { className, containerClassName, disabled, maxLength, showLength, ...props },
  ref,
) => {
  return (
    <div className={cn("relative", containerClassName)}>
      {disabled ? (
        <div className="absolute inset-0 z-2 cursor-not-allowed" />
      ) : null}
      <input
        ref={ref}
        {...props}
        maxLength={maxLength}
        className={cn(
          "w-full p-3 text-title bg-neutral-500/50 outline-none border border-konnectz_background focus:border-indigo-600 shadow-sm rounded-lg",
          className,
        )}
      />
      {(!!maxLength || (showLength && props.value)) && (
        <div className="absolute bottom-2 right-2 text-sm text-neutral-500">
          {(props.value ?? "").toString().length}
          {maxLength === undefined ? "" : "/" + maxLength}
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(TextInput);
