import Button from "@/components/button";
import SnippetBlock from "@/components/snippet-block";
import useR from "@/hooks/useR";
import React from "react";
import { useNavigate } from "react-router-dom";
import "@lottiefiles/lottie-player";

const OnboardingFinish = () => {
  const { currentSelection } = useR();
  const navigate = useNavigate();
  const code = `<tnj-widget data-selection="${currentSelection?.Config?.selection
    }" data-app_secret="${decodeURIComponent(
      currentSelection?.Config?.app_secret || ""
    )}"></tnj-widget>`;

  return (
    <section className="w-full max-w-screen-md shrink-0 pb-4 text-subtitle flex items-start">
      <div className="relative -top-12">
        <lottie-player
          autoplay
          src={import.meta.env.BASE_URL + "lotties/medal.json"}
          intermission={15_000}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
          }}
          loop
          background="transparent"
          style={{ width: 300, height: 300 }}
        />
      </div>
      <div className="flex flex-col items-start">
        <h3 className="text-title text-2xl font-bold sm:text-3xl w-full">
          Félicitations&nbsp;!
        </h3>
        <p className="mt-4 w-full">
          Votre widget est prêt à être ajouté à votre site web. Pour cela,
          collez le code ci-dessous dans un champ html de votre CMS ou le code
          source de votre site web.&nbsp;
          <a
            href={import.meta.env.BASE_URL + "guides/implementing"}
            target="_blank"
            className="text-emphasis font-semibold"
          >
            Comment faire ?
          </a>
        </p>

        <SnippetBlock code={code} />
        <Button
          className="mt-8"
          onClick={() => navigate("/app")}
          children="Accéder au dashboard"
        />
      </div>
    </section>
  );
};

export default OnboardingFinish;
