import React from "react";
import EventMenu from "@/components/context-menu/event-menu/v2";
import { TimenjoyEvent } from "@/types/timenjoy.types";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { useAssociatedSelections } from "@/hooks/useAssociatedSelections";
import Spinner from "@/components/spinner";
import { cleanName } from "@/utils/name";
import { AlertTriangle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

interface IListItemProps
  extends Pick<
    TimenjoyEvent,
    | "id"
    | "picture"
    | "title"
    | "published"
    | "categories"
    | "place"
    | "url_ticketing"
    | "site"
    | "company_events"
    | "date"
  > {
  showSelection?: boolean;
}

const EventItem: React.FunctionComponent<IListItemProps> = (props) => {
  const [query, displayedIn] = useAssociatedSelections(
    props.id,
    props.showSelection,
  );
  const [imageError, setImageError] = React.useState(false);
  return (
    <li className="hover:bg-background_light hover:bg-secondary select-none cursor-pointer rounded-md flex items-center w-full gap-2 text-white">
      <Link to={`/app/events/${props.id}`} className="w-full">
        <div className="flex items-center w-full gap-2">
          <div className="text-left w-60 shrink-0 grow">
            <div className="w-full h-full flex items-center justify-start pl-2">
              <img
                src={props.picture}
                alt={props.title}
                className="w-20 h-10 my-1 rounded-sm shrink-0"
                onError={() => {
                  setImageError(true);
                }}
              />
              <span className={"ml-2 text-ellipsis w-full !flex"}>
                {props.published ? (
                  <div className="w-4 mr-2" />
                ) : (
                  <LockClosedIcon
                    className="w-4 h-4 mr-2 mt-1 text-gray-500"
                    title="Cet événement n'est visible que par votre entreprise."
                  />
                )}
                <span className="shrink w-fit flex items-center">
                  {imageError && (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <AlertTriangle
                          className="w-4 h-4 mr-1 text-red-500"
                          onClick={(e) => e.preventDefault()}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          Une erreur s'est produite concernant l'image de cette
                          annonce. Veuillez la renseigner à nouveau.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  )}
                  {props.title}
                </span>
              </span>
            </div>
          </div>
          {props.showSelection ? (
            <div className="flex gap-2 overflow-x-auto">
              {query.isLoading ? <Spinner className="w-5 h-5" /> : null}
              {displayedIn.map((selection) => {
                const status = !!selection.schedules.find(
                  (s) => s.from < Date.now() && s.to > Date.now(),
                )
                  ? "en cours"
                  : !!selection.schedules.find((s) => s.from > Date.now())
                    ? "programmée"
                    : "passée";
                return (
                  <div
                    key={selection.id}
                    title={`Annonce ${status}`}
                    className={cn(
                      "px-[7px] py-1.5 rounded-sm justify-start items-center gap-2.5 flex",
                      {
                        "bg-green-500": status === "en cours",
                        "bg-blue-500": status === "programmée",
                        "bg-orange-500": status === "passée",
                      },
                    )}
                  >
                    <div className="text-white text-[10px] font-medium tracking-tight">
                      {cleanName(selection.account.name)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="flex gap-2">
                {props.categories.slice(0, 2).map((category) => (
                  <div
                    key={category.id}
                    className="px-[7px] py-1.5 bg-fuchsia-700 rounded-sm justify-start items-center gap-2.5 flex"
                  >
                    <div className="text-white text-[10px] font-medium tracking-tight">
                      {category.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-80 shrink-0 text-ellipsis">{props.place}</div>
            </>
          )}
        </div>
      </Link>
      <div className="w-20 shrink-0">
        <EventMenu {...props} selectionButton={!props.showSelection} />
      </div>
    </li>
  );
};

export default EventItem;
