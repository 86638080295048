import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams()
  React.useEffect(() => {
    navigate("/auth/login?redirect=" + query.get('redirect'), { replace: true });
  }, []);
  return null;
};

export default Auth;
