import useR from "@/hooks/useR";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

const OnboardingEntrypoint = (props: Props) => {
  const { user, company, apiAccounts } = useR();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!user?.firstname)
      return navigate("/onboarding/user", { replace: true });
    if (company?.name === user.email.replace(/\W+/gim, "_"))
      return navigate("/onboarding/company", { replace: true });
    if (!apiAccounts?.length)
      return navigate("/onboarding/widget", { replace: true });
    return navigate("/app", { replace: true });
  }, []);
  return null;
};

export default OnboardingEntrypoint;
