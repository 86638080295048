import { isPlanPremium } from "@/utils/isPlanPremium";
import { useQuery } from "@tanstack/react-query";

export type Pricing = {
  name: string;
  price: 12;
  description: string;
  subscriptionId?: string;
  features: {
    checked: boolean;
    label: string;
  }[];
  cta: {
    label: string;
    link: string;
    external: boolean;
  } | null;
};

export interface UsePricingProps {
  premium?: boolean;
}

export function usePricing(props: UsePricingProps = {}) {
  return useQuery<Pricing[]>({
    queryKey: ["pricing", JSON.stringify(props)],
    queryFn: async () => {
      const fileUrl = import.meta.env.BASE_URL + "pricing.json";
      const response = await fetch(fileUrl);
      const data = await response.json() as Pricing[];

      return data.filter((pricing) => {
        if (props.premium !== undefined && isPlanPremium(pricing) !== props.premium) {
          return false
        }
        return true;
      });
    }
  });
}
