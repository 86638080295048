import { useQuery } from "@tanstack/react-query";
import useR from "@/hooks/useR";
import { getAdvertisements, getConfig } from "@/service/konnectz.service";
import { useMemo } from "react";
import createThrottle from "@oriun/throttle";
import { Schedule } from "@/types/konnectz.types";

export default function useExcludedAds() {
  const { currentSelection } = useR();
  const excludedAds = useQuery({
    queryKey: ["excludedAds", currentSelection?.exclusionConfig],
    placeholderData: [],
    async queryFn({ queryKey: [, id] }) {
      if (typeof id !== "string" || !id) return [];
      const config = await getConfig(id as string);
      if (!config?.ConfigToAdvertisements?.length) return [];
      const ads = await getAdvertisements({
        id: config.ConfigToAdvertisements.map((i) => i.advertisement).filter(
          Boolean,
        ) as string[],
        include: ["configToAdvertisement", "pictures"],
      });
      return config.ConfigToAdvertisements.map((i) => {
        const ad = ads.find((a) => a.id === i.advertisement);
        return ad ? { ...i, Advertisement: ad } : null;
      }).filter(Boolean) as Schedule[];
    },
    enabled: !!currentSelection?.exclusionConfig,
  });
  return [
    useMemo(() => excludedAds.data ?? [], [excludedAds.data]),
    useMemo(
      () => createThrottle(() => excludedAds.refetch(), 1000),
      [excludedAds.refetch],
    ),
  ] as const;
}
