import {
  createExclusionConfig,
  getSelectionByAppSecret,
} from "@/service/manager.service";
import { Selection } from "@/types/manager.types";
import {
  TimenjoyApiAccount,
  TimenjoyCompany,
  TimenjoyUser,
} from "@/types/timenjoy.types";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { usePermission } from "./usePermissions";

export default function useR() {
  const client = useQueryClient();
  const user = client.getQueryData<TimenjoyUser>(["user"]);
  const company = client.getQueryData<TimenjoyCompany>(["company"]);
  const apiAccounts = client.getQueryData<string[]>(["apiAccounts"]) ?? [];
  const currentApiAccountID =
    useQuery<string>({
      queryKey: ["currentApiAccount"],
    }).data ?? apiAccounts[0];

  const currentApiAccount = client.getQueryData<TimenjoyApiAccount>([
    "apiAccount",
    currentApiAccountID,
  ]);
  const currentSelection = client.getQueryData<Selection>([
    "selection",
    currentApiAccountID,
  ]);
  const selections = useQueries({
    queries: apiAccounts.map((id) => {
      const account = client.getQueryData<TimenjoyApiAccount>([
        "apiAccount",
        id,
      ]);
      return {
        queryKey: ["selection", id],
        queryFn: async () => {
          if (!account) throw new Error("Account not found");
          const data = await getSelectionByAppSecret(account.app_secret);
          if (data.exclusionConfig) {
            return data;
          }
          await createExclusionConfig(data.id);
          return getSelectionByAppSecret(data.app_secret);
        },
        enabled: !!account,
      };
    }),
  });
  React.useEffect(() => {
    if (currentApiAccount && !currentApiAccountID) {
      client.setQueryData(["currentApiAccount"], currentApiAccount.id);
    }
  }, [client, currentApiAccountID, currentApiAccount]);
  const permissions = usePermission();
  return {
    user,
    company,
    apiAccounts,
    currentApiAccount,
    currentSelection,
    client,
    permissions,
    selections,
  };
}
