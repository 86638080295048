import React from "react";
import useR from "@/hooks/useR";
import Button from "@/components/button";
import { Link, useNavigate } from "react-router-dom";
import WidgetHeadline from "@/components/widget-headline";
import SnippetBlock from "@/components/snippet-block";
import { isCustomSelection } from "@/utils/isCustomSelection";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { currentApiAccount, currentSelection } = useR();

  const code = `<tnj-widget data-selection="${currentSelection?.Config?.selection
    }" data-app_secret="${decodeURIComponent(
      currentSelection?.Config?.app_secret || ""
    )}"></tnj-widget>`;

  const isEmptyCustomSelection =
    isCustomSelection(currentSelection) &&
    !currentSelection?.Config?.ConfigToAdvertisements.length;

  return (
    <div className="w-full h-full text-subtitle">
      <div className="w-full max-h-full overflow-scroll noscrollbar flex flex-col pb-4 px-16">
        <div className="flex justify-between">
          <WidgetHeadline />
          <Button
            children="Modifier"
            onClick={() => navigate("/app/widgets/" + currentApiAccount?.id)}
          />
        </div>
        <div className="mt-8">
          <h1 className="text-2xl font-semibold text-title select-none">
            Dashboard
          </h1>
        </div>
        <div className="mt-8">
          <h2 className="text-md font-semibold select-none">Code</h2>
          <SnippetBlock code={code} className="w-1/2 min-w-[400px]" />
        </div>
        <div className="mt-8">
          <h2 className="text-md font-semibold select-none">Mon contenu</h2>
          {!isEmptyCustomSelection && (
            <tnj-widget
              className="w-full -my-6"
              data-app_secret={decodeURIComponent(
                currentSelection?.Config?.app_secret || ""
              )}
              key={currentSelection?.config}
              data-selection={currentSelection?.Config?.selection}
              data-type="carousel"
              data-watermark="false"
              data-version="4.0.0"
              data-redirect="true"
              data-lines="100"
              data-styles={JSON.stringify({
                card: {
                  global: {
                    background: "#161b22",
                    border: "1px solid #1f2937",
                  },
                  firstLine: {
                    color: "#fff",
                    fontFamily: "Manrope",
                  },
                  secondLine: {
                    color: "#fffd",
                    fontFamily: "Manrope",
                  },
                  thirdLine: {
                    color: "#fffd",
                    fontFamily: "Manrope",
                  },
                },
                arrows: {
                  backgroundColor: "#161b22",
                },
              })}
            />
          )}
          {isEmptyCustomSelection && (
            <p className="mt-4">
              Votre widget est vide. Vous pouvez{" "}
              <Link
                to="/app/selection"
                className="text-emphasis underline underline-offset-4"
              >
                ajouter du contenu
              </Link>{" "}
              depuis l'onglet "Ma sélection"
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
