import { CompositionType } from "@/types/konnectz.types";
import { Selection } from "@/types/manager.types";

export function isCustomSelection(selection: Selection | undefined) {
  if (!selection) return false;
  const compositions = selection.Config?.origin_config;
  if (!compositions?.length) return true;
  const exceptComposition = compositions.filter(
    (composition) =>
      composition.type === CompositionType.EXCEPT &&
      composition.origin === selection.config &&
      composition.target === selection.config
  );
  return exceptComposition.length === 0;
}
