import React from "react";
import { WidgetContent } from "@/types/konnectz.types";
import { EyeSlashIcon } from "@heroicons/react/24/solid";
import { cn } from "@/lib/utils";

interface IListItemProps {
  item: WidgetContent["advertisements"][0];
  excluded: boolean;
  onClick?: () => void;
  size?: "small" | "large";
  actions?: React.ReactNode;
}

const ExclusionItem: React.FunctionComponent<IListItemProps> = ({
  item,
  excluded,
  onClick,
  size = "large",
  actions,
}) => {
  return (
    <div
      className="hover:bg-secondary flex shrink-0 w-full cursor-pointer select-none items-center gap-2 rounded-md"
      onClick={onClick}
    >
      <div className="relative my-1 ml-1 h-fit w-fit shrink-0 overflow-hidden rounded-sm">
        <img
          src={`https://konnectz.timenjoy.fr/pictures/${item.picture}.webp`}
          alt={item.firstLine}
          className={cn(
            "aspect-[2] shrink-0 rounded-sm object-cover",
            size === "small" ? "h-10 w-20" : "h-14 w-28",
          )}
        />
        {excluded && (
          <div className="absolute inset-0 flex items-center justify-center bg-slate-500/50 text-white">
            <EyeSlashIcon className="h-4 w-4" />
          </div>
        )}
      </div>
      <span
        className={cn(
          "ml-6 w-full text-ellipsis text-white",
          size === "small" ? "text-regular" : "font-medium",
          excluded && "opacity-50",
        )}
      >
        {item.firstLine}
      </span>
      <div>{actions}</div>
    </div>
  );
};

export default ExclusionItem;
