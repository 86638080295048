import Button from "@/components/button";
import DeleteWidgetPopup from "@/components/delete-widget-popup";
import useR from "@/hooks/useR";
import { deleteApiAccount } from "@/service/timenjoy.service";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import { cleanName } from "@/utils/name";
import { useToast } from "@chakra-ui/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@tanstack/react-query";
import { Badge } from "@tremor/react";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

const WidgetList = (props: Props) => {
  const [deletePopupId, setDeletePopupId] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { apiAccounts, client } = useR();
  const list = React.useMemo(
    () =>
      apiAccounts
        .map((item) => {
          return client.getQueryData<TimenjoyApiAccount>(["apiAccount", item]);
        })
        .filter(Boolean)
        .sort((a, b) => {
          return a!.createdAt > b!.createdAt ? -1 : 1;
        }) as TimenjoyApiAccount[],
    [apiAccounts, client]
  );

  function changeCurrent(id: string) {
    return () => {
      client.setQueryData(["currentApiAccount"], id);
      navigate("/app/dashboard");
    };
  }
  return (
    <div className="w-full min-h-full flex flex-col pb-12 px-16">
      <h1 className="text-2xl font-semibold text-title">Mes Widgets</h1>
      <div className="mt-8 shadow-md grow rounded-lg rounded-t-xl  border border-background_light overflow-x-auto">
        <table className="w-full table-auto text-sm text-left">
          <thead className="text-subtitle font-medium bg-background_light">
            <tr>
              <th className="py-3 px-6  rounded-tl-lg">#</th>
              <th className="py-3 px-6">Nom</th>
              <th className="py-3 px-6">Site connecté</th>
              <th className="py-3 px-6">Statut</th>
              <th className="py-3 px-6  rounded-tr-lg"></th>
            </tr>
          </thead>
          <tbody className="text-subtitle divide-y">
            {list.map((item, idx) => (
              <tr key={idx}>
                <td className="px-6 py-3 whitespace-nowrap border-b border-background_light">
                  {idx + 1}
                </td>
                <td className="px-6 py-3 whitespace-nowrap border-b border-background_light">
                  {cleanName(item.name)}
                </td>
                <td className="px-6 py-3 whitespace-nowrap border-b border-background_light">
                  {item.white_list?.[0]?.url}
                </td>
                <td className="px-6 py-3 whitespace-nowrap border-b border-background_light">
                  <Badge>{item.isFreemium ? "Freemium" : "Premium"}</Badge>
                </td>
                <td className="px-6 py-3 whitespace-nowrap border border-x-transparent border-background_light">
                  <div className="flex items-center">
                    <Button
                      children="Sélectionner"
                      className="!font-semibold !py-2 !px-3 h-9"
                      onClick={changeCurrent(item.id)}
                    />
                    <Button
                      children={<TrashIcon className="text-white w-4 h-4" />}
                      className="ml-2 !font-semibold !py-2 !px-3 h-9 bg-red-500 hover:bg-red-600"
                      onClick={() => setDeletePopupId(item.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DeleteWidgetPopup
        id={deletePopupId!}
        open={!!deletePopupId}
        setOpen={() => setDeletePopupId(null)}
      />
    </div>
  );
};

export default WidgetList;
