import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

type StepsProps = {
  stepsItems: string[];
  currentStep: number;
  setCurrentStep?: (step: number) => void;
  className?: string;
};

const Steps: React.FC<StepsProps> = ({
  stepsItems,
  currentStep,
  setCurrentStep,
  className,
}) => {
  return (
    // <div
    //   className={twMerge("max-w-2xl mx-auto sm:px-4 md:px-0 w-full", className)}
    // >
      <ul
        aria-label="Steps"
        className={twMerge("max-w-2xl mx-auto sm:px-4 md:px-0 w-full items-center text-subtitle font-medium md:flex", className)}
      >
        {stepsItems.map((item, idx) => (
          <li
            key={idx}
            aria-current={currentStep == idx + 1 ? "step" : false}
            className={clsx(
              "flex-1 last:flex-none flex md:items-center",
              currentStep != idx + 1 && "hidden sm:flex",
              setCurrentStep && "cursor-pointer"
            )}
            onClick={() => {
              setCurrentStep?.(idx);
            }}
          >
            <div className="flex gap-x-3">
              <div className="flex items-center flex-col gap-x-2">
                <div
                  className={clsx(
                    `w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${
                      currentStep > idx + 1
                        ? "bg-emphasis border-emphasis"
                        : "" || currentStep == idx + 1
                        ? "border-emphasis"
                        : ""
                    }`,
                    setCurrentStep && "cursor-pointer"
                  )}
                >
                  <span
                    className={` ${
                      currentStep > idx + 1
                        ? "hidden"
                        : "" || currentStep == idx + 1
                        ? "text-emphasis"
                        : ""
                    }`}
                  >
                    {idx + 1}
                  </span>
                  {currentStep > idx + 1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`h-12 flex items-center md:hidden ${
                    idx + 1 == stepsItems.length ? "hidden" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-subtitle"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>
              <div className="h-8 flex items-center md:h-auto">
                <h3
                  className={clsx(
                    `text-sm`,
                    setCurrentStep && "cursor-pointer",
                    currentStep == idx + 1 && "text-emphasis"
                  )}
                >
                  {item}
                </h3>
              </div>
            </div>
            <div
              className={`flex-1 hidden md:block ${
                idx + 1 == stepsItems.length ? "md:hidden" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mx-auto text-subtitle"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </li>
        ))}
      </ul>
    // </div>
  );
};

export default Steps;
