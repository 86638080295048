import * as React from "react";
import Popover from "../popover";
import TextInput from "../inputs/text";

interface ICityFilterProps {
  children: React.ReactElement;
  value: string;
  onChange: (value: string) => void;
}

const CityFilter: React.FunctionComponent<ICityFilterProps> = ({
  children,
  value,
  onChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const [reference, setReference] = React.useState<Element | null | undefined>(
    null
  );
  const Element = React.useCallback(
    () =>
      React.cloneElement(children, {
        ref: setReference,
        onClick: () => setOpen((i) => !i),
      }),
    [children]
  );
  return (
    <>
      <Element />
      <Popover
        placement="bottom"
        isOpen={open}
        setIsOpen={setOpen}
        reference={reference}
        arrowClassName="fill-konnectz_background_light"
      >
        <div>
          <div
            className="bg-background_light p-2 rounded-md"
            onClick={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
          >
            <TextInput
              placeholder="Saisir une ville"
              className=" w-72"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CityFilter;
