import Steps from "@/components/steps";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const stepsItems = ["Mes infos", "Mon entreprise", "Abonnement", "Mon widget"];
const stepUrls = [
  "/onboarding/user",
  "/onboarding/company",
  "/onboarding/pricing",
  "/onboarding/widget",
  "/onboarding/finish",
];

const OnboardingLayout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!location.pathname.startsWith("/onboarding")) {
      navigate("/onboarding");
    }
  }, [location.pathname]);

  const isOnboarding = location.pathname.startsWith("/onboarding");

  if (!isOnboarding) {
    return null;
  }

  const currentStep =
    stepUrls.findIndex((url) => url === location.pathname) + 1;

  function changeStep(step: number) {
    if (step >= currentStep - 1) return;
    navigate(stepUrls[step]);
  }

  return (
    <section className="h-full max-h-screen max-w-screen-2xl flex flex-col relative">
      <img
        src={import.meta.env.BASE_URL + "img/logo.png"}
        width={120}
        className="fixed top-4 left-6 bg-konnectz_background"
      />
      <Steps
        stepsItems={stepsItems}
        currentStep={currentStep}
        setCurrentStep={changeStep}
        className="mt-16 sm:mt-0"
      />
      <div className="h-full mb-8 sm:mt-6 pt-6 flex flex-col overflow-hidden">
        <div className="max-w-3xl px-4 w-full m-auto flex flex-col overflow-y-auto noscrollbar">
          {props.children}
          <div className="h-4 w-1" />
        </div>
      </div>
      <span className="bottom-0 right-0 absolute p-4 text-subtitle text-right">
        &#169; Konnectz 2023
      </span>
    </section>
  );
};

export default OnboardingLayout;
