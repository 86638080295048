import useAuth from "@/hooks/useAuth";
import {
  PublicAuthUser,
  listCompanyInvites,
  listCompanyUser,
} from "@/service/auth.service";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import TextInput from "@/components/inputs/text";
import { getUser } from "@/service/timenjoy.service";
import useR from "@/hooks/useR";
import { TimenjoyUser } from "@/types/timenjoy.types";
import useInput from "@/hooks/useInput";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "@chakra-ui/react";
import UserItem from "@/components/list-item/user-item";
import Button from "@/components/button";
import InviteItem from "@/components/list-item/invite-item";
import InviteUserModal from "@/modals/invite-user";

const CompanyUsersPage: React.FC = () => {
  const auth = useAuth();
  const users = useQuery({
    queryKey: ["companyUsers", auth?.company?.id],
    queryFn: async () => {
      const id = auth?.company?.id;

      if (!id) return Promise.reject();

      const companyUsers = await listCompanyUser(id);

      const users = await Promise.all(
        companyUsers.data.map(async (user) => {
          if (!user.legacyId) return null;
          const legacyUser = await getUser(user.legacyId).catch(() => null);
          if (!legacyUser) return null;
          return {
            user,
            legacyUser,
          };
        }),
      );

      return users.filter(Boolean) as {
        user: PublicAuthUser;
        legacyUser: TimenjoyUser;
      }[];
    },
    enabled: !!auth?.company?.id,
  });
  const invites = useQuery({
    queryKey: ["companyInvites", auth?.company?.id],
    queryFn: async () => {
      const id = auth?.company?.id;
      if (!id) return Promise.reject();
      return listCompanyInvites(id);
    },
    enabled: !!auth?.company?.id,
  });
  const searchInput = useInput("");
  const canInviteUser = auth?.policy?.canInviteUsers === true;
  const usersData = users.data?.filter(
    (item) =>
      item.legacyUser.firstname
        ?.toLowerCase()
        .includes(searchInput.value.toLowerCase()) ||
      item.legacyUser.lastname
        ?.toLowerCase()
        .includes(searchInput.value.toLowerCase()) ||
      item.legacyUser.email
        ?.toLowerCase()
        .includes(searchInput.value.toLowerCase()),
  );
  const invitesData = invites.data?.data.filter((item) =>
    item.email?.toLowerCase().includes(searchInput.value.toLowerCase()),
  );
  const [invitePopupOpen, setInvitePopupOpen] = React.useState(false);

  return (
    <div className="relative flex h-full flex-col items-start justify-start gap-6 overflow-hidden px-16 pb-4">
      <InviteUserModal open={invitePopupOpen} setOpen={setInvitePopupOpen} />
      <div className="flex w-full items-center justify-between">
        <div className="text-xl font-semibold tracking-tight text-white">
          Utilisateurs
        </div>
        {canInviteUser && (
          <Button onClick={() => setInvitePopupOpen(true)}>Inviter</Button>
        )}
      </div>
      <div className="inline-flex items-center justify-start gap-16">
        <TextInput
          placeholder="Rechercher"
          className=" w-72"
          {...searchInput}
        />
      </div>
      {searchInput.value && (
        <div className="noscrollbar -mb-6 flex w-full items-start gap-2 overflow-x-auto overflow-y-hidden pb-6">
          {searchInput.value && (
            <span className="bg-konnectz_accent flex shrink-0 cursor-default items-center rounded-full py-1.5 pl-1.5 pr-3 text-sm font-semibold tracking-tight text-white">
              <XCircleIcon
                className="mr-1 inline-block h-5 w-5 cursor-pointer"
                onClick={() => searchInput.onChange("")}
              />
              {searchInput.value}
            </span>
          )}
        </div>
      )}
      <div className="flex h-5 items-center text-right text-[15px] font-semibold tracking-tight text-white">
        {users.isLoading || invites.isLoading ? (
          <Spinner className="h-5 w-5" />
        ) : (
          (usersData?.length ?? 0) + (invitesData?.length ?? 0)
        )}{" "}
        Utilisateur{users.data?.length !== 1 && "s"}
      </div>
      <div className="noscrollbar -mx-16 flex h-full shrink flex-col items-start justify-start gap-1 self-stretch overflow-y-auto px-16">
        {invitesData?.map((item) => {
          return <InviteItem {...item} key={item.id} />;
        })}
        {usersData?.map((item) => {
          return <UserItem {...item} key={item.user.id} />;
        })}
      </div>
    </div>
  );
};

export default CompanyUsersPage;
