import Button from "@/components/button";
import TextArea from "@/components/inputs/area";
import TextInput from "@/components/inputs/text";
import {
  ADVERTISEMENT_TYPE,
  BuildingEvent,
  contentEventSchema,
  useBuildingEvent,
} from "@/hooks/useBuildingEvent";
import { useTextSize } from "@/hooks/useTextSize";
import useValidation from "@/hooks/useValidation";
import { EventBuilderStepsEnum } from "@/layout/event-builder";
import ImageCropper from "@/modals/image-cropper";
import { blobToBase64 } from "@/utils/blob-to-base64";
import { useToast } from "@chakra-ui/react";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AlertTriangleIcon } from "lucide-react";

interface IBaseEventBuilderProps {}

const WIDGET_CARD_TITLE_MINIMUM_WIDTH = 226; //px, 250 width - 12*2 padding

const wording = {
  [ADVERTISEMENT_TYPE.EVENT]: {
    title: "Nom de l'événement",
    description: "Description de l'événement",
  },
  [ADVERTISEMENT_TYPE.IMMO]: {
    title: "Titre de l'annonce",
    surface: "Surface",
    price: "Prix",
    rooms: "Nombre de pièces",
  },
  [ADVERTISEMENT_TYPE.EMPLOI]: {
    title: "Titre de l'offre",
    entreprise: "Nom de la société",
  },
} as const;

const BaseEventBuilder: React.FunctionComponent<IBaseEventBuilderProps> = (
  props,
) => {
  const event = useBuildingEvent();
  const titleSize = useTextSize({ content: event.title });
  const [isValid, validate] = useValidation(contentEventSchema);
  const navigate = useNavigate();
  const [s] = useSearchParams();
  const [cropperOpen, setCropperOpen] = React.useState(false);
  const toast = useToast();
  const dropzone = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".webp", ".png"],
    },
    validator(file) {
      if (file.size > 1 * 1024 ** 2) {
        return {
          code: "file-too-big",
          message: "Le fichier est trop gros. Il ne doit pas dépasser 1Mo",
        };
      }
      return null;
    },
    onDropRejected(fileRejections) {
      fileRejections.forEach((fileRejection) => {
        toast({
          title: "Erreur",
          description: fileRejection.errors[0].message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    },
  });
  React.useEffect(() => {
    validate(event, true);
  }, [event]);
  React.useEffect(() => {
    const droppedFile = dropzone.acceptedFiles[0];
    if (!droppedFile) return;
    let cancelled = false;
    blobToBase64(droppedFile).then((base64) => {
      if (cancelled) return;
      event.update((d) => {
        const data = { ...d };
        data.raw_picture = data.picture = base64;
        return data;
      });
    });
    return () => {
      cancelled = true;
    };
  }, [dropzone.acceptedFiles]);
  function updateEvent(property: keyof BuildingEvent) {
    return function (value: BuildingEvent[typeof property]) {
      event.update((data) => ({
        ...data,
        [property]: value,
      }));
    };
  }
  function goNext() {
    if (!validate(event)) return;
    switch (event.type) {
      case ADVERTISEMENT_TYPE.IMMO:
      case ADVERTISEMENT_TYPE.EMPLOI:
        navigate(
          "../" +
            EventBuilderStepsEnum.infos2 +
            `?lastStep=${s.get("lastStep")}`,
        );
        break;
      case ADVERTISEMENT_TYPE.EVENT:
      default:
        navigate(
          "../" +
            EventBuilderStepsEnum.location +
            `?lastStep=${s.get("lastStep")}`,
        );
        break;
    }
  }
  return (
    <div className="w-full h-full max-w-xl flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <h1>{wording[event.type ?? ADVERTISEMENT_TYPE.EVENT].title}</h1>
        <div className="relative w-full h-fit flex gap-3 items-center">
          <TextInput
            containerClassName="w-full"
            value={event.title}
            onChange={(e) => updateEvent("title")(e.target.value)}
            placeholder="Nice Jazz Festival"
            showLength
          />
          {titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="w-min flex justify-center items-center aspect-square">
                  <AlertTriangleIcon className="text-orange-400 aspect-square" />
                </div>
              </TooltipTrigger>
              <TooltipContent side="bottom" align="end" sideOffset={16}>
                <p>
                  En raison de sa longueur, ce titre peut être tronqué sur
                  certains écrans.
                </p>
              </TooltipContent>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h1>Image de couverture</h1>
        {!event.raw_picture && (
          <div
            {...dropzone.getRootProps({ className: "dropzone cursor-pointer" })}
          >
            <input {...dropzone.getInputProps()} />
            <p>Déposez une image ou cliquez pour en sélectionner une</p>
          </div>
        )}
        {event.raw_picture && (
          <div className="flex gap-2">
            <Button onClick={() => dropzone.open()}>
              Choisir une autre image
            </Button>
            <Button onClick={() => setCropperOpen(true)}>
              Ajuster l'image
            </Button>
            <ImageCropper
              open={cropperOpen}
              setOpen={setCropperOpen}
              imageUrl={event.raw_picture}
              currentImageUrl={event.picture ?? event.raw_picture}
              onUpdate={updateEvent("picture")}
            />
          </div>
        )}
        {/* From docs, update the styles later https://react-dropzone.js.org/#section-examples */}
        <style
          dangerouslySetInnerHTML={{
            __html: `
                .dropzone {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px;
                    border-width: 2px;
                    border-radius: 2px;
                    border-color: #eeeeee;
                    border-style: dashed;
                    // background-color: #fafafa;
                    color: #bdbdbd;
                    outline: none;
                    transition: border .24s ease-in-out;
                }`,
          }}
        />
      </div>
      {(!event.type || event.type === ADVERTISEMENT_TYPE.EVENT) && (
        <div className="flex flex-col gap-2">
          <h1>{wording[event.type ?? ADVERTISEMENT_TYPE.EVENT].description}</h1>
          <TextArea
            className="min-h-[200px]"
            value={event.description}
            onChange={(e) => updateEvent("description")(e.target.value)}
            placeholder="Le meilleur festival de Jazz à Nice !"
          />
        </div>
      )}
      {event.type === ADVERTISEMENT_TYPE.EMPLOI && (
        <div className="flex flex-col gap-2">
          <h1>{wording[event.type].entreprise}</h1>
          <TextInput
            value={event.entreprise}
            onChange={(e) => updateEvent("entreprise")(e.target.value)}
            placeholder="Konnectz"
            maxLength={30}
            required
          />
        </div>
      )}
      {event.type === ADVERTISEMENT_TYPE.IMMO && (
        <div className="flex flex-col gap-2">
          <h1>{wording[event.type].surface}</h1>
          <TextInput
            value={event.surface}
            onChange={(e) => updateEvent("surface")(e.target.value)}
            placeholder="120m2"
          />
          <h1>{wording[event.type].rooms}</h1>
          <TextInput
            value={event.rooms}
            onChange={(e) => updateEvent("rooms")(e.target.value)}
            placeholder="3 pièces"
          />
          <h1>{wording[event.type].price}</h1>
          <TextInput
            value={event.price}
            onChange={(e) => updateEvent("price")(e.target.value)}
            placeholder="1 500 000 €"
          />
        </div>
      )}
      <div className="h-full shrink grow w-full" />
      <Button
        className="w-fit py-3 px-5 mt-8 self-end"
        onClick={goNext}
        disabled={!isValid}
        clickThroughDisabled
      >
        Suivant
      </Button>
    </div>
  );
};

export default BaseEventBuilder;
