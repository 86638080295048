import React from "react";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const SupportById = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!id) {
      return navigate("/app/support");
    }
    // if id is in faq, compute name then navigate to corresponding faq page
    // return navigate(`/app/support/${id}/${name}`)
  }, []);
  return null;
};

export default SupportById;
