import * as React from "react";
import CheckPicker from "rsuite/CheckPicker";

interface ICategoryFilterProps {
  value: string[];
  onChange: (value: string[]) => void;
  data: string[];
  children?: React.ReactElement;
  className?: string;
  searchable?: boolean;
  disabled?: boolean;
}

const CategoryFilter: React.FunctionComponent<ICategoryFilterProps> = ({
  value,
  onChange,
  data,
  children,
  className,
  searchable = true,
  disabled = false,
}) => {
  const Element = children
    ? React.useCallback(() => children, [children])
    : undefined;
  return (
    <CheckPicker
      disabled={disabled}
      value={value}
      onChange={onChange}
      data={data.map((a) => ({ label: a, value: a }))}
      toggleAs={Element}
      cleanable
      countable
      placement="autoVertical"
      menuClassName="check-picker-menu-custom-class"
      className={className}
      searchable={searchable}
    />
  );
};

export default CategoryFilter;
