import React from "react";
import Button from "@/components/button";
import HTML from "@/components/html";
import clsx from "clsx";

type Props = {
  code: string;
  className?: string;
};

const SnippetBlock = ({ code, className }: Props) => {
  const [copied, setCopied] = React.useState(false);
  React.useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [copied]);

  const script = `<script src="https://widget.timenjoy.fr/main.js" async> </script>`;

  return (
    <div
      className={clsx(
        "mt-4 rounded-xl p-4 pt-4 bg-neutral-800/50 text-white text-sm relative",
        className
      )}
    >
      <HTML content={code} />
      <br />
      <HTML content={script} />
      <div className="absolute top-0 rounded-xl right-1 pt-1 pb-10 pl-10 max-h-full">
        <Button
          className="!px-3 !py-2 w-20 shadow-2xl shadow-black"
          onClick={() => {
            navigator.clipboard.writeText(code + "\n" + script);
            setCopied(true);
          }}
          children={copied ? "👍" : "Copier"}
        />
      </div>
    </div>
  );
};

export default SnippetBlock;
