import Button from "@/components/button";
import EmailInput from "@/components/inputs/email";
import PasswordInput from "@/components/inputs/password";
import Spinner from "@/components/spinner";
import useInput from "@/hooks/useInput";
import useValidation from "@/hooks/useValidation";
import { login } from "@/service/auth.service";
import {
  LoginEmailNotFound,
  LoginInvalidPassword,
} from "@/service/timenjoy.service";
import { clearQueryClient } from "@/utils/clear-query-client";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

const LoginPage: React.FC = () => {
  const toast = useToast();
  const form = useRef<HTMLFormElement>(null);
  const email = useInput(
    sessionStorage.getItem('email') ?? ''
  );
  const password = useInput();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [payloadValid, validate] = useValidation(z.object({
    email: z.string().email(),
    password: z.string(),
  }))
  React.useEffect(() => {
    validate({ email: email.value, password: password.value }, true)
  }, [email, password])
  const loginMutation = useMutation({
    async mutationFn() {
      // if (!token) throw new Error("Une erreur est survenue");
      const payload = { email: email.value, password: password.value };
      sessionStorage.setItem('email', payload.email)
      return login(payload);
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast.closeAll();
      if (error instanceof LoginInvalidPassword) {
        return toast({
          title: "Mot de passe incorrect",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      } else if (error instanceof LoginEmailNotFound) {
        return toast({
          title: "Aucun utilisateur avec cet email n'existe",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      }
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
    onSuccess(data) {
      const { user, company, apiAccounts, auth } = data;
      clearQueryClient(queryClient, { keepBrowserToken: false });
      queryClient.setQueryData(["user"], user);
      queryClient.setQueryData(["company"], company);
      queryClient.setQueryData(["userID"], user.id);
      queryClient.setQueryData(
        ["apiAccounts"],
        apiAccounts?.map((a) => a.id) ?? []
      );
      apiAccounts?.forEach((a) => {
        queryClient.setQueryData(["apiAccount", a.id], a);
      });
      queryClient.setQueryData(["auth"], auth);
      navigate("/app", { replace: true });
    },
    retry(count, error) {
      captureException(error, { extra: { count } });
      if (count) return false;
      if (error instanceof LoginInvalidPassword) return false;
      if (error instanceof LoginEmailNotFound) return false;
      return true;
    }
  });

  function submit(e?: React.FormEvent) {
    e?.preventDefault();
    if (!validate({ email: email.value, password: password.value })) return;
    if (loginMutation.isLoading) return;
    loginMutation.mutate();
  }
  return (
    <main className="w-full h-full flex flex-col items-center justify-center pb-10 sm:pb-16">
      <div className="max-w-sm w-full text-subtitle">
        <div className="text-center">
          <img
            src={import.meta.env.BASE_URL + "img/logo.png"}
            width={200}
            className="mx-auto"
          />
          <div className="mt-5 space-y-2">
            <h3 className="text-title text-2xl font-bold sm:text-3xl">
              Connexion
            </h3>
            <p className="">
              Pas encore de compte ?{" "}
              <Link
                to="/auth/register"
                className="font-medium text-emphasis hover:text-emphasis_light"
              >
                Je m'inscris
              </Link>
            </p>
          </div>
        </div>
        <form ref={form} onSubmit={submit} className="mt-8 space-y-5">
          <div>
            <label className="font-medium">Email</label>
            <EmailInput className="w-full mt-2" {...email} />
          </div>
          <div>
            <label className="font-medium">Mot de passe</label>
            <PasswordInput className="w-full mt-2" {...password} />
            <Link to="/auth/forgot" className="text-emphasis mt-2 block text-right">
              Mot de passe oublié ?
            </Link>
          </div>
          <input type="submit" className="hidden" />
        </form>
        <Button
          className="w-full grid grid-cols-3 text-base py-3 !mt-8 gap-3"
          disabled={!payloadValid || loginMutation.isLoading}
          clickThroughDisabled
          onClick={() => submit()}
        >
          <div />
          Se connecter{" "}
          {loginMutation.isLoading ? (
            <Spinner className="w-5 h-5 fill-emphasis" />
          ) : (
            <div />
          )}
        </Button>
      </div>
    </main>
  );
};

export default LoginPage;
