import Button from "@/components/button";
import TextInput from "@/components/inputs/text";
import {
  BuildingEvent,
  locationEventSchema,
  useBuildingEvent,
} from "@/hooks/useBuildingEvent";
import useValidation from "@/hooks/useValidation";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import DateTimeInput from "@/components/inputs/datetime";
import { EventBuilderStepsEnum } from "@/layout/event-builder";
import { useToast } from "@chakra-ui/react";

interface ILocationEventBuilderProps {}

const LocationEventBuilder: React.FunctionComponent<
  ILocationEventBuilderProps
> = (props) => {
  const event = useBuildingEvent();
  const toast = useToast();
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    language: "fr",
    // libraries: ["places", "maps", "core"],
    options: {
      types: ["geocode", "establishment"],
      fields: ["formatted_address", "geometry.location", "name"],
    },
    onPlaceSelected: (place) => {
      console.log(place);
      if (!place.geometry?.location?.lat?.()) {
        toast({
          title: "Adresse invalide",
          description: "Veuillez sélectionner une autre adresse",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      event.update((data) => ({
        ...data,
        place: data.place ?? place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }));
    },
  });
  const [isValid, validate] = useValidation(locationEventSchema);
  const [s] = useSearchParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    validate(event, true);
  }, [event]);
  function updateEvent(property: keyof BuildingEvent) {
    return function (value: BuildingEvent[typeof property]) {
      event.update((data) => ({
        ...data,
        [property]: value,
      }));
    };
  }
  function updateStartDate(dateString: string) {
    if (event.datefin === event.date) {
      event.update((data) => ({
        ...data,
        date: dateString,
        datefin: dateString,
      }));
    } else {
      event.update((data) => ({
        ...data,
        date: dateString,
      }));
    }
  }
  function goNext() {
    if (validate(event))
      navigate(
        "../" + EventBuilderStepsEnum.infos + `?lastStep=${s.get("lastStep")}`,
      );
  }
  return (
    <div className="flex h-full w-full max-w-xl flex-col gap-6">
      <div className="flex flex-col gap-2">
        <h1>Début de l'événement</h1>
        <DateTimeInput value={event.date} onChange={updateStartDate} />
      </div>
      <div className="flex flex-col gap-2">
        <h1>Fin de l'événement</h1>
        <DateTimeInput
          value={event.datefin}
          onChange={updateEvent("datefin")}
        />
      </div>
      <div className="flex flex-col gap-2">
        <h1>Adresse de l'événement</h1>
        <TextInput
          ref={ref}
          defaultValue={event.address}
          // value={event.title}
          onChange={(e) => updateEvent("address")(e.target.value)}
          placeholder="Nice Jazz Festival"
        />
      </div>
      <div className="flex flex-col gap-2">
        <h1>Lieu de l'événement</h1>
        <TextInput
          value={event.place}
          onChange={(e) => updateEvent("place")(e.target.value)}
          placeholder="Stade Allianz Riviera"
        />
      </div>
      <div className="h-full w-full shrink grow" />
      <Button
        className="mt-8 w-fit self-end px-5 py-3"
        onClick={goNext}
        disabled={!isValid}
        clickThroughDisabled
      >
        Suivant
      </Button>
    </div>
  );
};

export default LocationEventBuilder;
