import React from "react";

type Props = {
  value?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const WebsiteInput: React.FC<Props> = ({ value = "", onChange }) => {
  const splitted = value.split("://");
  const displayedValue = splitted.at(-1) || "";
  const prefix = splitted.at(-2) || "https";
  React.useEffect(() => {
    const cleanedValue = prefix + "://" + displayedValue;
    if (cleanedValue !== value) {
      onChange({
        target: { value: cleanedValue },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [value]);

  return (
    <div className="w-full flex items-center text-title rounded-md shadow-sm bg-neutral-500/50 outline-none border border-konnectz_background focus:border-indigo-600">
      <div className="px-3 py-3 rounded-l-md bg-neutral-500/50 text-subtitle border-r border-r-neutral-500/5">
        {prefix}://
      </div>
      <input
        type="text"
        placeholder="www.example.com"
        id="website-url"
        value={displayedValue}
        onChange={({ target: { value: newValue } }) =>
          onChange({
            target: { value: prefix + "://" + newValue },
          } as React.ChangeEvent<HTMLInputElement>)
        }
        className="w-full p-3 bg-transparent outline-none text-title"
      />
    </div>
  );
};

export default WebsiteInput;
