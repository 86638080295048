export function parseCData(data?: string) {
  if (!data) return undefined;
  const untagged = data.replace(/^<!\[CDATA\[/, "").replace(/\]\]>$/, "");
  return JSON.parse(untagged) as Record<string, any>;
}
export function stringifyCData(data: Record<string, any>) {
  if (!data) return data;
  return `<![CDATA[${JSON.stringify(data)}]]>`;
}

export function extractCData(content: string) {
  if (!content) return;
  const start = content.indexOf("<![CDATA[");
  const end = content.indexOf("]]>");
  const data = content.substring(start, end === -1 ? -1 : end + 3);
  return data;
}

export function removeCData(content: string) {
  const data = extractCData(content);
  if (!data) return content;
  return content.replace(data, "");
}

export function addCData(content: string, data: string | Record<string, any>) {
  if (!data) return content;
  const cleaned = removeCData(content);
  const stringified = typeof data === "string" ? data : stringifyCData(data);
  if (cleaned?.startsWith("<p>"))
    return cleaned.replace("<p>", `<p>${stringified}`);
  return stringified + (cleaned || "");
}
