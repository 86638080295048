import { captureException } from "@/service/exceptions";
import { useQuery } from "@tanstack/react-query";
import useR from "./useR";
import { getSelectionSummary } from "@/service/analytics.service";

export default function useWidgetAnalytics(period: string) {
  const { currentSelection } = useR();
  const query = useQuery({
    queryKey: ["basic-stats", currentSelection, period],
    async queryFn() {
      if (!currentSelection) throw new Error("No selection");
      return getSelectionSummary(currentSelection.config, period);
    },
    enabled: !!currentSelection,
    onError(err) {
      captureException(err);
      console.error(err);
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: 3,
  });
  return query;
}
