import { TextInput } from "@tremor/react";
import Spinner from "@/components/spinner";
import { useDebounceEffect } from "@/hooks/useDebounceEffect";
import useInput from "@/hooks/useInput";
import useR from "@/hooks/useR";
import { getEvents, searchEvents } from "@/service/timenjoy.service";
import {
  TimenjoyListResponse,
  TimenjoySearchEvent,
} from "@/types/timenjoy.types";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useInfiniteQuery } from "@tanstack/react-query";
import * as React from "react";
import EventItem from "@/components/list-item/event-item";
import { IUseCategoriesParams, useCategories } from "@/hooks/useCategories";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@tremor/react";
import { useSearchParams } from "react-router-dom";
import { useMainWorkPlaceSize } from "@/hooks/useMainWorkPlaceSize";
import { Switch } from "@tremor/react";
import { usePermission } from "@/hooks/usePermissions";

interface IEventsListProps {}

const PER_PAGE = 50;

const EventsList: React.FunctionComponent<IEventsListProps> = (props) => {
  const [query, setQuery] = useSearchParams();
  const { eventsEnabled, immoEnabled, jobsEnabled } = usePermission();
  const size = useMainWorkPlaceSize();
  const tabs = React.useMemo(() => {
    const tabs = [];
    if (eventsEnabled) tabs.push(<Tab>Événements</Tab>);
    if (immoEnabled) tabs.push(<Tab>Immobiliers</Tab>);
    if (jobsEnabled) tabs.push(<Tab>Emplois</Tab>);
    return tabs;
  }, [eventsEnabled, immoEnabled, jobsEnabled]);
  return (
    <div className="flex-col justify-start items-start gap-6 flex h-full pb-4 px-16 relative overflow-hidden">
      {/* <WidgetHeadline /> */}
      <div className="flex justify-between items-center w-full">
        <div className="text-white text-xl font-semibold tracking-tight">
          Mes annonces
        </div>
      </div>
      <TabGroup
        className="h-full shrink -mx-4 px-4 overflow-hidden grid grid-rows-[38px_1fr]"
        style={{ height: size.height - 80 }}
        defaultIndex={+(query.get("tab") ?? 0) || 0}
        onIndexChange={(tab) => {
          setQuery((prev) => {
            prev.set("tab", tab + "");
            prev.delete("old");
            return prev;
          });
        }}
      >
        <TabList>{tabs}</TabList>
        <TabPanels
          className="h-full flex flex-col shrink"
          style={{ height: size.height - 80 - 38 }}
        >
          <TabPanel className="h-full">
            {eventsEnabled && <EventList event />}
          </TabPanel>
          <TabPanel className="h-full">
            {immoEnabled && <EventList immo />}
          </TabPanel>
          <TabPanel className="h-full">
            {jobsEnabled && <EventList emploi />}
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default EventsList;

function EventList(categoryParams: IUseCategoriesParams) {
  const { company } = useR();
  const searchInput = useInput("");
  const categories = useCategories(categoryParams);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get("search") ?? "";
  const showOlds = searchParams.get("old") === "true";
  const localStorageKey = "showSelection:" + JSON.stringify(categoryParams);
  const [showSelections, setShowSelections] = React.useState<boolean>(
    localStorage.getItem(localStorageKey) === "true",
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, showSelections ? "true" : "false");
  }, [showSelections]);

  useDebounceEffect(
    () => {
      setSearchParams((prev) => {
        prev.set("search", searchInput.value);
        return prev;
      });
    },
    500,
    [searchInput.value],
  );
  const [data, setdata] = React.useState<
    TimenjoyListResponse<TimenjoySearchEvent>[]
  >([]);
  const query = useInfiniteQuery<TimenjoyListResponse<TimenjoySearchEvent>>({
    queryKey: ["events", searchString, categories, showOlds],
    queryFn: async ({ pageParam = 0 }) => {
      if (searchString)
        return searchEvents({
          page: pageParam + 1,
          perPage: PER_PAGE,
          search: searchString,
          company: company?.id,
          categories: categories.data?.map((a) => a.id),
          olds: showOlds,
        });
      else
        return getEvents({
          page: pageParam + 1,
          number: PER_PAGE,
          distance: 100,
          blackListed: false,
          search: searchString,
          company: company?.id,
          categories: categories.data?.map((a) => a.id),
          old: showOlds,
        });
    },
    getNextPageParam: (lastPage) => {
      const { currentPage, count } = lastPage.metaData;
      const maxPage = Math.ceil(count / PER_PAGE);
      if (+currentPage >= maxPage) return undefined;
      return +currentPage;
    },
    onSuccess(data) {
      setdata(data.pages);
    },
    refetchOnWindowFocus: false,
    // refetchInterval: 1000 * 60 * 30,
    // staleTime: 1000 * 60 * 20,
    // refetchOnWindowFocus: false,
    // refetchOnMount: false,
    // refetchOnReconnect: false,
  });
  const lastElementRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!lastElementRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          query.fetchNextPage();
        }
      },
      { threshold: 1 },
    );
    observer.observe(lastElementRef.current);
    return () => observer.disconnect();
  }, [query]);

  return (
    <div className="flex flex-col gap-4 py-6 h-full">
      <div className="flex gap-3">
        <TextInput
          className="w-80"
          placeholder="Rechercher"
          {...searchInput}
          icon={MagnifyingGlassIcon}
        />
        <div className="flex gap-2 items-center">
          <Switch
            checked={showOlds}
            onChange={(value) => {
              setSearchParams((prev) => {
                prev.set("old", value + "");
                return prev;
              });
            }}
          />
          <span className="text-white text-sm">Annonces passées</span>
        </div>
        <div className="flex gap-2 items-center">
          <Switch
            checked={showSelections}
            onChange={(value) => {
              setShowSelections(value);
            }}
          />
          <span className="text-white text-sm">Sélections associées</span>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="text-right text-white text-[15px] font-semibold tracking-tight flex items-center h-5">
          {query.isLoading ? (
            <Spinner className="w-5 h-5" />
          ) : (
            data[0]?.metaData.count ?? 0
          )}{" "}
          Annonces
        </div>
      </div>
      <div className="-mx-16 px-16 self-stretch h-full flex-col justify-start items-start gap-1 flex shrink overflow-y-auto noscrollbar">
        {data.map((page) => {
          return page.rows.map((item) => {
            return (
              <EventItem
                {...item}
                showSelection={showSelections}
                key={item.id}
              />
            );
          });
        })}
        {query.hasNextPage && (
          <div
            className="w-full h-20 flex items-center justify-center"
            onClick={() => {
              query.fetchNextPage();
            }}
            ref={lastElementRef}
          >
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
