import { PaymentCompany } from "@/types/payment.types";
import { TimenjoyCompany } from "@/types/timenjoy.types";
import { getToken } from "./auth.service";
import { AUTH_URL } from "./environment";

// const KONNECTZ_PAYMENT_URL = "https://konnectz-payment.vercel.app/api";
const KONNECTZ_PAYMENT_URL = `${AUTH_URL}/payment`;

export async function getSubscription(company: TimenjoyCompany) {
  const response = await fetch(`${KONNECTZ_PAYMENT_URL}/company/list`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      foreignId: [company.id],
    }),
  });
  if (!response.ok) {
    throw new Error("Could not get subscription");
  }
  const data = (await response.json()) as PaymentCompany[];
  if (!data?.[0]) {
    throw new Error("No subscription found");
  }
  return data[0];
}

export async function createPaymentCompany(
  company: TimenjoyCompany,
  email: string,
  subscriptionId?: string | undefined
) {
  const response = await fetch(`${KONNECTZ_PAYMENT_URL}/company`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      foreignId: company.id,
      name: company.name,
      email,
      subscriptionId,
    }),
  });
  if (!response.ok) {
    throw new Error("Could not create payment company");
  }
  return getSubscription(company);
}
