import clsx from "clsx";
import React from "react";

export interface ISelectProps {
  options: readonly string[];
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string;
  disabled?: boolean;
  className?: string;
};

export default function Select({
  options,
  value,
  onChange,
  defaultValue,
  disabled = false,
  className
}: ISelectProps) {
  return (
    <div className={clsx("relative", disabled && 'cursor-not-allowed')}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 bottom-0 w-6 h-6 z-0 my-auto text-subtitle right-2.5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
      <select
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
        className={clsx("w-full px-2.5 py-3 text-title relative z-10 rounded-md shadow-sm appearance-none bg-neutral-500/50 outline-none border border-konnectz_background focus:border-indigo-600 cursor-pointer", className, disabled && 'cursor-not-allowed')}
      >
        {options.map((option, idx) => (
          <option key={idx} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
