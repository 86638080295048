import clsx from "clsx";
import React from "react";

type Props = {
  placeholder: string;
  className?: string;
} & (
  | {
      onClick: () => void;
      value?: never;
      onChange?: never;
    }
  | {
      value: string;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
      onClick?: never;
    }
);

const SearchButton: React.FC<Props> = ({
  placeholder,
  className,
  onClick,
  value,
  onChange,
}) => {
  return (
    <div
      className={clsx(
        "relative w-full text-base bg-white rounded-full cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      <div className="label-button flex items-center gap-1 px-6 py-1 border rounded-full bg-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          placeholder={placeholder}
          disabled={!!onClick}
          autoFocus={!onClick}
          value={value}
          onChange={onChange}
          className="w-full px-2 py-2 text-gray-600 bg-transparent rounded-md outline-none disabled:cursor-default"
        />
      </div>
    </div>
  );
};

export default SearchButton;
