import { getZones } from "@/service/manager.service";
import { captureException } from "@/service/exceptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const useZones = () => {
  const queryClient = useQueryClient();
  const zones = useQuery({
    queryKey: ["zones"],
    queryFn: getZones,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 60 * 1000,
    staleTime: 60 * 1000,
    onSuccess(data) {
      queryClient.invalidateQueries(["baseComposition"]);
    },
    onError(err) {
      captureException(err);
      console.error(err);
    },
  });
  return zones.data;
};

export default useZones;
