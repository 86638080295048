import useR from "@/hooks/useR";
import {
  createConfig,
  _refreshConfig,
  updateConfig,
} from "@/service/konnectz.service";
import {
  associateCompanyConfig,
  createSelection,
  dissociateCompanyConfig,
  updateSelection,
} from "@/service/manager.service";
import { createApiAccount, updateApiAccount } from "@/service/timenjoy.service";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import { cleanName } from "@/utils/name";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";
import slugify from "slugify";

export type MutationPayload = {
  name: string;
  website: string;
  type: string;
  compositions?: string[];
  traffic: string;
  useCompanyAds?: boolean;
};

export function useCreation(submit: () => void) {
  const { company, client, apiAccounts } = useR();
  const toast = useToast();
  return useMutation({
    async mutationFn({
      name,
      website,
      type,
      compositions,
      useCompanyAds,
    }: MutationPayload) {
      if (!company) throw new Error("No company");
      const account = await createApiAccount({
        name: name,
        url: website,
        company_id: company.id,
      });
      const config = await createConfig({
        source_id: account.appId,
        source: slugify(company.name),
        selection: slugify(name),
        origins: [website, window.location.origin],
      });
      const selection = await createSelection({
        name: name,
        config: config.id,
        app_secret: account.app_secret,
        type,
        compositions,
      });

      if (useCompanyAds) await associateCompanyConfig(selection.id, company.id);

      return {
        account,
        selection,
        config: config.id,
      };
    },
    onSuccess({ account, selection, config }) {
      client.setQueryData(["apiAccount", account.id], account);
      client.setQueryData(["apiAccounts"], [...apiAccounts, account.id]);
      client.setQueryData(["selection", selection.id], selection);
      client.setQueryData(["currentApiAccount"], account.id);
      _refreshConfig(config).catch(console.error).finally(submit);
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      const { name } = variables;
      console.error(error);
      console.log("error", (error as Error).message);
      if ((error as Error).message === "Bad Request") {
        console.log("is bad request");
        // check if another selection with this name already exists
        const apiAccountWithSameName = apiAccounts.find((id) => {
          const account = client.getQueryData<TimenjoyApiAccount>([
            "apiAccount",
            id,
          ]);
          console.log("account", cleanName(account?.name));
          return cleanName(account?.name) === name;
        });
        if (apiAccountWithSameName) {
          toast({
            title: "Un widget existe déjà avec ce nom",
            description: "Veuillez en choisir un autre",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
}

export function useUpdate(submit: () => void) {
  const { company, client, currentSelection, currentApiAccount, apiAccounts } =
    useR();
  const toast = useToast();
  return useMutation({
    async mutationFn({
      website,
      name,
      type,
      compositions = [],
      useCompanyAds,
    }: MutationPayload) {
      if (!company) throw new Error("No company");
      if (!currentApiAccount) throw new Error("No currentApiAccount");
      if (!currentSelection?.Config) throw new Error("No currentSelection");
      const currentCompositions =
        currentSelection.compositions?.map((comp) => comp.id) || [];
      const add_compositions = compositions.filter(
        (compId) => !currentCompositions.includes(compId),
      );
      const remove_compositions = currentCompositions.filter(
        (compId) => !compositions.includes(compId),
      );
      console.log("updating selection");
      const [account, config, selection, companyConfig] = await Promise.all([
        updateApiAccount(currentApiAccount.id, {
          url: website,
          name,
        }),
        updateConfig(currentSelection.Config.id, {
          source: slugify(company.name),
          origins: [website],
        }),
        updateSelection(currentSelection.id, {
          name,
          type,
          add_compositions,
          remove_compositions,
        }),
        useCompanyAds
          ? associateCompanyConfig(currentSelection.id, company.id)
          : dissociateCompanyConfig(currentSelection.id, company.id),
      ]);
      console.log("update", { companyConfig });
      return {
        account,
        selection,
        config: config.id,
      };
    },
    onSuccess({ account, selection, config }, { useCompanyAds }) {
      console.log("update success");
      client.setQueryData(["apiAccount", account.id], account);
      client.setQueryData(
        ["apiAccounts"],
        [...new Set([...apiAccounts, account.id])],
      );
      const updatedSelection = {
        ...selection,
        companyConfig: useCompanyAds ? selection.companyConfig : null,
        companyConfigId: useCompanyAds
          ? selection.companyConfigId || company!.id
          : null,
      };
      console.log({ updatedSelection });
      client.setQueryData(["selection", account.id], updatedSelection);
      _refreshConfig(config).catch(console.error).finally(submit);
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      console.error(error);
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
}
