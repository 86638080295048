import * as Sentry from "@sentry/react";
import {
  extraErrorDataIntegration,
  captureConsoleIntegration,
  httpClientIntegration,
} from "@sentry/integrations";

Sentry.init({
  dsn: "https://2cf4aa34565b53916c1ebb8668d5e68e@o4506060440010752.ingest.sentry.io/4506060442763264",
  integrations: [
    Sentry.browserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/(.*\.)?timenjoy\.fr/,
        /^https:\/\/(.*\.)?konnectz\.io/,
        /^https:\/\/(.*\.)?oriun\.fr/,
      ],
    }),
    Sentry.dedupeIntegration(),
    captureConsoleIntegration({
      levels: ["error"],
    }),
    extraErrorDataIntegration(),
    httpClientIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [
        "https://apisails.timenjoy.fr",
        "https://konnectz.timenjoy.fr",
        "https://timenjoy.fr",
        "https://stripe-server.timenjoy.fr",
        "https://widget.timenjoy.fr",
        "https://tnj-smart-manager.oriun.fr",
        "https://tnj-smart-analytics.oriun.fr",
        "https://konnectz-payment.vercel.app",
        "https://mytimenjoy.timenjoy.fr",
        "https://smart-authentication.vercel.app",
        "https://smart-authentication-three.vercel.app",
      ],
    }),
  ],
  sendDefaultPii: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE,
  enabled: import.meta.env.PROD,
});

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import Utc from "dayjs/plugin/utc";
import "dayjs/locale/fr";
import Timezone from "dayjs/plugin/timezone";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(Utc);
dayjs.extend(Timezone);
dayjs.extend(CustomParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.locale("fr");

import "./service/polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ChakraProvider } from "@chakra-ui/react";
import "rsuite/dist/rsuite-no-reset.min.css";
import "./index.scss";
import BrowserAuth from "./components/browser-auth";
import ZoneProvider from "./components/zone-provider";
import { inject } from "@vercel/analytics";
import Hotjar from "@hotjar/browser";
import { ThemeProvider } from "@/components/theme-provider";
import { TooltipProvider } from "./components/ui/tooltip";

inject();

const cacheTime = 1000 * 60 * 60 * 24 * 7;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const doNotPersistQueries: string[] = [
  "browserToken",
  "single-ad-stats",
  "single-ad-stats-by-widget",
  "ads-stats",
  "basic-stats",
];

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  buster: "018ffe30-a847-7fb0-912d-485c8e514d58",
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => {
      return !doNotPersistQueries.includes(queryKey[0] as string);
    },
  },
});

// retrieveDarkMode();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <TooltipProvider>
        <Router basename={import.meta.env.BASE_URL}>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider>
              <BrowserAuth />
              <ZoneProvider />
              <App />
            </ChakraProvider>
          </QueryClientProvider>
        </Router>
      </TooltipProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

const siteId = 3672479;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
