import React from "react";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const PaddingLayout: React.FC<Props> = (props) => {
  return (
    <main className="w-screen min-h-screen h-full p-6 sm:p-16 md:px-32 !pb-0 bg-konnectz_background">
      <div className="max-w-screen-2xl w-full h-[calc(100vh_-_40px)] sm:h-[calc(100vh_-_64px)] m-auto bg-radial from-darkblue_konnectz/30 to-konnectz_background to-75%">
        {props.children}
      </div>
    </main>
  );
};

export default PaddingLayout;
