export function debounce<T extends any[]>(
  fn: (...args: T) => void,
  ms: number
) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: T) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), ms);
  };
}
