import Button from "@/components/button";
import useInput, { UseInputHandler } from "@/hooks/useInput";
import useR from "@/hooks/useR";
import * as React from "react";
import dayjs from "dayjs";
import { useKeyboard } from "@/hooks/useKeyboard";
import { IActionButtonProps } from "@/components/context-menu/selection-menu/v2";
import { useUpdateScheduleMutation } from "@/mutations/update-schedule";
import useValidation from "@/hooks/useValidation";
import { z } from "zod";
import { useToast } from "@chakra-ui/react";
import DatePicker from "rsuite/DatePicker";
import frFR from "rsuite/locales/fr_FR";
import { FloatingPortal } from "@floating-ui/react";
import { BookmarkIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";
import InfoBubble from "@/components/info-bubble";
import Spinner from "@/components/spinner";

export interface IUpdateScheduleModalProps
  extends Omit<IActionButtonProps, "lockDrag"> {
  open: boolean;
  setOpen(open: boolean): void;
}

const UpdateScheduleModal: React.FunctionComponent<
  IUpdateScheduleModalProps
> = ({ open, setOpen, ...schedule }) => {
  const { currentSelection } = useR();
  const toast = useToast();
  const from = useInput(dayjs(schedule.from).toISOString());
  const to = useInput(dayjs(schedule.to).toISOString());

  const schema = React.useMemo(
    () =>
      z.strictObject({
        from: z
          .string()
          .refine((s) => dayjs(s).isValid(), "Les dates ne sont pas valides"),
        to: z
          .string()
          .refine((s) => dayjs(s).isValid(), "Les dates ne sont pas valides"),
      }),
    [schedule.from, schedule.to],
  );

  const [isValid, validate] = useValidation(schema);

  React.useEffect(() => {
    validate({ from: from.value, to: to.value }, true);
  }, [from.value, to.value]);

  const updateSchedule = useUpdateScheduleMutation();

  function close(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === event.currentTarget) setOpen(false);
  }

  useKeyboard({
    Escape: () => setOpen(false),
  });

  async function submit() {
    if (updateSchedule.isLoading) return;
    try {
      const data = validate({ from: from.value, to: to.value });
      if (!data) return;
      await updateSchedule.mutateAsync({
        id: schedule.advertisement.id,
        config: currentSelection!.config,
        from: +dayjs(from.value).startOf("day"),
        to: +dayjs(to.value).endOf("day"),
        exactDate: true,
      });
      setOpen(false);
    } catch (e) {
      toast({
        title: "Echec de la modification",
        description: (e as Error).message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  function datePickerDateChange(input: UseInputHandler) {
    return (value: Date | null) => {
      const date = dayjs(value);
      if (date.isValid()) {
        input.onChange(date.toISOString());
        return;
      }
      console.log("invalid date", date, value);
    };
  }
  if (!open) return null;
  return (
    <FloatingPortal preserveTabOrder>
      <div
        className="fixed inset-0 flex justify-center items-center bg-background_dark/40 z-40 backdrop-blur cursor-default"
        onClick={close}
      >
        <div className=" w-96 border border-background_light px-6 py-8 bg-konnectz_background rounded-2xl flex-col justify-start items-start gap-6 inline-flex">
          <div className="flex justify-between items-center w-full">
            <div className="text-white text-xl font-semibold tracking-wide">
              Planifier la diffusion
            </div>
          </div>

          <div className="w-full">
            <h6 className="text-white text-sm font-semibold tracking-wide flex items-center">
              <BookmarkIcon className="w-5 h-5 mr-2 text-emphasis" /> Evénement
            </h6>
            <div className="w-full mt-3 text-white">
              <h6>{schedule.advertisement.firstLine}</h6>
              <h6>{schedule.advertisement.secondLine}</h6>
            </div>
          </div>
          <div className="w-full">
            <h6 className="text-white text-sm font-semibold tracking-wide flex items-center">
              <CalendarDaysIcon className="w-5 h-5 mr-2 text-emphasis" />{" "}
              Nouvelles dates
            </h6>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-3">
              <label className="text-white text-sm font-semibold tracking-wide">
                Du
              </label>
              <label className="text-white text-sm font-semibold tracking-wide flex gap-1 items-center">
                Au
                <InfoBubble className="text-emphasis">
                  <div className="max-w-[300px] text-xs text-white">
                    Si la diffusion s'étend au-delà de la fin de l'événement, il
                    sera automatiquement retiré de la sélection une fois
                    l'événement terminé.
                  </div>
                </InfoBubble>
              </label>
              <DatePicker
                locale={frFR.DatePicker}
                value={dayjs(from.value).toDate()}
                onChange={datePickerDateChange(from)}
                size="md"
                ranges={[]}
                placement="autoVertical"
              />
              <DatePicker
                locale={frFR.DatePicker}
                value={dayjs(to.value).toDate()}
                onChange={datePickerDateChange(to)}
                size="md"
                ranges={[]}
                placement="autoVertical"
              />
            </div>
          </div>
          <div className="flex justify-between mt-4 items-center w-full">
            <Button
              disabled={updateSchedule.isLoading}
              variant="quiet"
              className="bg-background_light"
              onClick={() => setOpen(false)}
            >
              Annuler
            </Button>
            <Button
              variant="tertiary"
              className="flex gap-[6px] items-center"
              disabled={!isValid || updateSchedule.isLoading}
              onClick={submit}
              clickThroughDisabled
            >
              Valider{" "}
              {updateSchedule.isLoading && <Spinner className="w-4 h-4" />}
            </Button>
          </div>
        </div>
      </div>
    </FloatingPortal>
  );
};

export default UpdateScheduleModal;
