import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import TextInput from "./text";
import useInput from "@/hooks/useInput";
import dayjs from "dayjs";

export interface DateTimeInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  type?: "datetime"
  value?: string;
  onChange?: (value: string) => void;
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  className,
  disabled,
  value,
  onChange,
  type = "datetime",
  ...props
}) => {
  const date = useInput()
  const time = useInput()
  React.useEffect(() => {
    const [hours, minutes] = time.value.split(":").map(x => parseInt(x))
    const newDate = dayjs(date.value).hour(hours ?? 0).minute(minutes ?? 0)
    if(isNaN(+newDate) || !newDate.isValid()) return
    onChange?.(newDate.toISOString())
  }, [date.value, time.value])
  React.useEffect(() => {
    date.onChange(dayjs(value).format("YYYY-MM-DD"))
    time.onChange(dayjs(value).format("HH:mm"))
  }, [value])

  return (
    <div className="relative grid grid-cols-2 gap-2">
      <TextInput {...date} type="date" {...props} disabled={disabled} />
      <TextInput {...time} type="time"  {...props} disabled={disabled}  />
    </div>
  );
};

export default DateTimeInput;
