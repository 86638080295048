import { useMemo, useState } from "react";

export function useSet<T>(initialSet = new Set<T>()) {
  const [set, setSet] = useState(initialSet);

  const stableActions = useMemo(
    () => ({
      add: (item: T) =>
        setSet((prevSet) => new Set([...Array.from(prevSet), item])),
      remove: (item: T) =>
        setSet(
          (prevSet) => new Set(Array.from(prevSet).filter((i) => i !== item))
        ),
      reset: () => setSet(initialSet),
    }),
    [initialSet]
  );

  const utils = useMemo(
    () => ({
      has: (item: T) => set.has(item),
      size: set.size,
      values: Array.from(set.values()),
    }),
    [set]
  );

  return {
    ...stableActions,
    ...utils,
  } as const;
}
