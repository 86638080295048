import * as React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import type CropperType from 'cropperjs';
import { debounce } from '@/utils/debounce';
import Button from '@/components/button';

interface IImageCropperProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    imageUrl: string;
    currentImageUrl: string;
    onUpdate: (url: string) => void;
}

const ImageCropper: React.FunctionComponent<IImageCropperProps> = (props) => {
    const cropper = React.useRef<CropperType>()
    const [fallbackImageUrl, setFallbackImageUrl] = React.useState(props.currentImageUrl)
    React.useEffect(() => {
        if (props.open) setFallbackImageUrl(props.currentImageUrl)
    }, [props.open])
    const debounceUpdate = React.useCallback(debounce(props.onUpdate, 500), [])
    function onCrop() {
        const url = cropper.current?.getCroppedCanvas({ width: 640, height: 320 }).toDataURL()
        if (url) debounceUpdate(url)
    }
    function cancel() {
        props.onUpdate(fallbackImageUrl)
        props.setOpen(false)
    }
    function confirm() {
        onCrop()
        props.setOpen(false)
    }
    if (!props.open) return null
    return <div
        className="fixed inset-0 flex justify-center items-center bg-background_dark/40 z-20 backdrop-blur cursor-pointer"
        onClick={() => props.setOpen(false)}
    >
        <div onClick={e => e.stopPropagation()} className="w-[800px] h-auto border border-background_light px-10 py-8 bg-konnectz_background rounded-2xl flex-col justify-start items-start gap-6 inline-flex">
            <Cropper
                src={props.imageUrl}
                guides
                className='aspect-[2_/_1] w-full h-auto'
                crop={onCrop}
                aspectRatio={2}
                crossOrigin='anonymous'
                onInitialized={instance => { cropper.current = instance }}
            />
            <div className='flex gap-2'>
                <Button onClick={cancel}>Annuler</Button>
                <Button onClick={confirm}>Appliquer</Button>
            </div>
        </div>
    </div>
};

export default ImageCropper;
