import React from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
  disabled?: boolean;
  clickThroughDisabled?: boolean;
  className?: string;
  variant?: "primary" | "secondary" | "tertiary" | "quiet" | "outline";
};

const Button = ({
  variant = "primary",
  disabled,
  className,
  onClick,
  children: children,
  clickThroughDisabled = false,
}: Props) => {
  return (
    <button
      className={twMerge(clsx(
        "px-5 py-2 text-sm font-semibold duration-150 active:shadow-lg disabled:cursor-default",
        variant === "primary" &&
        "text-white bg-emphasis_dark rounded-lg hover:bg-emphasis_dark",
        variant === "secondary" &&
        "text-emphasis_dark bg-white rounded-lg hover:bg-neutral-50 font-semibold",
        variant === "tertiary" &&
        "text-white bg-konnectz_accent rounded-lg hover:bg-konnectz_accent/80",
        variant === "quiet" && "text-white rounded-lg hover:bg-gray-50/20",
        variant === "outline" &&
        "text-white border border-white rounded-lg hover:bg-white/20",
        disabled && "opacity-80",
        className
      ))}
      onClick={disabled && !clickThroughDisabled ? undefined : onClick}
    >
      {children}
    </button>
  );
};

export default Button;
