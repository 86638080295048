import React from "react";

export interface PersistOptions<T> {
  key: string;
  defaultValue: T;
  parser?: (value: string) => T;
  serializer?: (value: T) => string;
  storage?: "localStorage" | "sessionStorage";
}

const PREFIX = "0906-";
export default function usePersist<T>({
  key,
  defaultValue,
  parser = JSON.parse,
  serializer = JSON.stringify,
  storage = "localStorage",
}: PersistOptions<T>) {
  const [state, setState] = React.useState<T>(() => {
    try {
      const persistedValue = window[storage].getItem(PREFIX + key);
      return persistedValue !== null ? parser(persistedValue) : defaultValue;
    } catch (error) {
      console.error(error);
      return defaultValue;
    }
  });

  React.useEffect(() => {
    window[storage].setItem(PREFIX + key, serializer(state));
  }, [key, state, serializer]);

  return [state, setState] as const;
}
