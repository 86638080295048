import React from "react";

export default function useOptimistic<S = any, T = any>(
  state: T,
  updateFn: (currentState: T, payload: S) => T,
) {
  const waitingForUpdate = React.useRef<"IDLE" | "ON" | "OFF">("OFF");
  const [optimisticState, setOptimisticState] = React.useState<T>(state);
  React.useEffect(() => {
    waitingForUpdate.current = "IDLE";
    setOptimisticState(state);
    setTimeout(() => {
      waitingForUpdate.current = "OFF";
    }, 100);
  }, [state]);
  React.useEffect(() => {
    if (waitingForUpdate.current === "IDLE") waitingForUpdate.current = "OFF";
    else waitingForUpdate.current = "ON";
  }, [optimisticState]);
  const optimisticUpdate = React.useCallback(
    (payload: S) => {
      //if (waitingForUpdate.current === "ON") return;
      setOptimisticState((currentState) => updateFn(currentState, payload));
    },
    [updateFn],
  );
  return [optimisticState, optimisticUpdate] as const;
}
