import React from "react";

type Props = {
  content: string;
};

const red = "text-rose-400";

const HTML: React.FC<Props> = ({ content }) => {
  return (
    <span className="text-subtitle">
      {content.split('"').map((a, aIdx) => {
        if (aIdx % 2 === 1)
          return (
            <span className="text-green-400" key={a + aIdx}>
              &quot;{a}&quot;
            </span>
          );
        const b = a
          .replace(/\n/gim, "")
          .replace(/ +/gim, " ")
          .replace(/(\W) +/gim, "$1")
          .replace(/(\w) +(\W)/gim, "$1$2")
          .replace(/></gim, "> <");
        return b.split(" ").map((c, cIdx) => {
          if (!c) return " ";
          if (c.endsWith("=")) {
            return [
              <span className="text-orange-300" key={a + aIdx + c + cIdx}>
                {c.slice(0, -1)}
              </span>,
              "=",
            ];
          }
          if (/^[a-zA-Z0-9-]*$/.test(c)) {
            return [
              <span className={red} key={a + aIdx + c + cIdx}>
                {c}
              </span>,
              " ",
            ];
          }
          if (/^<[a-zA-Z0-9-]*$/.test(c)) {
            return [
              "<",
              <span className={red} key={a + aIdx + c + cIdx}>
                {c.slice(1)}
              </span>,
              " ",
            ];
          }
          if (/^[a-zA-Z0-9-]*>$/.test(c)) {
            return [
              <span className={red} key={a + aIdx + c + cIdx}>
                {c.slice(0, -1)}
              </span>,
              ">",
            ];
          }
          if (/^<[a-zA-Z0-9-]*>$/.test(c)) {
            return [
              "<",
              <span className={red} key={a + aIdx + c + cIdx}>
                {c.slice(1, -1)}
              </span>,
              ">",
            ];
          }
          if (/^<[a-zA-Z0-9-]*\/>$/.test(c)) {
            return [
              "<",
              <span className={red} key={a + aIdx + c + cIdx}>
                {c.slice(1, -2)}
              </span>,
              "/>",
            ];
          }
          if (/^<\/[a-zA-Z0-9-]*>$/.test(c)) {
            return [
              "</",
              <span className={red} key={a + aIdx + c + cIdx}>
                {c.slice(2, -1)}
              </span>,
              ">",
            ];
          }

          return [c, " "];
        });
      })}
    </span>
  );
};

export default HTML;
