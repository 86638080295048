import createThrottle from "@oriun/throttle";
import { ErrorHandlerResponse } from "@/types/apis.types";
import {
  Advertisement,
  Configs,
  CreateConfigPayload,
  Customization,
  Origin,
  RotationStrategy,
  Schedule,
  WidgetContent,
} from "@/types/konnectz.types";
import { nanoid } from "nanoid";
import deepMerge from "deepmerge";
import { getToken } from "./auth.service";
import { AUTH_URL } from "./environment";
import PromisePool from "@supercharge/promise-pool";

// const KONNECTZ_URL = "https://konnectz.timenjoy.fr/api";
const KONNECTZ_URL = `${AUTH_URL}/selection`;

export async function createConfig({
  selection,
  source,
  source_id,
  origins = [],
}: CreateConfigPayload) {
  const payload = {
    app_secret: nanoid(),
    selection,
    source,
    source_id,
    origins: origins.map((o) => new URL(o).origin),
  };
  const request = await fetch(`${KONNECTZ_URL}/config`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return request.json();
}

export async function updateConfig(
  id: string,
  {
    source,
    origins,
    rotation,
  }: Pick<Partial<CreateConfigPayload>, "source" | "origins" | "rotation">,
) {
  if (!id) throw new Error("You must provide a config id");
  const request = await fetch(`${KONNECTZ_URL}/config/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ source, rotation }),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }

  const config = (await request.json()) as Configs;

  if (origins) await addOrigins(id, origins);

  return config;
}

export async function getConfig(id: string) {
  if (!id) throw new Error("You must provide a config id");
  const request = await fetch(`${KONNECTZ_URL}/config/${id}`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return request.json() as Promise<Configs>;
}

export async function _refreshConfig(id: string) {
  const request = await fetch(
    `${KONNECTZ_URL}/selection/${id}?write_selection=true`,
    {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return true;
}

export const throttledRefreshConfig = createThrottle<[config: string], void>(
  async (configs) => {
    console.log("Refreshing configs", configs, new Date().toISOString());
    await PromisePool.withConcurrency(5)
      .for(configs)
      .process(async ([config]) => {
        await _refreshConfig(config);
      });
  },
  5_000,
);

window.onbeforeunload = () => {
  throttledRefreshConfig.force();
};

export async function customize(config: string, options: Record<string, any>) {
  const configObject = await getConfig(config);
  const currentCustomizaton = JSON.parse(configObject.Customization.json);
  const request = await fetch(`${KONNECTZ_URL}/customization`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...deepMerge(currentCustomizaton, options),
      config,
    }),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return request.json() as Promise<Customization>;
}

export async function addOrigins(config: string, origins: string[]) {
  const request = await fetch(`${KONNECTZ_URL}/origin`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      config,
      origins: origins.map((o) => new URL(o).origin),
    }),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }

  return request.json() as Promise<Origin[]>;
}

export async function getAdvertisements({
  id,
  timenjoyId,
  include = ["pictures"],
}: {
  id?: string[];
  timenjoyId?: string[];
  include?: ("configToAdvertisement" | "pictures")[];
}) {
  if (!id?.length && !timenjoyId?.length) return [];
  const request = await fetch(`${KONNECTZ_URL}/advertisement/list`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      timenjoyId,
      include,
    }),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }

  const advertisement = (await request.json()) as Advertisement[];
  return advertisement;
}

export async function refreshAdvertisement(id: string) {
  const request = await fetch(
    `${KONNECTZ_URL}/advertisement/${id}/refresh?forcePicture=true`,
    {
      method: "PUT",
      credentials: "include",
      body: "{}",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return true;
}

export async function scheduleAdvertisement(
  config: string,
  timenjoyId: string,
  from: number,
  to: number,
  rotation_strategy: RotationStrategy = RotationStrategy.FILLER,
) {
  const request = await fetch(`${KONNECTZ_URL}/advertisement`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      config,
      from,
      to,
      rotation_strategy,
      exactDate: true,
      timenjoyId,
      firstLine: "",
      secondLine: "",
      thirdLine: "",
      previewLink: "",
      originLink: "",
      type: "event",
    }),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }

  return request.json() as Promise<Schedule>;
}

export interface IRescheduleAdvertisement {
  timenjoyId?: string;
  id?: string;
  config: string;
  from?: number;
  to?: number;
  rotation_strategy?: RotationStrategy;
  order?: number;
  exactDate?: true;
}

export async function rescheduleAdvertisement(
  params: IRescheduleAdvertisement,
) {
  if (!params.id && !params.timenjoyId)
    throw new Error("You must provide either an id or a timenjoyId");
  const request = await fetch(`${KONNECTZ_URL}/config_to_advertisement`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }

  return request.json() as Promise<Schedule>;
}

export async function flatRotation(config: string) {
  const request = await fetch(
    `${KONNECTZ_URL}/selection/${config}/rotate?iteration=0&commit=true`,
    {
      method: "PUT",
      credentials: "include",
      body: "{}",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    },
  );
  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return true;
}

export async function unscheduleAdvertisement(id: string) {
  const request = await fetch(`${KONNECTZ_URL}/config_to_advertisement/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }

  return true;
}

export class KonnectzError extends Error {
  headers: Record<string, string>;
  statusCode;
  body = { error: "Unknown Error" };

  static async makeError(response: Response) {
    if (response.headers.get("content-type")?.includes("application/json")) {
      const res = await response.text();
      let json: any = {};
      try {
        json = JSON.parse(res);
      } catch (e) {
        console.error("Error parsing JSON", res);
        json = { error: res };
      }
      return new KonnectzError({
        statusCode: response.status as ErrorHandlerResponse["statusCode"],
        headers: Object.fromEntries(response.headers),
        body: json,
      });
    } else {
      return new KonnectzError({
        statusCode: response.status as ErrorHandlerResponse["statusCode"],
        headers: Object.fromEntries(response.headers),
        body: { error: response.statusText },
      });
    }
  }

  constructor(
    response: ErrorHandlerResponse & { headers: Record<string, string> },
  ) {
    if (!response.body) {
      console.error("no body", response);
    }
    super(`Konnectz Error: ${response.body.error}`);
    this.headers = response.headers || {};
    this.statusCode = response.statusCode;
    this.body = response.body;
  }
}

export function orderAdvertisement(a: Schedule, b: Schedule) {
  // If one is a filler and the other is not, the filler should be last
  if (
    (a.rotation_strategy === RotationStrategy.FILLER) !==
    (b.rotation_strategy === RotationStrategy.FILLER)
  ) {
    return a.rotation_strategy === RotationStrategy.FILLER ? 1 : -1;
  }
  if (a.order === b.order) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }
  return a.order - b.order;
}

export async function getWidgetContent(app_secret: string, selection: string) {
  const request = await fetch("https://konnectz.timenjoy.fr/selection.json", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "x-app-secret": app_secret,
      "x-selection": selection,
    },
  });
  if (!request.ok) {
    throw await KonnectzError.makeError(request);
  }
  return (await request.json()) as WidgetContent;
}
