
import * as React from 'react';
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import usePersist from '@/hooks/usePersist';
import { X } from 'lucide-react';

interface ITrialCountDownBannerProps {
    show: boolean;
    daysLeft: number;
}

const TrialCountDownBanner: React.FunctionComponent<ITrialCountDownBannerProps> = (props) => {
    const navigate = useNavigate();
    const [dismissed, setDismissed] = usePersist({
        key: 'trialCountDownBanner:dismissed',
        defaultValue: false,
        parser: (value: string) => value === 'true',
        storage: "sessionStorage"
    })
    if (!props.show || dismissed) return null

    return (
        <div className="bg-konnectz_accent fixed z-50 top-0 left-0 right-0">
            <div className="max-w-screen-xl mx-auto px-4 py-2 items-center gap-x-4 justify-center text-sm text-white sm:flex md:px-8">
                <p className="font-medium">
                    Votre essai gratuit prendra fin dans {props.daysLeft} jours.
                </p>
                <Button
                    onClick={() => navigate("/app/premium")}
                    className="text-konnectz_accent px-3 text-sm"
                    size="sm"
                >
                    Passer premium
                </Button>
            </div>
            <Button onClick={() => setDismissed(true)} variant="ghost" size="icon" className="absolute top-1.5 right-0 p-2 text-white">
                <X size={24} />
            </Button>
        </div>
    )
};

export default TrialCountDownBanner;

