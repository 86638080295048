import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";
import { isCustomSelection } from "@/utils/isCustomSelection";
import { BriefcaseIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  CodeBracketIcon,
  SparklesIcon,
  HomeIcon,
  ListBulletIcon,
  PlusCircleIcon,
  ArrowRightOnRectangleIcon,
  ChartBarIcon,
  FolderIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import clsx from "clsx";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type Props = {
  label: string;
  to: string;
  Icon: typeof HomeIcon;
  target?: string;
  color?: string;
  show?: boolean;
  fullReload?: boolean;
  prefix?: string;
};

const MenuLink: React.FC<Props> = ({
  label,
  to,
  target,
  Icon,
  color = "text-emphasis_dark",
  show = true,
  fullReload = false,
  prefix,
}) => {
  const location = useLocation();
  const isCurrent = location.pathname === to;
  if (!show) return null
  return (
    <Link
      className={twMerge(
        clsx(
          "font-medium text-sm text-title py-2 flex gap-2 items-center pl-3",
          isCurrent
            ? "border-l-4 rounded-r-lg -ml-[16px] cursor-default border-" +
            color
            : "rounded-lg -ml-3 hover:bg-background_light cursor-pointer"
        )
      )}
      to={to}
      target={target}
      onMouseDown={(e) => {
        if (fullReload) return window.open(to, "_self")
        if (prefix && location.pathname.startsWith(prefix) && !isCurrent) return window.open(to, "_self")
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Icon className={clsx("h-4 w-4", color)} />
      {label}
    </Link>
  );
};

const MenuGroup: React.FC<{
  label?: string | null;
  children: React.ReactNode | React.ReactNode[];
  show?: boolean;
}> = ({ label, children, show = true }) => {
  const [open, setOpen] = React.useState(true);
  if (show === false) return null;
  if (!label) return <div>{children}</div>;
  return (
    <div className="mt-8 flex flex-col space-y-1">
      <label
        className="text-subtitle py-2 -my-2 rounded-lg pl-3 -ml-3 text-sm font-semibold active:bg-background_light flex items-center gap-2 cursor-pointer"
        onClick={() => setOpen((a) => !a)}
      >
        {label}{" "}
        {open ? (
          <ChevronDownIcon className="h-4 w-4 text-subtitle" />
        ) : (
          <ChevronRightIcon className="h-4 w-3 text-subtitle" />
        )}
      </label>
      {open ? children : null}
    </div>
  );
};

const AppMenu: React.FC = () => {
  const { currentSelection, permissions } = useR();
  const { policy } = useAuth()
  const { canUseSelection, canUseEventBuilder, isFreemium, isTrial, isPremium } = permissions;
  const showSelectionTabs =
    isCustomSelection(currentSelection) && canUseSelection;
  const showAnalyticsTab = policy?.canConsultStatistics && isPremium;
  const showPublishersTab = canUseEventBuilder;
  return (
    <div className="flex flex-col h-full pl-8 pr-6 pb-6">
      <img
        src={import.meta.env.BASE_URL + "img/logo.png"}
        width={120}
        className="mb-8"
      />
      <MenuLink label="Dashboard" to="/app/dashboard" Icon={HomeIcon} />
      <MenuGroup label={showPublishersTab ? "Widgets" : null}>
        {showSelectionTabs && (
          <MenuLink
            label="Ma sélection"
            to="/app/selection"
            Icon={ListBulletIcon}
            show={!!policy?.canConsultWidgets}
          />
        )}
        {showAnalyticsTab && (
          <MenuLink label="Analytics" to="/app/analytics" color="text-konnectz_accent" Icon={ChartBarIcon} />
        )}
        {/* <MenuLink label="Mes Widgets" to="/app/widgets" Icon={Squares2X2Icon} /> */}
        <MenuLink
          label="Créer un Widget"
          to="/app/widgets/new"
          Icon={PlusCircleIcon}
          show={!!policy?.canCreateWidgets}
        />
        {(isFreemium || isTrial) && (
          <MenuLink
            label="Passer premium"
            to="/app/premium"
            Icon={SparklesIcon}
            color="text-konnectz_accent"
          />
        )}
      </MenuGroup>
      <MenuGroup label="Annonces" show={showPublishersTab && !!policy?.canConsultAds}>
        <MenuLink label="Mes annonces" to="/app/events" Icon={FolderIcon} />
        <MenuLink
          label="Créer une annonce"
          to="/app/events/new"
          Icon={PlusCircleIcon}
          prefix="/app/events/"
          // fullReload
          show={!!policy?.canCreateAds}
        />
      </MenuGroup>
      <MenuGroup label="Guides">
        <MenuLink
          label="Implémentation"
          to="/guides/implementing"
          Icon={CodeBracketIcon}
          target="_blank"
        />
        {/* <MenuLink
          label="Use cases"
          to="/guides/widget-types"
          Icon={ComputerDesktopIcon}
          target="_blank"
        /> */}
      </MenuGroup>
      <MenuGroup label="Entreprise" show={!!policy?.canConsultUsers}>
        <MenuLink
          label="Utilisateurs"
          to="/app/company/users"
          Icon={BriefcaseIcon}
        />
        {/* <MenuLink
          label="Use cases"
          to="/guides/widget-types"
          Icon={ComputerDesktopIcon}
          target="_blank"
        /> */}
      </MenuGroup>
      <div className="h-full shrink" />
      <div className="mt-8 flex flex-col space-y-1">
        {/* <MenuLink label="Support" to="/app/support" Icon={LifebuoyIcon} /> */}
        {/* <MenuLink label="Paramètres" to="/app/settings" Icon={Cog6ToothIcon} /> */}
        <LogoutButton />
      </div>
    </div>
  );
};

export default AppMenu;

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

interface ILogoutButtonProps {
}

function LogoutButton(props: ILogoutButtonProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger className={twMerge(
        clsx(
          "font-medium text-sm text-title py-2 flex gap-2 items-center pl-3",
          "rounded-lg -ml-3 hover:bg-background_light cursor-pointer"
        )
      )}>
        <ArrowRightOnRectangleIcon className={clsx("h-4 w-4", "text-red-500")} />
        Déconnexion
      </AlertDialogTrigger>
      <AlertDialogContent className="border-border max-w-sm bg-konnectz_background">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-white">
            Etes-vous sûr de vouloir vous déconnecter ?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Vous serez redirigé vers la page de connexion.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="border-white text-white bg-konnectz_background hover:bg-background_light">
            Annuler
          </AlertDialogCancel>
          <Link to="/auth/logout">
            <AlertDialogAction className="bg-red-600 text-white hover:text-white hover:bg-red-800">
              Confirmer
            </AlertDialogAction>
          </Link>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

