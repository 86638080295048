import React from "react";
import { useNavigate } from "react-router-dom";

const AppCompanyEntryPoint: React.FC = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        return navigate("/app/company/users", { replace: true });
    }, []);
    return null;
};

export default AppCompanyEntryPoint;
