import useR from "@/hooks/useR";
import { useSchedules } from "@/hooks/useSchedules";
import {
  flatRotation,
  rescheduleAdvertisement,
  throttledRefreshConfig,
} from "@/service/konnectz.service";
import { Schedule } from "@/types/konnectz.types";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";

export interface IReorderMutationProps {
  reorderedItems: Schedule[];
  referenceItems: Schedule[];
  signal: AbortSignal;
}
export function useReorderMutation() {
  const { currentSelection, currentApiAccount } = useR();
  const toast = useToast();
  const [, refetch] = useSchedules();
  return useMutation({
    mutationFn: async ({
      reorderedItems,
      referenceItems,
      signal,
    }: IReorderMutationProps) => {
      if (!currentSelection || !currentApiAccount)
        throw new Error("No selection selected");
      if (referenceItems.length !== reorderedItems.length)
        throw new Error("Une erreur s'est produite");

      const firstReoNotInPlace = referenceItems.findIndex(
        (item, index) => reorderedItems[index]?.id !== item.id,
      );
      const lastReoNotInPlace = reorderedItems.findLastIndex(
        (item, index) => referenceItems[index]?.id !== item.id,
      );
      if (firstReoNotInPlace === -1 || lastReoNotInPlace === -1) return false;

      if (
        !referenceItems[firstReoNotInPlace] ||
        !referenceItems[lastReoNotInPlace] ||
        !reorderedItems[firstReoNotInPlace] ||
        !reorderedItems[lastReoNotInPlace]
      )
        return false;
      const isForwardReorder =
        referenceItems[firstReoNotInPlace].id ===
        reorderedItems[lastReoNotInPlace].id;

      console.log(
        referenceItems[firstReoNotInPlace],
        referenceItems[lastReoNotInPlace],
      );
      if (isForwardReorder) {
        await rescheduleAdvertisement({
          config: currentSelection.config,
          id: referenceItems[firstReoNotInPlace].advertisement,
          order: reorderedItems[lastReoNotInPlace - 1].order + 0.5,
        });
      } else {
        await rescheduleAdvertisement({
          config: currentSelection.config,
          id: referenceItems[lastReoNotInPlace].advertisement,
          order: referenceItems[firstReoNotInPlace].order - 0.5,
        });
      }
      // const updates: Promise<any>[] = [];
      // for (let i = 0, j = 0; i < reorderedItems.length; i++) {
      //   if (signal.aborted) return false;
      //   const referenceItem = referenceItems[i];
      //   const item = reorderedItems[j] ?? {};
      //   if (item.id === referenceItem.id) {
      //     j++;
      //     continue;
      //   }
      //   if (reorderedItems[j + 1]) {
      //     toast({
      //       title: "If",
      //       description:
      //         "Has j+1 " + j + " : " + i + " : " + reorderedItems.length,
      //     });
      //     const nextItem = reorderedItems[j + 1];
      //     if (nextItem.id === referenceItem.id) {
      //       j += 2;
      //       updates.push(
      //         rescheduleAdvertisement({
      //           config: currentSelection.config,
      //           id: item.advertisement,
      //           order: nextItem.order - 0.5,
      //         })
      //       );
      //     } else {
      //       toast({
      //         title: "Else 1",
      //         description:
      //           "Has j+1 " + j + " : " + i + " : " + reorderedItems.length,
      //       });
      //     }
      //   } else {
      //     toast({
      //       title: "Else 2",
      //       description:
      //         "No j+1 " + j + " : " + i + " : " + reorderedItems.length,
      //     });
      //   }
      // }
      // await Promise.all(updates);

      await flatRotation(currentSelection.config);

      throttledRefreshConfig(currentSelection.config).catch(console.error);

      return true;
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Erreur",
        description:
          "Une erreur est survenue lors de la mise à jour de l'ordre des événements",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    onSuccess(data) {
      if (!data) return;
      toast({
        title: "Ordre mis à jour",
        description: "L'ordre des événements a été mis à jour",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch();
    },
  });
}
