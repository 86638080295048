import Crypto from "crypto-js";
import {
  TimenjoyApiAccount,
  CreateApiAccount,
  CreateWhiteList,
  TimenjoyCompany,
  TimenjoyEvent,
  TimenjoyUser,
  TimenjoyWhiteList,
  UpdateCompany,
  UpdateUser,
  UpdateApiAccount,
  TimenjoyCategory,
  TimenjoyListResponse,
  TimenjoySearchEvent,
} from "@/types/timenjoy.types";
import { blobToBase64 } from "@/utils/blob-to-base64";
import dayjs from "dayjs";
import { getToken } from "./auth.service";
import { AUTH_URL } from "./environment";

// const APISAILS_URL = "https://apisails.timenjoy.fr";
const APISAILS_URL = `${AUTH_URL}/legacy`;

export async function getCategories() {
  const response = await fetch(`${APISAILS_URL}/category?perPage=1000`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
      origin: "https://timenjoy.fr",
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data: { rows: TimenjoyCategory[] } = await response.json();

  return data.rows;
}

interface GetEventParams {
  city?: string | null;
  coordinate?: { lat: number; lng: number };
  distance?: number;
  number?: number;
  categories?: string[];
  inevitable?: boolean;
  page?: number;
  date?: string | Date;
  datefin?: string | Date;
  company?: string;
  search?: string;
  app_secret?: string;
  notcompany?: string;
  blackListed?: boolean;
  notIds?: string[];
  published?: boolean;
  old?: boolean;
}

export function createUrl(params: GetEventParams) {
  const s = new URLSearchParams();
  s.append("page", params.page + "");
  s.append("perPage", params.number + "");
  s.append(
    "populate",
    '["categories","coordinate","occurences","companies","company_events]',
  );
  s.append(
    "order",
    `[[inevitableRate","DESC"],['occurences', 'datefin', 'ASC'],['occurences', 'date', 'ASC'],['datefin','ASC'],['date','ASC']]`,
  );
  s.append("olds", (params.old ?? "false").toString());

  /* Appending the value of the variable notcompany to the url. */
  if (params.notcompany) s.append("notcompany", params.notcompany);
  if (params.company) s.append("company", params.company);
  // if (params.app_secret) s.append("app_secret", params.app_secret);
  if (params.inevitable !== undefined)
    s.append("inevitable", !!params.inevitable + "");
  if (params.coordinate?.lng && params.coordinate?.lat) {
    s.append("lat", params.coordinate.lat + "");
    s.append("lng", params.coordinate.lng + "");
  } else if (params.city) {
    s.append("city", params.city);
    s.append("distance", (params.distance || 50) + "");
  }

  /* Checking if the categories variable is an array and if it is not empty. If it is, it is appending
    the categories to the url. */
  if (Array.isArray(params.categories) && params.categories[0])
    s.append("categories", `["${params.categories.join('","')}"]`);

  /* Taking the date and datefin parameters and converting them to ISO format. */
  let d = new Date(params.date!);
  if (d.toString() != "Invalid Date") {
    if (d.getTime() < Date.now()) d = new Date(Date.now());
    d = new Date(d.getTime() + 7200000);
    s.append("fromDate", d.toISOString().replace(/Z/g, ""));
    let df = new Date(params.datefin!);
    if (df.toString() == "Invalid Date")
      df = new Date(d.getTime() + 31536000000);
    else df = new Date(df.getTime() + 86400000);
    s.append("toDate", df.toISOString().replace(/Z/g, ""));
  }

  /* Adding a where clause to the query string. */
  const where = {
    $and: [] as any[],
  };
  if (params.published !== undefined) {
    where.$and.push({
      published: params.published,
    });
  }
  if (params.search)
    where.$and.push({
      $or: [
        { title: { $iLike: `%${params.search}%` } },
        { place: { $iLike: `%${params.search}%` } },
      ],
    });
  if (params.notIds?.length)
    where.$and.push({
      $not: { id: { $in: params.notIds } },
    });

  s.append("where", JSON.stringify(where));
  /* Checking if the blackListed variable is not undefined. If it is, it will append the blackListed variable
    to the s variable. */
  if (params.blackListed !== undefined)
    s.append("blackListed", params.blackListed + "");
  return "/event?" + s.toString();
}

export async function reportGetEventsError(
  params: GetEventParams,
  response: any,
) {
  await fetch(
    "https://discord.com/api/webhooks/1008061076002316330/UMZCjkmFCMTJ2D7hpE63NYMmw_IkF_wkdzqsHo5HAmTesDgtCbI5yyqiioDA5fQdbuJp",
    {
      method: "POST",
      body: JSON.stringify({
        content: `[reportGetEventsError]
        \`\`\`json
        ${JSON.stringify({ params, response })}
        \`\`\``,
      }),
      headers: {
        "content-type": "application/json",
      },
    },
  );
}
export async function getEvents(params: GetEventParams) {
  try {
    const query = createUrl(params);
    const response = await fetch(`${APISAILS_URL}${query}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        origin: "https://timenjoy.fr",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const data: TimenjoyListResponse<TimenjoyEvent> = await response.json();
    if (!data.rows.length)
      reportGetEventsError(params, {
        msg: "no rows",
        statusText: response.statusText,
      });
    return {
      rows: data.rows.map((e) => ({
        ...e,
        picture: `${APISAILS_URL}/event/${e.id}/getImage/${encodeURIComponent(
          e.title,
        )}.jpeg`,
      })),
      metaData: data.metaData,
    };
  } catch (e) {
    const err = e as Error;
    reportGetEventsError(params, {
      name: err.name,
      stack: err.stack,
    });
    throw e;
  }
}

export async function searchEvents(params: {
  page?: number;
  perPage?: number;
  search: string;
  company?: string;
  categories?: string[];
  olds?: boolean;
}) {
  const query = new URLSearchParams();
  query.set("search", params.search);
  params.page && query.set("page", (params.page - 1).toString());
  params.perPage && query.set("perPage", params.perPage.toString());
  params.company && query.set("company", params.company);
  params.categories && query.set("categories", params.categories.join(","));
  params.olds && query.set("olds", params.olds.toString());
  const response = await fetch(
    `${APISAILS_URL}/searchEvent?${query.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        origin: "https://timenjoy.fr",
      },
    },
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data: TimenjoyListResponse<TimenjoySearchEvent> = await response.json();

  return {
    rows: data.rows.map((e) => ({
      ...e,
      picture: `${APISAILS_URL}/event/${e.id}/getImage/${encodeURIComponent(
        e.title,
      )}.jpeg`,
    })),
    metaData: data.metaData,
  };
}

export async function getEventById(id: string) {
  if (!id) throw new Error("No id provided");
  const response = await fetch(
    `${APISAILS_URL}/event/${id}?populate=["coordinate","categories","tags","company_events","occurences"]`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        origin: "https://timenjoy.fr",
      },
    },
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data: TimenjoyEvent = await response.json();
  const event = {
    ...data,
    picture: `${APISAILS_URL}/event/${data.id}/getImage/${encodeURIComponent(
      data.title,
    )}.jpeg`,
  };
  return event;
}

export async function triggeResetPassword(email: string) {
  const response = await fetch(`${APISAILS_URL}/userpro/passwordlost`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      email,
      sender: "Konnectz",
      domain: window.location.origin + "/",
      endpoint: "auth/recover/<id>",
    }),
  });
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Cet email n'est associé à aucun compte");
  }
  return true;
}

export async function fetchResetPasswordSession(id: string) {
  const response = await fetch(`${APISAILS_URL}/companyusersession/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Ce lien n'est pas valide.");
  }
  const data = (await response.json()) as {
    company_user_id: string;
    expirationDate: string;
  };
  if (Date.now() > new Date(data.expirationDate).getTime()) {
    throw new Error("Ce lien a expiré.");
  }
  return data.company_user_id;
}

export async function resetPassword(
  userId: string,
  password: string,
  password_check: string,
) {
  const response = await fetch(
    `${APISAILS_URL}/userpro/${userId}/resetpassword`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({ password, password_check }),
    },
  );
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Cet email n'est associé à aucun compte");
  }
  return true;
}

export async function getApiAccount(
  app_secret: string,
): Promise<TimenjoyApiAccount> {
  const decodeAppSecret = Crypto.AES.decrypt(
    app_secret,
    process.env.REACT_APP_ENCRYPT_SECRET!,
  ).toString(Crypto.enc.Utf8);

  const { appId } = JSON.parse(decodeAppSecret);

  if (!appId) throw new Error("No appId provided");

  const url = new URL("https://apisails.timenjoy.fr/apiAccount");
  url.searchParams.set("perPage", "1");
  url.searchParams.set("page", "1");
  url.searchParams.append("populate", "white_list");
  url.searchParams.set("where", JSON.stringify({ appId }));

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Origin: "http://admin.timenjoy.fr",
    },
  });
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Couldn't fetch account");
  }
  const accounts = await response.json();
  if (!accounts.rows[0]) {
    throw new Error("Couldn't find account");
  }
  return accounts.rows[0];
}

export async function addUserSendingBlue(user: TimenjoyUser) {
  if (!import.meta.env.PROD) {
    return;
  }
  await fetch(`${APISAILS_URL}/sendinblue/contacts`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ listId: 113, user }),
  });
}

export async function getCompanyById(id: string): Promise<TimenjoyCompany> {
  if (!id) throw new Error("No id provided");
  const response = await fetch(`${APISAILS_URL}/company/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Origin: "http://admin.timenjoy.fr",
    },
  });
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Couldn't fetch company");
  }
  return await response.json();
}

export async function getCompanyNameById(id: string): Promise<string> {
  const company = await getCompanyById(id);
  return company.name.trim();
}

export async function getUser(id: string) {
  if (!id) throw new Error("No user id provided");
  const response = await fetch(`${APISAILS_URL}/userpro/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Couldn't fetch apiAccounts");
  }
  return (await response.json()) as TimenjoyUser;
}

export async function getApiAccountByCompanyId(id: string) {
  if (!id?.length) throw new Error("No company id provided");
  const response = await fetch(
    `${APISAILS_URL}/apiAccount?where={"company_id":"${id}"}&populate=["white_list"]&page=1&perPage=100`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Couldn't fetch apiAccounts");
  }
  const data = await response.json();
  const apiAccounts = data.rows as TimenjoyApiAccount[];
  return (
    apiAccounts
      // .filter((account) => {
      //   return (
      //     !account.name.startsWith("Immo ") &&
      //     !account.name.startsWith("Emploi ") &&
      //     !account.name.startsWith("Smart Immo -") &&
      //     !account.name.startsWith("Smart Emploi -")
      //   );
      // })
      .map((account) => {
        account.white_list?.sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return 1;
          }
          if (a.createdAt < b.createdAt) {
            return -1;
          }
          return 0;
        });
        return account;
      })
  );
}

export async function updateUser(user_id: string, payload: UpdateUser) {
  const response = await fetch(`${APISAILS_URL}/userpro/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = (await response.json()) as TimenjoyUser;
  return data;
}

export async function updateCompany(
  company_id: string,
  payload: UpdateCompany,
) {
  const response = await fetch(`${APISAILS_URL}/company/${company_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = (await response.json()) as TimenjoyCompany;
  return data;
}

export async function getWhiteListByApiAccount(id: string) {
  const response = await fetch(
    `${APISAILS_URL}/whitelist?where={"api_account_id":"${id}"}&page=1&perPage=100`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
  if (!response.ok) {
    console.error(response.status, await response.text());
    throw new Error("Couldn't fetch whiteList");
  }
  const data = await response.json();
  const whiteList = data.rows as TimenjoyWhiteList[];
  return whiteList;
}
export async function createWhiteList(payload: CreateWhiteList) {
  const response = await fetch(`${APISAILS_URL}/whitelist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ ...payload, active: true }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = (await response.json()) as TimenjoyWhiteList;

  return data;
}

export async function createApiAccount(
  payload: CreateApiAccount & { url: string },
) {
  const { url, name, company_id } = payload;
  const response = await fetch(`${APISAILS_URL}/apiAccount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      name: name + " " + company_id,
      company_id,
      password: crypto.randomUUID(),
      active: true,
      isFreemium: true,
      type: "customer_api",
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data: TimenjoyApiAccount = await response.json();

  data.white_list ??= [];
  for (const whitelistUrl of new Set(
    [url, window.location.origin].map((u) => new URL(u).origin),
  )) {
    const whitelist = await createWhiteList({
      api_account_id: data.id,
      url: new URL(whitelistUrl).origin,
    }).catch(async () => {
      const fetched = await getWhiteListByApiAccount(data.id);
      const exists = fetched.find((w) => w.url === whitelistUrl);
      if (exists) {
        return exists;
      }
      throw new Error("Couldn't create whitelist");
    });

    data.white_list.push(whitelist);
  }

  try {
    const res2 = await fetch(`${APISAILS_URL}/apiAccount/${data.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        active: true,
        type: "customer_api",
      }),
    });

    data.active = res2.ok;
  } catch (e) {
    console.error(e);
  }

  return data;
}

export async function updateApiAccount(
  id: string,
  { url, ...payload }: Partial<UpdateApiAccount> & { url: string },
) {
  let data: TimenjoyApiAccount;
  if (payload.name ?? payload.company_id) {
    const response = await fetch(`${APISAILS_URL}/apiAccount/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    data = await response.json();
  } else {
    const response = await fetch(`${APISAILS_URL}/apiAccount/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    data = await response.json();
  }

  data.white_list ??= [];
  if (!data.white_list.find((w) => w.url === url)) {
    const whitelist = await createWhiteList({
      api_account_id: data.id,
      url: new URL(url).origin,
    }).catch(async () => {
      const fetched = await getWhiteListByApiAccount(data.id);
      const exists = fetched.find((w) => w.url === url);
      if (exists) {
        return exists;
      }
      throw new Error("Couldn't create whitelist");
    });

    data.white_list.push(whitelist);
  }

  return data;
}

export async function deleteApiAccount(id: string) {
  const response = await fetch(`${APISAILS_URL}/apiAccount/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export async function addEventCategory(eventId: string, categoryId: string) {
  const response = await fetch(`${APISAILS_URL}/eventcategory`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      event_id: eventId,
      category_id: categoryId,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export async function deleteEventCategory(eventCategoryId: string) {
  const response = await fetch(
    `${APISAILS_URL}/eventcategory/${eventCategoryId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export async function deleteEvent(eventId: string) {
  const response = await fetch(`${APISAILS_URL}/deleteEvent/${eventId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export interface IUpdateCoordinatesPayload {
  lat: number;
  lng: number;
  address: string;
}

export async function updateCoordinates(
  coordinateId: string,
  payload: IUpdateCoordinatesPayload,
) {
  const response = await fetch(`${APISAILS_URL}/coordinate/${coordinateId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export async function getPictureOfEvent(eventId: string) {
  const response = await fetch(
    `${APISAILS_URL}/picture?where={"event":"${eventId}"}&filter=["id"]`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = await response.json();
  return data.rows[0].id.toString(); // .toString() to make it throw if it's somehow undefined
}

export async function updatePicture(pictureId: string, pictureUrl: string) {
  const file = pictureUrl.startsWith("data:")
    ? pictureUrl
    : await fetch(pictureUrl)
        .then((response) => response.blob())
        .then(blobToBase64);
  const response = await fetch(`${APISAILS_URL}/picture/${pictureId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      pictureId,
      file,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export async function addEventToCompany(
  companyId: string,
  userId: string,
  eventId: string,
) {
  const response = await fetch(`${APISAILS_URL}/companyEvent`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      user_id: userId,
      company_id: companyId,
      event_id: eventId,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export interface CreateEventPayload {
  title: string;
  place: string;
  description: string;
  date: string;
  datefin: string;
  url_ticketing: string;
  published: boolean;
}

export async function createEvent(payload: CreateEventPayload) {
  const response = await fetch(`${APISAILS_URL}/event`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      ...payload,
      origin: "MyTimenjoy",
      chatbot: true,
      // I HATE THIS SHIT
      date: dayjs(payload.date)
        .add(dayjs(payload.date).utcOffset(), "minutes")
        .toISOString(),
      datefin: dayjs(payload.datefin)
        .add(dayjs(payload.datefin).utcOffset(), "minutes")
        .toISOString(),
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = (await response.json()) as TimenjoyEvent;
  return data;
}

export async function addPicture(eventId: string, pictureUrl: string) {
  const file = pictureUrl.startsWith("data:")
    ? pictureUrl
    : await fetch(pictureUrl)
        .then((response) => response.blob())
        .then(blobToBase64);
  const response = await fetch(`${APISAILS_URL}/picture`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      event: eventId,
      file,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.ok;
}

export async function addCoordinates(
  eventId: string,
  payload: IUpdateCoordinatesPayload,
) {
  const response = await fetch(`${APISAILS_URL}/coordinate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      ...payload,
      event: eventId,
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = (await response.json()) as { id: string };
  return data.id;
}

export interface UpdateEventPayload
  extends Partial<
    Omit<
      TimenjoyEvent,
      | "categories"
      | "tags"
      | "company_events"
      | "coordinate"
      | "occurences"
      | "nextDate"
      | "nextDatefin"
      | "picture"
    >
  > {}

export async function updateEvent(id: string, payload: UpdateEventPayload) {
  // I HATE THIS SHIT
  if (payload.date) {
    payload.date = dayjs(payload.date)
      .add(dayjs(payload.date).utcOffset(), "minutes")
      .toISOString();
  }
  // I HATE THIS SHIT
  if (payload.datefin) {
    payload.datefin = dayjs(payload.datefin)
      .add(dayjs(payload.datefin).utcOffset(), "minutes")
      .toISOString();
  }
  const response = await fetch(`${APISAILS_URL}/event/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = await response.json();
  return data;
}

export async function duplicateEvent(id: string, userId: string) {
  const event = await getEventById(id);
  let title = event.title + " - copie";
  const createdEvent = await createEvent({
    title,
    description: event.description?.trim() || "Pas de description",
    place: event.place,
    date: event.date,
    datefin: event.datefin,
    url_ticketing: event.url_ticketing,
    published: event.published,
  });

  const companyId =
    event.companies?.[0]?.id ?? event.company_events?.[0]?.company_id;
  await addEventToCompany(companyId, userId, createdEvent.id);

  const categoriesCreation = Promise.allSettled(
    event.categories.map(async (category) => {
      return addEventCategory(createdEvent.id, category.id);
    }),
  );

  const coordinatesCreation = addCoordinates(createdEvent.id, {
    lat: event.coordinate.lat,
    lng: event.coordinate.lng,
    address: event.coordinate.address,
  });

  const pictureCreation = addPicture(createdEvent.id, event.picture!);

  const result = await Promise.allSettled([
    categoriesCreation,
    coordinatesCreation,
    pictureCreation,
  ]);

  if (result.some((result) => result.status === "rejected")) {
    throw new Error("Error while updating event");
  }

  return {
    ...createdEvent,
    picture: event.picture,
  };
}

export class LoginEmailNotFound extends Error {
  constructor() {
    super("Login email not found");
  }
}
export class LoginInvalidPassword extends Error {
  constructor() {
    super("Invalid password");
  }
}
