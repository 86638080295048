import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useR from "../hooks/useR";

export function withUser<T extends Record<string, any>>(
  shouldBeComplete: boolean,
  Component: React.FC<T>
) {
  return function WithCompleteUser(props: T) {
    const navigate = useNavigate();
    const location = useLocation()
    const { user, company, apiAccounts } = useR();
    const [isUserComplete, setIsUserComplete] = React.useState(
      !shouldBeComplete
    );
    React.useEffect(() => {
      if (!user) {
        return navigate("/auth/login?redirect=" + location.pathname + location.search);
      }
      if (shouldBeComplete) {
        if (
          !user.firstname ||
          !company ||
          company.name === user.email.replace(/\W+/gim, "_") ||
          !apiAccounts?.length
        ) {
          return navigate("/onboarding", { replace: true });
        }
        setIsUserComplete(true);
      } else {
        if (
          user.firstname &&
          company &&
          company.name !== user.email.replace(/\W+/gim, "_") &&
          apiAccounts?.length
        ) {
          return navigate("/app");
        }
        setIsUserComplete(false);
      }
    }, [user, company, apiAccounts]);
    if (isUserComplete !== shouldBeComplete) return null;
    return <Component {...props} />;
  };
}
export const withCompleteUser = withUser.bind(null, true);
export const withIncompleteUser = withUser.bind(null, false);

type Props = {
  children: React.ReactNode;
};

export const CompleteUserGuard: React.FC<Props> = withCompleteUser(
  ({ children }) => <>{children}</>
);

export const IncompleteUserGuard: React.FC<Props> = withIncompleteUser(
  ({ children }) => <>{children}</>
);
