import Button from "@/components/button";
import CheckboxInput from "@/components/inputs/checkbox";
import EmailInput from "@/components/inputs/email";
import useAuth from "@/hooks/useAuth";
import useInput from "@/hooks/useInput";
import useR from "@/hooks/useR";
import useValidation from "@/hooks/useValidation";
import { inviteUser, listCompanyPolicies } from "@/service/auth.service";
import { useToast } from "@chakra-ui/react";
import { FloatingPortal } from "@floating-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { z } from "zod";

export interface IInviteUserModalProps {
  open: boolean;
  setOpen(open: boolean): void;
}

export default function InviteUserModal({
  open,
  setOpen,
}: IInviteUserModalProps) {
  const toast = useToast();
  const email = useInput("");
  const [valid, validate] = useValidation(
    z.object({
      email: z.string().email(),
      policyId: z.number().min(1),
    }),
  );
  const [checkedPolicy, setCheckedPolicy] = useState<number>();
  useEffect(() => {
    validate(
      {
        email: email.value,
        policyId: checkedPolicy,
      },
      true,
    );
  }, [email.value, checkedPolicy]);
  const auth = useAuth();
  const { client } = useR();
  const companyPolicies = useQuery({
    queryKey: ["companyPolicies", auth?.user?.companyId],
    queryFn: async () => {
      if (!auth?.user?.companyId) return Promise.reject();
      return listCompanyPolicies(auth.user.companyId);
    },
    enabled: !!auth?.user?.companyId,
  });
  const inviteMutation = useMutation({
    async mutationFn({
      email,
      policyId,
      companyId,
    }: {
      email: string;
      policyId: number;
      companyId: number;
    }) {
      return inviteUser(companyId, email, policyId);
    },
    onSuccess({ companyId }) {
      client.invalidateQueries(["companyInvites", companyId]);
      client.refetchQueries(["companyInvites", companyId]);
      toast({
        title: "Invitation envoyée",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
  });
  function close() {
    setOpen(false);
  }

  if (!open) return null;

  return (
    <FloatingPortal preserveTabOrder>
      <div
        className="bg-background_dark/40 fixed inset-0 z-40 flex cursor-default items-center justify-center backdrop-blur"
        onClick={close}
      >
        <div
          className="border-background_light bg-konnectz_background inline-flex w-full max-w-lg flex-col items-start justify-start gap-6 rounded-2xl border px-6 py-8"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex w-full items-center justify-between">
            <div className="text-xl font-semibold tracking-wide text-white">
              Inviter un utilisateur
            </div>
          </div>

          <div className="w-full space-y-2">
            <h4 className="flex items-center text-lg font-semibold tracking-wide text-white">
              Adresse email
            </h4>
            <EmailInput {...email} />
          </div>
          <div className="w-full">
            <h4 className="mb-2 flex items-center text-lg font-semibold tracking-wide text-white">
              Niveau d'accès
            </h4>
            {companyPolicies.data?.data.map((policy) => (
              <div
                key={policy.id}
                className={clsx(
                  "flex cursor-pointer items-start rounded-md px-4 py-3.5 pt-2.5 text-white",
                  checkedPolicy === policy.id && "bg-slate-400/30",
                )}
                onClick={() => setCheckedPolicy(policy.id)}
              >
                <CheckboxInput checked={checkedPolicy === policy.id} />
                <div className="pt-1">
                  <h6 className="text-sm font-semibold">{policy.name}</h6>
                  <p className="font-regular text-sm">
                    {descriptionForPolicy[policy.name]}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex w-full justify-end">
            <Button
              className="px-4 py-2.5"
              disabled={inviteMutation.isLoading || !valid}
              onClick={async () => {
                if (!valid)
                  return alert("Veuillez saisir une adresse email valide");
                await inviteMutation.mutateAsync({
                  email: email.value,
                  policyId: checkedPolicy!,
                  companyId: auth?.user?.companyId!,
                });
                close();
              }}
            >
              Envoyer l'invitation
            </Button>
          </div>
        </div>
      </div>
    </FloatingPortal>
  );
}

const descriptionForPolicy: Record<string, string> = {
  Administrateur:
    "Accès complet à l'ensemble des fonctionnalités de l'application",
  Comptable: "Accès à la gestion des factures et des paiements",
  Créateur: "Accès à la création d'événements et la planification de diffusion",
};
