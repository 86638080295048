import NotFound from "@/pages/404";
import React from "react";
import { Route, Routes } from "react-router-dom";

type WithChildren = {
  children: React.ReactNode | React.ReactNode[];
};
type SubRoutesParams = (
  params: {
    path?: string;
    wrapper?: React.FC<WithChildren> | React.FC<WithChildren>[];
  },
  ...children: React.ReactNode[]
) => React.ReactElement;

const SubRoutes: SubRoutesParams = ({ path, wrapper }, ...children) => {
  const wrappers = Array.isArray(wrapper) ? wrapper : wrapper ? [wrapper] : [];
  const Wrapper = wrappers.reduceRight((Acc, Wrapper) => {
    return ({ children }: WithChildren) => (
      <Wrapper>
        <Acc>{children}</Acc>
      </Wrapper>
    );
  }, Routes as React.FC<WithChildren>);
  return (
    <Route
      path={path ? `${path}/*`.replaceAll("//", "/") : "/*"}
      element={
        <Wrapper>
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child as React.ReactElement, {
              key: index + (path || ""),
            });
          })}
          <Route path="*" element={<NotFound />} />
        </Wrapper>
      }
    />
  );
};

export default SubRoutes;
