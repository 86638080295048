import Button from "@/components/button";
import TextInput from "@/components/inputs/text";
import useInput from "@/hooks/useInput";
import useR from "@/hooks/useR";
import React, { useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addUserSendingBlue, updateCompany } from "@/service/timenjoy.service";
import { UpdateCompany } from "@/types/timenjoy.types";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import WebsiteInput from "@/components/inputs/website";
import Select from "@/components/inputs/select";
import { CompanyActivityArea, schema } from "./schema";
import useValidation from "@/hooks/useValidation";
import { captureException } from "@/service/exceptions";

const OnboardingCompany: React.FC = () => {
  const queryClient = useQueryClient();
  const { user, company } = useR();
  const toast = useToast();
  const navigate = useNavigate();
  const name = useInput(
    typeof company?.name !== "string"
      ? ""
      : company.name === user?.email.replace(/\W+/gim, "_")
        ? ""
        : company?.name
  );
  const city = useInput(company?.zip_code || "");
  const type = useInput<HTMLSelectElement>(
    company?.activity_area || CompanyActivityArea[0]
  );
  const website = useInput(company?.web || "");
  const [isValid, validate] = useValidation(schema);
  const mutation = useMutation({
    async mutationFn(payload: UpdateCompany) {
      const companyResponse = await updateCompany(company!.id, payload);

      await addUserSendingBlue(user!).catch((e) => {
        console.error(e);
      });

      return companyResponse;
    },
    onSuccess(data) {
      queryClient.setQueryData(["company"], data);
      navigate("/onboarding/pricing");
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      console.error(error);
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    validate(
      {
        zip_code: city.value,
        name: name.value,
        activity_area: type.value,
        web: website.value,
        phone: user?.phone as string,
        email: user?.email as string,
      },
      true
    );
  }, [company, city, name, type, website, user]);

  function submit(e?: React.FormEvent) {
    e?.preventDefault();
    const payload = {
      zip_code: city.value,
      name: name.value,
      activity_area: type.value,
      web: website.value,
      phone: user?.phone as string,
      email: user?.email as string,
    };
    if (validate(payload)) mutation.mutate(payload);
  }

  return (
    <div className="max-w-2xl m-auto w-full flex flex-col">
      <h3 className="text-2xl font-bold sm:text-3xl text-title">
        Mon entreprise
      </h3>
      <p className="mt-4 max-w-lg text-subtitle">
        Super {user?.firstname}, maintenant passons aux détails de votre
        entreprise. Ces informations nous aiderons à personnaliser votre
        expérience et à mieux comprendre vos besoins.
      </p>
      <form
        onSubmit={submit}
        className="max-w-2xl grid grid-cols-2 gap-x-8 gap-y-6 mt-8 text-subtitle"
      >
        <div className="space-y-5">
          <label className="font-medium">Nom</label>
          <TextInput {...name} placeholder="Nom de l'entreprise" />
        </div>
        <div className="space-y-5">
          <label className="font-medium">Secteur d’activité</label>
          <Select
            {...type}
            options={CompanyActivityArea}
            defaultValue={CompanyActivityArea[0]}
          />
        </div>
        <div className="space-y-5">
          <label className="font-medium">Code postal</label>
          <TextInput {...city} placeholder="Code postal" />
        </div>
        <div className="space-y-5">
          <label className="font-medium">Site web</label>
          <WebsiteInput {...website} />
        </div>
      </form>
      <Button
        className="self-end ml-auto !mt-9 flex justify-center items-center gap-2 font-semibold"
        children={[
          <div />,
          <span>Suivant</span>,
          <ChevronRightIcon className="w-5 h-5" />,
        ]}
        disabled={!isValid}
        clickThroughDisabled
        onClick={() => submit()}
      />
    </div>
  );
};

export default OnboardingCompany;
