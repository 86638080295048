import PremiumCard from "@/components/premium-card";
import { usePricing } from "@/hooks/usePricing";

const Premium = () => {
  const pricing = usePricing();
  return (
    <section className="w-full min-h-full noscrollbar overflow-scroll flex flex-col justify-center items-center pb-12 px-16 bg-radial from-konnectz_accent/10 to-50% to-transparent">
      <div className="max-w-xl mx-auto text-center">
        <h2 className="text-title text-3xl font-semibold sm:text-4xl">
          Profitez de la pleine puissance de Konnectz
        </h2>
        <p className="text-subtitle mt-6">
          Accédez aux options de diffusion et aux fonctionnalités de publication
          et de programmation avancées.
        </p>
      </div>
      <div className="mt-12 justify-center gap-6 sm:grid lg:grid-cols-2 xl:grid-cols-3 sm:space-y-0 mx-auto sm:max-w-3xl lg:max-w-5xl duration-1000 delay-300 opacity-1">
        {pricing.data?.map((plan) => <PremiumCard plan={plan} key={plan.name} />)}
      </div>
    </section>
  );
};

export default Premium;
