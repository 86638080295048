import { z } from "zod";

export const schema = z.strictObject({
    firstname: z
        .string({
            errorMap() {
                return {
                    message: "Le prénom doit contenir au moins 2 caractères.",
                };
            },
        })
        .min(2)
        .trim(),
    lastname: z
        .string({
            errorMap() {
                return {
                    message: "Le nom doit contenir au moins 2 caractères.",
                };
            },
        })
        .min(2)
        .trim(),
    phone: z
        .string({
            errorMap() {
                return {
                    message: "Vous devez saisir un numéro de téléphone valide.",
                };
            },
        })
        .trim()
        .regex(/^(0|\+\d+)[0-9 -]{9,}$/),
});