import { useSchedules } from "@/hooks/useSchedules";
import {
  IRescheduleAdvertisement,
  rescheduleAdvertisement,
  throttledRefreshConfig,
} from "@/service/konnectz.service";
import { RotationStrategy, Schedule } from "@/types/konnectz.types";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";

export interface ISponsorScheduleMutation
  extends Omit<IRescheduleAdvertisement, "from" | "to" | "rotation_strategy"> {
  rotation_strategy: RotationStrategy;
}

export function useSponsorScheduleMutation() {
  const toast = useToast();
  const [, refetch] = useSchedules();
  return useMutation<Schedule, unknown, ISponsorScheduleMutation>({
    mutationFn: rescheduleAdvertisement,
    onSuccess(data) {
      throttledRefreshConfig(data.config).catch(console.error);
      toast({
        title: "Evénement mis à jour",
        description:
          "Les dates de planification de l'événement ont été mises à jour",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Erreur",
        description:
          "Une erreur est survenue lors de la suppression de l'événement",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
    onSettled: refetch,
  });
}
