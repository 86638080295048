import Button from "@/components/button";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { acceptInvite, getInviteForUser, getSession } from "@/service/auth.service";

const OnboardingInvite: React.FC = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const invites = useQuery({
    queryKey: ["userInvites"],
    queryFn: getInviteForUser,
  });

  const acceptInviteMutation = useMutation({
    async mutationFn({ id }: { id: number }) {
      return acceptInvite(id)
    },
    async onSuccess() {
      const { user, company, apiAccounts, auth } = await getSession();
      // clearQueryClient(queryClient, { keepBrowserToken: false });
      queryClient.setQueryData(["user"], user);
      queryClient.setQueryData(["company"], company);
      queryClient.setQueryData(["userID"], user.id);
      queryClient.setQueryData(
        ["apiAccounts"],
        apiAccounts?.map((a) => a.id) ?? []
      );
      apiAccounts?.forEach((a) => {
        queryClient.setQueryData(["apiAccount", a.id], a);
      });
      queryClient.setQueryData(["auth"], auth);
      navigate('/app')
      toast({
        title: "Invitation acceptée",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    async onError(error) {
      console.error(error);
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  })

  React.useEffect(() => {
    if (acceptInviteMutation.isSuccess) return;
    if (invites.data?.data.length === 0) {
      navigate('/onboarding/company')
    }
  }, [invites.data?.data.length])

  if (invites.isLoading || !invites.data?.data.length) return <>Loading</>

  return (
    <div className="max-w-2xl m-auto w-full flex flex-col">
      <h3 className="text-2xl font-bold sm:text-3xl text-title">
        Invitation
      </h3>
      <p className="my-4 max-w-lg text-subtitle">
        Vous avez été invité à rejoindre une entreprise. Souhaitez-vous accepter l'invitation ?
      </p>
      {invites.data?.data.map((invite) => (
        <div className="mt-4 w-full bg-slate-300/30 py-4 px-5 rounded-xl">
          <div className="flex items-center gap-4 justify-between">
            <div>
              <p className="text-title font-bold">{invite.company?.name}</p>
              <p className="text-subtitle">{invite.inviter ? `Par ${invite.inviter.email}` : ""}</p>
            </div>
            <Button
              onClick={() => {
                acceptInviteMutation.mutate({ id: invite.id })
              }}
              disabled={acceptInviteMutation.isLoading}
              className="shrink-0"
            >
              Accepter
            </Button>
          </div>
        </div>
      ))}
      <Button
        onClick={() => {
          navigate('/onboarding/company')
        }}
        disabled={acceptInviteMutation.isLoading}
        className="shrink-0 w-fit mt-8"
        variant="outline"
      >
        Refuser
      </Button>
    </div>
  );
};

export default OnboardingInvite;
