import Button from "@/components/button";
import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Title, Text, Grid, Col, TabPanel, AreaChart, TabPanels, TabGroup, TabList, Tab, Metric, BarList, Select, SelectItem } from "@tremor/react";
import useWidgetAnalytics from "@/hooks/useWidgetAnalytics";
import { numberFormatter } from "@/utils/numbers";
import { Spinner } from "@chakra-ui/react";

export interface IWidgetStatsProps {
}

const durationFormatter = (value: number) => {
    const minutes = Math.floor(value / 60);
    const seconds = +value.toFixed(0) - minutes * 60;
    return `${minutes}m ${seconds}s`;
}


function sumArray<K extends string, T extends Record<K, number>>(array: T[], metric: K) {
    return array.reduce((accumulator, currentValue) => accumulator + currentValue[metric], 0);
}

export default function WidgetStats(props: IWidgetStatsProps) {
    const { permissions: { isPremium } } = useR();
    const [query, setQuery] = useSearchParams()
    const { policy } = useAuth()
    const navigation = useNavigate();
    React.useEffect(() => {
        if (!isPremium || !policy?.canConsultStatistics) {
            navigation("/app");
        }
    }, [isPremium, policy?.canConsultStatistics]);
    const { data, isLoading, isError } = useWidgetAnalytics(query.get("time") ?? "14_days");

    if (isLoading)
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <Title className="text-4xl font-semibold text-title">
                    Chargement
                </Title>
                <Spinner />
            </div>
        )
    if (isError)
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <Title className="text-4xl font-semibold text-title">
                    Erreur
                </Title>
                <Text className="text-lg text-subtitle">
                    Une erreur est survenue lors du chargement des données.
                </Text>
                <Button className="mt-4" onClick={() => window.location.reload()}>
                    Recharger
                </Button>
            </div>
        )
    if (!data)
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <Title className="text-4xl font-semibold text-title">
                    Pas de données
                </Title>
                <Text className="text-lg text-subtitle">
                    Vous n'avez pas encore de données.
                </Text>
            </div>
        )

    return (
        <div className="h-full flex flex-col justify-start pt-2 pb-6 overflow-scroll noscrollbar px-4 -mx-4">
            <div className="w-full flex justify-start relative gap-6 mb-2">
                <Select defaultValue="14_days" value={query.get("time") ?? undefined} onValueChange={e => {
                    setQuery(prev => {
                        if (!e) prev.set("time", "14_days")
                        else prev.set("time", e)
                        return prev
                    })
                }} className="w-full max-w-[calc(33%_-_12px)]">
                    <SelectItem value="today">Aujourd'hui</SelectItem>
                    <SelectItem value="yesterday">Hier</SelectItem>
                    <SelectItem value="7_days">7 dernier jours</SelectItem>
                    <SelectItem value="14_days">14 dernier jours</SelectItem>
                    <SelectItem value="30_days">30 dernier jours</SelectItem>
                </Select>
            </div>
            <Grid numItemsLg={6} className="gap-6">
                {/* Main section */}
                <Col numColSpanLg={4}>
                    <Card className="h-full p-0">
                        <TabGroup>
                            <TabList>
                                <Tab className="py-1 px-4 sm:p-6 text-left border-b-2 border-b-transparent">
                                    <p className="text-sm sm:text-base">Impressions</p>
                                    <Metric className="mt-2 text-inherit">
                                        {numberFormatter(sumArray(data.timeSerie, "impressions"))}
                                    </Metric>
                                </Tab>
                                <Tab className="py-1 px-4 sm:p-6 text-left border-b-2 border-b-transparent">
                                    <p className="text-sm sm:text-base">Clics</p>
                                    <Metric className="mt-2 text-inherit">
                                        {numberFormatter(sumArray(data.timeSerie, "clics"))}
                                    </Metric>
                                </Tab>
                                <Tab className="py-1 px-4 sm:p-6 text-left border-b-2 border-b-transparent">
                                    <p className="text-sm sm:text-base">Interactions</p>
                                    <Metric className="mt-2 text-inherit">
                                        {numberFormatter(sumArray(data.timeSerie, "interactions"))}
                                    </Metric>
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel className="p-6">
                                    <AreaChart
                                        className="h-64 mt-10"
                                        data={data.timeSerie}
                                        index="hour"
                                        categories={["impressions"]}
                                        colors={["fuchsia"]}
                                        valueFormatter={numberFormatter}
                                        showLegend={false}
                                        yAxisWidth={50}
                                        showAnimation
                                    />
                                </TabPanel>
                                <TabPanel className="p-6">
                                    <AreaChart
                                        className="h-64 mt-10"
                                        data={data.timeSerie}
                                        index="hour"
                                        categories={["clics"]}
                                        colors={["fuchsia"]}
                                        valueFormatter={numberFormatter}
                                        showLegend={false}
                                        yAxisWidth={50}
                                        showAnimation
                                    />
                                </TabPanel>
                                <TabPanel className="p-6">
                                    <AreaChart
                                        className="h-64 mt-10"
                                        data={data.timeSerie}
                                        index="hour"
                                        categories={["interactions"]}
                                        colors={["fuchsia"]}
                                        valueFormatter={numberFormatter}
                                        showLegend={false}
                                        yAxisWidth={50}
                                        showAnimation
                                    />
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                    </Card>
                </Col>

                {/* KPI sidebar */}
                <Col numColSpanLg={2}>
                    <div className="space-y-6">
                        <Card>
                            <Title>CTR</Title>
                            <Metric>{numberFormatter(100 * sumArray(data.timeSerie, "clics") / sumArray(data.timeSerie, "impressions"))} %</Metric>
                        </Card>
                        <Card>
                            <Title>Durée de session</Title>
                            <Metric>-</Metric>
                            {/* <Metric>{durationFormatter(data.average_duration || 0)}</Metric> */}
                        </Card>
                        <Card>
                            <Title>Pages</Title>
                            {data.impression_per_origin.length ? (
                                <BarList
                                    data={data.impression_per_origin.slice(0, 3).map((item) => ({
                                        value: item.impressions,
                                        name: item.origin.replace("https://", "").replace("http://", "").replace("www.", "")
                                    }))}
                                    valueFormatter={numberFormatter}
                                    className="mt-2 h-32"
                                />
                            ) : (
                                <Text className="mt-2 pt-4 h-32">Vous n'avez pas encore assez de données</Text>
                            )}
                        </Card>
                    </div>
                </Col>
            </Grid>
        </div>
    )
}
