import { usePermission } from "@/hooks/usePermissions";
import useR from "@/hooks/useR";
import { useSchedules } from "@/hooks/useSchedules";
import {
  scheduleAdvertisement,
  throttledRefreshConfig,
} from "@/service/konnectz.service";
import { RotationStrategy } from "@/types/konnectz.types";
import { batchArray } from "@/utils/batch";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";
import { PromisePool } from "@supercharge/promise-pool";

export interface IAddEventToSelectionProps {
  config?: string;
  id: string;
  from: number;
  to: number;
  filler: boolean;
}

export function useAddToSelection() {
  const { currentSelection, currentApiAccount } = useR();
  const { canUseSelection } = usePermission();
  const toast = useToast();
  const [, refetch] = useSchedules();
  return useMutation({
    mutationFn: async (data: IAddEventToSelectionProps[]) => {
      if (!currentSelection || !currentApiAccount)
        throw new Error("No selection selected");
      if (!canUseSelection) {
        toast({
          title: "Fonctionnalité réservée aux comptes premium",
          description:
            "Vous pouvez créer un événement en vous abonnant à Konnectz Premium.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        throw new Error("You need to be premium to use this feature");
      }
      const { errors } = await PromisePool.withConcurrency(10)
        .withTaskTimeout(10_000)
        .for(data)
        .process(
          async ({
            id,
            from,
            to,
            filler,
            config = currentSelection.config,
          }) => {
            return scheduleAdvertisement(
              config,
              id,
              from,
              to,
              filler ? RotationStrategy.FILLER : RotationStrategy.ROTATE
            );
          }
        );
      throttledRefreshConfig(currentSelection.config).catch(console.error);
      if (errors.length > 0) {
        throw new Error(
          "Une erreur est survenue lors de l'ajout de l'événement à la sélection"
        );
      }
      return true;
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
    },
    onSettled: refetch,
  });
}
