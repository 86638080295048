import Button from "@/components/button";
import { useBuildingEvent } from "@/hooks/useBuildingEvent";
import { useMainWorkPlaceSize } from "@/hooks/useMainWorkPlaceSize";
import useR from "@/hooks/useR";
import AddToSelectionModal from "@/modals/add-to-selection";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface INewEventFinishPublishedProps {}

const NewEventFinishPublished: React.FunctionComponent<
  INewEventFinishPublishedProps
> = (props) => {
  const { apiAccounts } = useR();
  const [openAddToSelectionModal, setOpenAddToSelectionModal] =
    React.useState(false);
  const size = useMainWorkPlaceSize();
  const event = useBuildingEvent();
  const [s] = useSearchParams();
  const navigate = useNavigate();
  function addToSelection() {
    setOpenAddToSelectionModal(true);
  }
  React.useEffect(() => {
    if (s.get("lastStep") !== "true") navigate("/app/events");
  }, [s]);
  if (s.get("lastStep") !== "true") return null;
  return (
    <div
      className="text-white w-full h-full flex flex-col justify-center items-center gap-1"
      style={size}
    >
      <h1 className="text-2xl font-semibold tracking-wide">
        Votre événement a bien été publié !
      </h1>
      <p className="text-md font-regular mb-4 ">
        Vous pouvez le retrouver dans la liste de vos événements.
      </p>
      {apiAccounts.length === 1 ? (
        <Button variant="tertiary" onClick={addToSelection}>
          Ajouter à ma sélection
        </Button>
      ) : (
        <Button variant="tertiary" onClick={addToSelection}>
          Ajouter à mes sélections
        </Button>
      )}
      <AddToSelectionModal
        open={openAddToSelectionModal}
        skip={() => setOpenAddToSelectionModal(false)}
        next={() => {
          setOpenAddToSelectionModal(false);
          navigate("/app/selection");
        }}
        events={[
          {
            id: event.id!,
            title: event.title!,
            date: event.date!,
            picture: event.picture!,
          },
        ]}
      />
    </div>
  );
};

export default NewEventFinishPublished;
