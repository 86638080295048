import { ADVERTISEMENT_TYPE, useBuildingEvent } from "@/hooks/useBuildingEvent";
import { useMainWorkPlaceSize } from "@/hooks/useMainWorkPlaceSize";
import { usePermission } from "@/hooks/usePermissions";
import { cn } from "@/lib/utils";
import { LockClosedIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface IEventBuilderProps {}

const EventBuilder: React.FunctionComponent<IEventBuilderProps> = (props) => {
  const { id, update } = useBuildingEvent();
  const navigate = useNavigate();
  const [s] = useSearchParams();
  const { eventsEnabled, immoEnabled, jobsEnabled } = usePermission();
  const setAdvertisementType = (type: ADVERTISEMENT_TYPE) => {
    update({ type });
    navigate(`/app/events/new/base`);
  };
  const size = useMainWorkPlaceSize();
  React.useEffect(() => {
    if (id) {
      navigate(`/app/events/${id}/base?lastStep=${s.get("lastStep")}`, {
        replace: true,
      });
      return;
    }
  }, []);
  if (id) return null;
  return (
    <section
      style={size}
      className="noscrollbar bg-radial from-konnectz_accent/10 flex min-h-full w-full flex-col items-center justify-center overflow-scroll to-transparent to-50% px-16 pb-12"
    >
      <div className="mx-auto max-w-xl text-center">
        <h2 className="text-title text-3xl font-semibold sm:text-4xl">
          Créer une annonce
        </h2>
        <p className="text-subtitle mt-6">
          Sélectionnez le type d'annonce que vous souhaitez créer.
        </p>
      </div>
      <div className="mx-auto mt-12 grid w-full max-w-3xl grid-cols-3 gap-4 opacity-90 delay-300 duration-1000">
        <AdButon
          color="bg-greenenjoy_dark"
          label="Événement"
          type={ADVERTISEMENT_TYPE.EVENT}
          setAdvertisementType={setAdvertisementType}
          enabled={eventsEnabled}
        />
        <AdButon
          color="bg-emphasis_dark"
          label="Annonce immobilière"
          type={ADVERTISEMENT_TYPE.IMMO}
          setAdvertisementType={setAdvertisementType}
          enabled={immoEnabled}
        />
        <AdButon
          color="bg-konnectz_accent"
          label="Offre d’emploi"
          type={ADVERTISEMENT_TYPE.EMPLOI}
          setAdvertisementType={setAdvertisementType}
          enabled={jobsEnabled}
        />
      </div>
    </section>
  );
};

interface IAdButonProps {
  color: string;
  label: string;
  type: ADVERTISEMENT_TYPE;
  setAdvertisementType: (type: ADVERTISEMENT_TYPE) => void;
  enabled: boolean;
}

function AdButon(props: IAdButonProps) {
  if (props.enabled)
    return (
      <div
        className={cn(
          "border-konnectz_background bg-emphasis_dark flex aspect-[7_/_6] cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 font-semibold text-white hover:border-white",
          props.color,
        )}
        onClick={() => props.setAdvertisementType(props.type)}
      >
        <PlusCircleIcon className="h-12 w-12" />
        {props.label}
      </div>
    );
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          tabIndex={0}
        >
          <div
            className={cn(
              "border-konnectz_background bg-emphasis_dark pointer-events-none flex aspect-[7_/_6] cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 font-semibold text-white opacity-50 hover:border-white",
              props.color,
            )}
          >
            <LockClosedIcon className="h-12 w-12 text-white" />
            {props.label}
          </div>
        </span>
      </TooltipTrigger>
      <TooltipContent>
        <p>Contactez-nous pour déverrouiller cette catégorie d'annonce</p>
      </TooltipContent>
    </Tooltip>
  );
}

export default EventBuilder;
