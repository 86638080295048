import Button from "@/components/button";
import TextInput from "@/components/inputs/text";
import { useDebounceEffect } from "@/hooks/useDebounceEffect";
import useInput from "@/hooks/useInput";
import useR from "@/hooks/useR";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import Spinner from "@/components/spinner";
import { useKeyboard } from "@/hooks/useKeyboard";
import usePersist from "@/hooks/usePersist";
import useExcludedAds from "@/hooks/useExcludedAds";
import useOptimistic from "@/hooks/useOptimistic";
import { Schedule } from "@/types/konnectz.types";
import {
  getAdvertisements,
  getWidgetContent,
  scheduleAdvertisement,
  throttledRefreshConfig,
  unscheduleAdvertisement,
} from "@/service/konnectz.service";
import ExclusionItem from "@/components/list-item/exclusion-item";

interface IScheduleAdvertisementModalProps {
  open: boolean;
  setOpen(open: boolean): void;
}

interface Payload {
  id: string;
  excluded: boolean;
}

const ExcludeAdvertisementModal: React.FunctionComponent<
  IScheduleAdvertisementModalProps
> = ({ open, setOpen }) => {
  const { currentSelection } = useR();
  const excludedAdsQuery = useExcludedAds();
  const [excludedAds, optimistic] = useOptimistic<
    Payload,
    Pick<Schedule, "advertisement">[]
  >(excludedAdsQuery[0], function (current, { id, excluded }) {
    (async () => {
      try {
        const [{ ConfigToAdvertisements, timenjoyId }] =
          await getAdvertisements({
            id: [id],
            include: ["configToAdvertisement"],
          });
        if (excluded) {
          await scheduleAdvertisement(
            currentSelection!.exclusionConfig!,
            timenjoyId,
            Date.now(),
            Date.now() + 1000 * 60 * 60 * 24 * 365,
          );
        } else {
          const matchingSchedule = ConfigToAdvertisements.find(
            (schedule) => schedule.config === currentSelection?.exclusionConfig,
          );
          if (matchingSchedule) {
            await unscheduleAdvertisement(matchingSchedule.id);
          }
        }
      } catch (e) {
        console.error(e);
      }
      throttledRefreshConfig(currentSelection!.config).catch(console.error);
      await excludedAdsQuery[1]();
    })();
    if (excluded) {
      return [...current, { advertisement: id }];
    } else {
      return current.filter((ad) => ad.advertisement !== id);
    }
  });

  const widgetContent = useQuery({
    queryKey: [
      "widget-content",
      currentSelection?.Config?.app_secret,
      currentSelection?.Config?.selection,
    ],
    enabled: !!currentSelection?.Config,
    queryFn: async ({ queryKey }) => {
      const [, app_secret, selection] = queryKey;
      if (!app_secret || !selection)
        throw new Error("Missing app_secret or selection");
      const content = await getWidgetContent(app_secret, selection);
      const passed = new Set<string>();
      return [
        ...excludedAdsQuery[0].map((a) => ({
          ...a.Advertisement,
          picture: a.Advertisement.Pictures[0].filename,
        })),
        ...content.advertisements,
      ]
        .filter((ad) => {
          if (passed.has(ad.id)) return false;
          passed.add(ad.id);
          return true;
        })
        .sort((a, b) => {
          return a.firstLine.localeCompare(b.firstLine);
        });
    },
  });

  const [searchString, setSearchString] = usePersist<string>({
    key: "schedule-advertisement-search",
    defaultValue: "",
  });
  const searchInput = useInput(searchString);

  useDebounceEffect(
    () => {
      setSearchString(searchInput.value);
    },
    500,
    [searchInput.value],
  );

  const hasFilters = !!searchString;

  function close(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === event.currentTarget) setOpen(false);
  }

  useKeyboard({
    Escape: () => setOpen(false),
  });

  if (!open) return null;

  return (
    <div
      className="bg-background_dark/40 fixed inset-0 z-20 flex cursor-pointer items-center justify-center backdrop-blur"
      onClick={close}
    >
      <div className="border-background_light bg-konnectz_background inline-flex max-h-[100vh] h-[615px] w-[1014px] flex-col items-start justify-start gap-6 rounded-2xl border px-10 py-8">
        <div className="flex w-full items-center justify-between">
          <div className="text-xl font-semibold tracking-tight text-white">
            Masquer des annonces
          </div>
          <div className="flex gap-2">
            <Button variant="quiet" onClick={() => setOpen(false)}>
              Fermer
            </Button>
          </div>
        </div>
        <div className="inline-flex items-center justify-start gap-16">
          <TextInput
            placeholder="Rechercher"
            className=" w-72"
            {...searchInput}
          />
        </div>
        {hasFilters && (
          <div className="noscrollbar -mb-6 flex w-full items-start gap-2 overflow-x-auto overflow-y-hidden pb-6">
            {searchInput.value && (
              <span className="bg-konnectz_accent flex shrink-0 cursor-default items-center rounded-full py-1.5 pl-2 pr-3 text-sm font-semibold tracking-tight text-white">
                <XCircleIcon
                  className="mr-1 inline-block h-5 w-5 cursor-pointer"
                  onClick={() => searchInput.onChange("")}
                />
                Nom: {searchInput.value}
              </span>
            )}
          </div>
        )}
        <div className="noscrollbar w-full h-full overflow-y-auto">
          <div className="grid grid-cols-2 gap-x-3 gap-y-2 h-full">
            {widgetContent.isLoading ? (
              <Spinner className="col-span-2 h-5 w-5" />
            ) : widgetContent.isError ? (
              <div className="col-span-2 text-white">
                Erreur lors du chargement des événements
              </div>
            ) : widgetContent.data?.length === 0 ? (
              <div className="col-span-2 text-white">
                Aucune annonce dans le widget
              </div>
            ) : (
              widgetContent.data?.map((ad) => {
                const isExcluded = excludedAds.some(
                  (excludedAd) => excludedAd.advertisement === ad.id,
                );
                return (
                  <ExclusionItem
                    key={ad.id}
                    item={{
                      ...ad,
                      metaData: {},
                    }}
                    excluded={isExcluded}
                    onClick={() => {
                      optimistic({ id: ad.id, excluded: !isExcluded });
                    }}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcludeAdvertisementModal;
