import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createPaymentCompany,
  getSubscription,
} from "@/service/payment.service";
import { TimenjoyCompany, TimenjoyUser } from "@/types/timenjoy.types";
import dayjs from "dayjs";
import { captureException } from "@/service/exceptions";

export function usePermission() {
  const client = useQueryClient();
  const company = client.getQueryData<TimenjoyCompany>(["company"]);
  const subscription = useQuery({
    queryKey: ["subscription", company?.id],
    queryFn: () => {
      if (!company?.id) {
        throw new Error("No company id");
      }
      return getSubscription(company);
    },
    enabled: !!company?.id,
    refetchInterval: 1000 * 60 * 5,
    async onError(error) {
      captureException(error);
      const user = client.getQueryData<TimenjoyUser>(["user"]);
      const company = client.getQueryData<TimenjoyCompany>(["company"]);
      if (!user || !company) return;
      await createPaymentCompany(company, user.email);
      client.invalidateQueries(["subscription", company.id]);
    },
  });
  if (!subscription.data)
    return {
      isLoading: subscription.isLoading,
      subscriptionExpired: false,
      trialExpired: false,
      premiumExpired: false,
      isPremium: false,
      isStandard: false,
      isTrial: false,
      isFreemium: true,
      canUseSelection: false,
      canUseEventBuilder: false,
      trialLeft: 0,
      eventsEnabled: true,
      immoEnabled: false,
      jobsEnabled: false,
    };

  const subscriptionExpired =
    subscription.data.subscriptionEnd < new Date().toISOString();
  const trialExpired = subscription.data.trialEnd < new Date().toISOString();
  const premiumExpired = subscriptionExpired && trialExpired;
  const isPremium =
    !premiumExpired &&
    subscription.data.Subscription?.id === "1TjiokzsLFhQY3bJEROZu";
  const isStandard =
    !premiumExpired &&
    subscription.data.Subscription?.id === "yGRZdMVn4qyiFeJ2CdqGY";
  const isFreemium = !subscription.data.subscriptionId;
  const isPaid = isPremium || isStandard;
  const isTrial = subscriptionExpired && !trialExpired && isPaid;
  const canUseSelection = isPaid;
  const canUseEventBuilder = isPremium;
  const trialLeft = Math.ceil(
    dayjs(subscription.data.trialEnd).diff(dayjs(), "day"),
  );

  return {
    isLoading: subscription.isLoading,
    subscriptionExpired,
    trialExpired,
    premiumExpired,
    isPremium,
    isStandard,
    isFreemium,
    isTrial,
    canUseSelection,
    canUseEventBuilder,
    trialLeft,
    eventsEnabled: subscription.data.eventsEnabled,
    immoEnabled: subscription.data.immoEnabled,
    jobsEnabled: subscription.data.jobsEnabled,
  };
}
