import { ErrorHandlerResponse } from "@/types/apis.types";
import { TimenjoyLogin } from "@/types/timenjoy.types";
import { z } from "zod";
import {
  LoginInvalidPassword,
  getApiAccountByCompanyId,
  getCompanyById,
  getUser,
} from "./timenjoy.service";
import { AUTH_URL } from "./environment";

const tokenSchema = z.object({
  value: z.string(),
  maxDate: z.number(),
});
function parseToken(str: string) {
  const parsing = tokenSchema.safeParse(JSON.parse(str));
  if (!parsing.success) return "";
  const { data } = parsing;
  if (data.maxDate < Date.now()) return "";
  return data.value;
}
function stringifyToken(token: string) {
  return JSON.stringify(
    tokenSchema.parse({
      value: token,
      maxDate: Date.now() + 1000 * 60 * 60 * 7,
    }),
  );
}
export function getToken() {
  try {
    return parseToken(
      localStorage.getItem("konnectz-token_v2") ||
        sessionStorage.getItem("konnectz-token_v2") ||
        "",
    );
  } catch (err) {
    console.error(err);
    return "";
  }
}
export function setToken(newToken: string) {
  console.log({ newToken });
  try {
    const token = stringifyToken(newToken);
    console.log({ token });
    localStorage.setItem("lastLogin", Date.now().toString());
    localStorage.setItem("konnectz-token_v2", token);
    sessionStorage.setItem("konnectz-token_v2", token);
  } catch (err) {
    console.error(err);
  }
}

export const AuthRegisterSchema = z.strictObject({
  email: z.string().email(),
  password: z.string().min(import.meta.env.PROD ? 12 : 1),
});
export async function register({
  email,
  password,
}: z.infer<typeof AuthRegisterSchema>) {
  const request = await fetch(`${AUTH_URL}/authentication/legacy-auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  if (!request.ok) {
    throw await AuthError.makeError(request);
  }
  return login({ email, password });
}

export interface AuthUser {
  id: number;
  email: string;
  legacyId: string | null;
  createdAt: string;
  updatedAt: string;
  canLogin: boolean;
  companyId: number | null;
  policyId: number | null;
}

export interface PublicAuthUser
  extends Pick<AuthUser, "id" | "email" | "legacyId" | "policyId"> {}

export interface AuthLoginResponse {
  message?: string;
  smart_session: string;
  company: null | {
    id: number;
    name: string;
    legacyId: string | null;
    createdAt: string;
    updatedAt: string;
  };
  policy: null | AuthPolicy;
  user: AuthUser;
}

export async function login({
  email,
  password,
}: z.infer<typeof AuthRegisterSchema>) {
  const request = await fetch(`${AUTH_URL}/authentication/legacy-auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email, password }),
  });

  if (!request.ok) {
    const error = await AuthError.makeError(request);
    if (error?.body?.message === "Incorrect password") {
      throw new LoginInvalidPassword();
    }
  }

  const data = (await request.json()) as AuthLoginResponse;
  console.log({ data });
  setToken(data.smart_session!);
  const [user, company] = await Promise.all([
    getUser(data.user.legacyId!),
    getCompanyById(data.company?.legacyId!),
  ]);
  const apiAccounts = await getApiAccountByCompanyId(company.id).catch(
    (err) => {
      console.error(err);
      return [];
    },
  );

  const userData: TimenjoyLogin & { auth: AuthLoginResponse } = {
    user,
    company,
    apiAccounts,
    auth: data,
  };

  return userData;
}
export async function getSession() {
  const request = await fetch(`${AUTH_URL}/authentication/me`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "include",
  });

  if (!request.ok) {
    throw await AuthError.makeError(request);
  }
  const data = (await request.json()) as AuthLoginResponse;
  const [user, company, apiAccounts] = await Promise.all([
    getUser(data.user.legacyId!),
    getCompanyById(data.company?.legacyId!),
    getApiAccountByCompanyId(data.company?.legacyId!).catch((err) => {
      console.error(err);
      return [];
    }),
  ]);

  const userData: TimenjoyLogin & { auth: AuthLoginResponse } = {
    user,
    company,
    apiAccounts,
    auth: data,
  };

  return userData;
}

export async function listCompanyUser(companyId: number) {
  const response = await fetch(
    `${AUTH_URL}/authentication/companies/${companyId}/users`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<{
    data: PublicAuthUser[];
    meta: {
      count: number;
    };
  }>;
}

export const enum AuthInviteStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
  Expired = "expired",
  Removed = "removed",
}
export interface AuthInvite {
  status: AuthInviteStatus;
  companyId: number;
  id: number;
  email: string;
  createdAt: string;
  inviterEmail: string;
  inviterId: number | null;
  policyId: number | null;
  userId: number | null;
  expiresAt: string;
}
export async function listCompanyInvites(companyId: number) {
  const response = await fetch(
    `${AUTH_URL}/authentication/companies/${companyId}/invites`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<{
    data: AuthInvite[];
    meta: {
      count: number;
    };
  }>;
}

export type AuthPolicy = Record<PolicyEnum, boolean> & {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: number | null;
};

export async function listCompanyPolicies(companyId: number) {
  const response = await fetch(
    `${AUTH_URL}/authentication/companies/${companyId}/policies`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<{
    data: AuthPolicy[];
    meta: {
      count: number;
    };
  }>;
}

export async function inviteUser(
  companyId: number,
  email: string,
  policyId: number | null,
) {
  const response = await fetch(`${AUTH_URL}/authentication/invites`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ email, policyId, companyId }),
  });
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<AuthInvite>;
}

export async function isLoggedIn() {
  const response = await fetch(`${AUTH_URL}/authentication/me`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "include",
  });
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<AuthUser>;
}

export async function logout() {
  await fetch(`${AUTH_URL}/authentication/logout`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "include",
  });
  setToken("");
}

export async function resendInvite(inviteId: number) {
  const response = await fetch(
    `${AUTH_URL}/authentication/invites/${inviteId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      method: "POST",
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return true;
}
export async function deleteInvite(inviteId: number) {
  const response = await fetch(
    `${AUTH_URL}/authentication/invites/${inviteId}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      method: "DELETE",
      credentials: "include",
    },
  );
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<AuthInvite>;
}

export async function acceptInvite(inviteId: number) {
  const response = await fetch(
    `${AUTH_URL}/authentication/invites/${inviteId}/resolve`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        status: AuthInviteStatus.Accepted,
      }),
    },
  );
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return true;
}

export interface AuthCompany {
  id: number;
  name: string;
  legacyId: string | null;
  createdAt: string;
  updatedAt: string;
}
export interface FullInvite extends AuthInvite {
  inviter: PublicAuthUser | null;
  company: AuthCompany | null;
}
export async function getInviteForUser() {
  const response = await fetch(`${AUTH_URL}/authentication/invites`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "include",
  });
  if (!response.ok) {
    throw await AuthError.makeError(response);
  }
  return response.json() as Promise<{
    data: FullInvite[];
    meta: {
      count: number;
    };
  }>;
}

export class AuthError extends Error {
  headers: Record<string, string>;
  statusCode;
  body: any = { error: "Unknown Error" };

  static async makeError(response: Response) {
    if (response.headers.get("content-type")?.includes("application/json")) {
      return new AuthError({
        statusCode: response.status as ErrorHandlerResponse["statusCode"],
        headers: Object.fromEntries(response.headers),
        body: await response.json(),
      });
    } else {
      return new AuthError({
        statusCode: response.status as ErrorHandlerResponse["statusCode"],
        headers: Object.fromEntries(response.headers),
        body: { error: response.statusText },
      });
    }
  }

  constructor(
    response: ErrorHandlerResponse & { headers: Record<string, string> },
  ) {
    if (!response.body) {
      console.error("no body", response);
    }
    super(`Auth Error: ${response.body.error}`);
    this.headers = response.headers || {};
    this.statusCode = response.statusCode;
    this.body = response.body;
  }
}

export enum PolicyEnum {
  // creation permissions
  CAN_INVITE_USERS = "canInviteUsers",
  CAN_CREATE_CAMPAIGNS = "canCreateCampaigns",
  CAN_CREATE_WIDGETS = "canCreateWidgets",
  CAN_CONNECT_SITES = "canConnectSites",
  CAN_CREATE_ADS = "canCreateAds",
  CAN_CREATE_POLICY = "canCreatePolicy",

  // update permissions
  CAN_UPDATE_OTHERS_POLICY = "canUpdateOthersPolicy",
  CAN_UPDATE_COMPANY = "canUpdateCompany",
  CAN_UPDATE_USER = "canUpdateUser",
  CAN_UPDATE_CAMPAIGN = "canUpdateCampaign",
  CAN_UPDATE_WIDGET = "canUpdateWidget",
  CAN_UPDATE_ADS = "canUpdateAds",
  CAN_UPDATE_STRIPE_INFOS = "canUpdateStripeInfos",

  // delete permissions
  CAN_REVOKE_USERS = "canRevokeUsers",
  CAN_DELETE_CAMPAIGNS = "canDeleteCampaigns",
  CAN_DELETE_WIDGETS = "canDeleteWidgets",
  CAN_DELETE_ADS = "canDeleteAds",
  CAN_DELETE_COMPANY = "canDeleteCompany",
  CAN_DELETE_POLICY = "canDeletePolicy",

  // consultation permissions
  CAN_CONSULT_USERS = "canConsultUsers",
  CAN_CONSULT_CAMPAIGNS = "canConsultCampaigns",
  CAN_CONSULT_WIDGETS = "canConsultWidgets",
  CAN_CONSULT_ADS = "canConsultAds",
  CAN_CONSULT_COMPANY = "canConsultCompany",
  CAN_CONSULT_STATISTICS = "canConsultStatistics",
  CAN_CONSULT_INVOICES = "canConsultInvoices",
  CAN_CONSULT_POLICY = "canConsultPolicy",
}
