import React from "react";
import {
  useFloating,
  autoUpdate,
  flip,
  offset,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
  arrow,
  FloatingArrow,
  Placement,
} from "@floating-ui/react";

interface IPopoverProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactElement<{ onClick?: React.MouseEventHandler }>;
  reference: Element | null | undefined;
  placement?: Placement;
  noArrow?: boolean;
  arrowClassName?: string;
}

const Popover: React.FunctionComponent<IPopoverProps> = ({
  children,
  isOpen,
  setIsOpen,
  reference,
  placement = "left",
  arrowClassName,
  noArrow
}) => {
  const arrowRef = React.useRef<SVGSVGElement | null>(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    elements: {
      reference,
    },
    middleware: [
      offset(10),
      flip(),
      shift({ padding: 10 }),
      arrow({ element: arrowRef }),
    ],
    placement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);

  const { getFloatingProps, getItemProps } = useInteractions([role, dismiss]);

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay lockScroll className="z-50">
          <FloatingFocusManager context={context} initialFocus={refs.floating}>
            <div
              className="focus:outline-none focus-visible:outline-none"
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {noArrow || <FloatingArrow
                ref={arrowRef}
                context={context}
                className={arrowClassName}
              />}
              {React.isValidElement(children) &&
                React.cloneElement(
                  children,
                  getItemProps({
                    onClick(event) {
                      children.props.onClick?.(event);
                      if (event.isPropagationStopped()) return
                      setIsOpen(false);
                    },
                    onMouseUp(event) {
                      children.props.onClick?.(event);
                      if (event.isPropagationStopped()) return
                      setIsOpen(false);
                    },
                  })
                )}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};

export default Popover;
