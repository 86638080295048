import * as React from "react";

export function useDelayedOff(prop = false, timeout = 1000) {
  const [state, setState] = React.useState(true);
  React.useEffect(() => {
    const newValue = prop;
    if (newValue === state) return;
    if (newValue) {
      setState(newValue);
      return;
    }
    const timer = setTimeout(() => setState(newValue), timeout);
    return () => clearTimeout(timer);
  }, [prop]);
  return state;
}
