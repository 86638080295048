import Button from '@/components/button';
import { BuildingEvent, useBuildingEvent } from '@/hooks/useBuildingEvent';
import { useToast } from '@chakra-ui/react';
import * as React from 'react';
import WebsiteInput from '@/components/inputs/website';
import CategoryFilter from '@/components/category-filter';
import { PlusIcon, XCircleIcon } from '@heroicons/react/24/solid';
import CheckboxInput from '@/components/inputs/checkbox';
import { useCategories } from '@/hooks/useCategories';



interface IInfosEventBuilderProps {
}

const MAX_CATEGORY = 2


const InfosEventBuilder: React.FunctionComponent<IInfosEventBuilderProps> = (props) => {
    const event = useBuildingEvent()
    const availableCategories = useCategories({ event: true })
    const toast = useToast()
    function updateEvent(property: keyof BuildingEvent) {
        return function (value: BuildingEvent[typeof property]) {
            event.update(data => ({
                ...data,
                [property]: value
            }))
        }
    }
    const categories = (availableCategories.data ?? []).map(a => a.name)
    function updateCategories(values: string[]) {
        if (!availableCategories.isSuccess) return;
        if (values.length > MAX_CATEGORY) {
            toast({
                title: "Trop de catégories",
                description: `Vous ne pouvez pas sélectionner plus de ${MAX_CATEGORY} catégories`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return
        }
        event.update(data => ({
            ...data,
            categories: availableCategories.data.filter(a => values.includes(a.name)) ?? []
        }))
    }
    return <div className='w-full h-full max-w-xl flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
            <h1>Catégorie de l'événement</h1>
            <div className='flex gap-2 mt-2'>
                <CategoryFilter value={event.categories?.map(a => a.name) ?? []} data={categories} onChange={updateCategories} >
                    <Button variant='tertiary' className='flex gap-1.5 leading-none items-center px-3'><PlusIcon className='w-5 h-5 text-white' /> Ajouter</Button>
                </CategoryFilter>
                {event.categories?.map((category) => (
                    <span key={category.id} className="shrink-0 cursor-default pl-2 pr-3 py-1.5 bg-konnectz_accent rounded-full text-white text-sm font-semibold tracking-tight flex items-center">
                        <XCircleIcon
                            className="w-5 h-5 inline-block mr-1 cursor-pointer"
                            onClick={() =>
                                updateCategories(event.categories!.map(a => a.name).filter((c) => c !== category.name))
                            }
                        />
                        {category.name}
                    </span>
                ))}
            </div>
        </div>
        <div className='flex flex-col gap-2'>
            <h1>Lien de la billetterie</h1>
            <WebsiteInput
                value={event.url_ticketing}
                onChange={(e) => updateEvent("url_ticketing")(e.target.value)}
            />
        </div>
        <div className='flex flex-col gap-2'>
            <h1>Statut</h1>
            <CheckboxInput
                checked={event.published}
                onClick={() => {
                    event.update(data => ({
                        ...data,
                        published: !event.published
                    }))
                }}
            >
                Publier dans le catalogue Konnectz
            </CheckboxInput>
        </div>
    </div>
};

export default InfosEventBuilder;
