import {
  getAdvertisements,
  orderAdvertisement,
} from "@/service/konnectz.service";
import { Schedule } from "@/types/konnectz.types";
import { useQuery } from "@tanstack/react-query";
import useR from "./useR";
import { Selection } from "@/types/manager.types";
import { refreshSelectionByAppSecret } from "@/service/manager.service";

export function useSchedules() {
  const { currentApiAccount } = useR();
  const currentSelection = useQuery<Selection>({
    queryKey: ["selection", currentApiAccount?.id],
  });
  const list =
    currentSelection.data?.Config?.ConfigToAdvertisements.filter(
      (a) => a.advertisement,
    ) ?? [];
  const schedules = useQuery<Schedule[]>({
    queryKey: ["schedules", list.map((a) => JSON.stringify(a)).join(":")],
    queryFn: async () => {
      if (!currentSelection.data) throw new Error("No selection selected");
      const items = list;
      const ads = await getAdvertisements({
        id: items.map((i) => i.advertisement).filter(Boolean) as string[],
      });
      const populated: Schedule[] = items
        .map((item) => ({
          ...item,
          Advertisement: ads.find((a) => a.id === item.advertisement)!,
        }))
        .filter((a) => a.Advertisement);
      return populated.sort(orderAdvertisement);
    },
    enabled: !!currentSelection.data,
    staleTime: 1_000,
    refetchInterval: 60_000,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
  async function refresh() {
    await refreshSelectionByAppSecret(currentApiAccount?.app_secret!);
    await currentSelection.refetch();
    await schedules.refetch({
      cancelRefetch: false,
    });
  }

  return [schedules, refresh] as const;
}
