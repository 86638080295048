import React from "react";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

type Props = {
  content: React.ReactNode | React.ReactNode[];
  onClick: () => void;
  disable?: boolean;
  className?: string;
  checked?: boolean;
};

const CheckButton = (props: Props) => {
  return (
    <button
      type="button"
      className={clsx(
        "px-5 py-3 flex justify-center items-center gap-2 text-white font-medium border border-white/20 duration-150 bg-background_light rounded-lg hover:border-emphasis active:shadow-lg relative",
        props.disable && "hover:bg-background_light hover:border-white/20 cursor-not-allowed opacity-50",
        props.checked && "!border-emphasis border font-semibold",
        props.className
      )}
      onClick={props.onClick}
    >
      {props.content}
      {props.checked ? (
        <CheckCircleIcon className="w-5 h-5 text-emphasis" />
      ) : null}
    </button>
  );
};

export default CheckButton;
