import Button from "@/components/button";
import EmailInput from "@/components/inputs/email";
import Spinner from "@/components/spinner";
import useInput from "@/hooks/useInput";
import { triggeResetPassword } from "@/service/timenjoy.service";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";
import React, { useRef } from "react";

export interface IForgotPasswordProps {
}

export default function ForgotPassword(props: IForgotPasswordProps) {
    const toast = useToast();
    const email = useInput();
    const lastSent = useRef("");
    const forgotPasswordMutation = useMutation<unknown, Error>({
        async mutationFn() {
            lastSent.current = email.value;
            return triggeResetPassword(email.value);
        },
        onError(error, variables, context) {
            captureException(error, { extra: { variables, context } });
            toast.closeAll();
            toast({
                title: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        },
        onSuccess() {
            toast({
                title: "Un email de récupération a été envoyé",
                description: "Veuillez vérifier votre boite mail",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        },
        retry: false,
    });

    function submit(e?: React.FormEvent) {
        e?.preventDefault();
        if (forgotPasswordMutation.isLoading) return;
        if (
            forgotPasswordMutation.isError &&
            email.value === lastSent.current
        )
            return;
        forgotPasswordMutation.mutate();
    }
    return (
        <main className="w-full h-full flex flex-col items-center justify-center pb-10 sm:pb-16">
            <div className="max-w-sm w-full text-subtitle">
                <div className="text-center">
                    <img
                        src={import.meta.env.BASE_URL + "img/logo.png"}
                        width={200}
                        className="mx-auto"
                    />
                    <div className="mt-5 space-y-2">
                        <h3 className="text-title text-2xl font-bold sm:text-3xl">
                            Mot de passe oublié
                        </h3>
                        <p className="">
                            Veuillez renseigner l'adresse email de votre compte. Vous
                            recevrez un email avec un lien pour réinitialiser votre mot de passe.
                        </p>
                    </div>
                </div>
                <form onSubmit={submit} className="mt-8 space-y-5">
                    <div>
                        <label className="font-medium">Email</label>
                        <EmailInput className="w-full mt-2" {...email} />
                    </div>
                    <input type="submit" className="hidden" />
                </form>
                <Button
                    className="w-full grid grid-cols-3 text-base py-3 !mt-8 gap-3"
                    disabled={forgotPasswordMutation.isLoading}
                    onClick={() => submit()}
                >
                    <div />
                    Réinitialiser{" "}
                    {forgotPasswordMutation.isLoading ? (
                        <Spinner className="w-5 h-5 fill-emphasis" />
                    ) : (
                        <div />
                    )}
                </Button>
            </div>
        </main>
    );
}
