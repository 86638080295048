import React from "react";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import { cleanName } from "@/utils/name";
import useR from "@/hooks/useR";

interface IWidgetSwitchItemProps extends TimenjoyApiAccount {
    close: () => void;
}

const WidgetSwitchItem: React.FunctionComponent<IWidgetSwitchItemProps> = (props) => {
    const { client } = useR();
    const sites = props.white_list?.map((site) => site.url) ?? [];

    function selectWidget(event: React.MouseEvent) {
        event.stopPropagation()
        console.log("selectWidget", props.id)
        client.setQueryData(["currentApiAccount"], props.id);
        props.close()
    }

    return <div className="py-1 px-2 rounded hover:bg-background_light relative" onMouseDown={selectWidget}>
        <div className="flex flex-col cursor-pointer">
            <span className=" font-medium tracking-wider text-xs">{cleanName(props.name)} </span>
            <span className="text-xs font-light text-white/60">
                {sites[0] ?? "Pas de site connecté"}
                {/* {sites.length > 1 && " +" + sites.length} */}
            </span>
        </div>
        <div className="absolute opacity-0 inset-0 w-full h-full hover:opacity-100 flex items-center justify-end z-10">
            <div className="cursor-pointer flex text-emphasis text-xs tracking-wide items-center py-1 px-2 mr-2 rounded gap-2 hover:bg-slate-100/10">
                <ArrowsRightLeftIcon className="w-4 h-4 text-emphasis" />
                Sélectionner
            </div>
        </div>

    </div>
};


export default WidgetSwitchItem;