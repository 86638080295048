import React from "react";
import { useNavigate } from "react-router-dom";
import Options from "@/components/options";

const NewWidget: React.FC = () => {
  const navigate = useNavigate();

  function next() {
    navigate("/app/selection");
  }

  return (
    <div className="w-full min-h-full flex flex-col pb-12 px-16">
      <h1 className="text-2xl font-semibold text-title">
        Créer un nouveau widget
      </h1>
      <p className="mt-4 w-full max-w-3xl text-subtitle">
        Créer un nouveau widget à intégrer sur votre site internet. Indiquez les
        informations spécifiques à votre widget ci-dessous. N'oubliez pas de
        préciser le format, la catégorie d'événement et le trafic mensuel
        souhaité. À vous de jouer&nbsp;!
      </p>
      <section className="mt-8 h-full grow">
        <Options
          next={next}
          openPremiumPaywall
        />
      </section>
    </div>
  );
};

export default NewWidget;
