import { deleteEvent } from "@/service/timenjoy.service";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";

export function useDeleteEventMutation() {
  const { policy } = useAuth();
  const { client } = useR();
  const toast = useToast();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      if (!policy?.canDeleteAds) {
        toast({
          title: "Action refusée",
          description:
            "Vous n'êtes pas autorisés à supprimer d'annonce sur dans cette société.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        throw new Error("You are not allowed to use this feature");
      }
      return deleteEvent(id);
    },
    onSuccess() {
      toast({
        title: "Annonce supprimée",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      client.invalidateQueries(["events"]);
      client.prefetchQuery(["events"]);
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Erreur",
        description:
          "Une erreur est survenue lors de la suppression de l'annonce",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
}
