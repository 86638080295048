"use client";

import * as React from "react";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import dayjs from "dayjs";
import fr from "date-fns/locale/fr";

export interface IDatePickerWithPresetsProps {
  onChange(from: Date, to: Date): void;
  from: Date;
  to: Date;
  className?: string;
}

export function DatePickerWithPresets({
  from,
  to,
  onChange,
  className,
}: IDatePickerWithPresetsProps) {
  const [date, setDate] = React.useState<
    | {
        from: Date | undefined;
        to?: Date | undefined;
      }
    | undefined
  >(from && to ? { from, to } : undefined);

  React.useEffect(() => {
    setDate({ from, to });
  }, [from, to]);

  React.useEffect(() => {
    if (!date) return;
    if (!date.from || !date.to) return;
    if (
      date.from.getTime() === from.getTime() &&
      date.to.getTime() === to.getTime()
    )
      return;
    onChange(date.from, date.to);
  }, [date]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal bg-transparent",
            className,
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date?.from && date?.to ? (
            `${dayjs(date.from).format("DD MMM")} - ${dayjs(date.to).format("DD MMM")}`
          ) : (
            <span>Sélectionner une date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-auto flex-col space-y-2 p-2 border-border">
        <Select
          onValueChange={(value) =>
            setDate({
              from: dayjs().subtract(+value, "days").startOf("day").toDate(),
              to: new Date(),
            })
          }
        >
          <SelectTrigger className="bg-transparent">
            <SelectValue placeholder="Périodes" />
          </SelectTrigger>
          <SelectContent position="popper">
            <SelectItem value="0">Aujourd'hui</SelectItem>
            <SelectItem value="1">Hier</SelectItem>
            <SelectItem value="7">7 derniers jours</SelectItem>
            <SelectItem value="14">14 derniers jours</SelectItem>
            <SelectItem value="30">30 derniers jours</SelectItem>
          </SelectContent>
        </Select>
        <div className="rounded-md border border-border">
          <Calendar
            initialFocus
            mode="range"
            locale={fr}
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
