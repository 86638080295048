import React from "react";
import {
  AuthInvite,
  deleteInvite,
  listCompanyPolicies,
  resendInvite,
} from "@/service/auth.service";
import Button from "@/components/button";
import { PaperAirplaneIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";
import { useToast } from "@chakra-ui/react";

interface IListItemProps extends AuthInvite {}

const InviteItem: React.FunctionComponent<IListItemProps> = (invite) => {
  const auth = useAuth();
  const { client } = useR();
  const toast = useToast();
  const policy = useQuery({
    queryKey: ["companyPolicies", auth?.company?.id],
    async queryFn() {
      if (!auth?.company?.id) return Promise.reject();
      return listCompanyPolicies(auth.company.id);
    },
    enabled: !!auth?.company?.id,
  });
  const resendInviteMutation = useMutation({
    async mutationFn({ id }: { id: number }) {
      return resendInvite(id);
    },
    onSuccess() {
      client.refetchQueries(["companyInvites", auth?.company?.id]);
      toast({
        title: "Invitation renvoyée",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError() {
      toast({
        title: "Une erreur est survenue",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const deleteInviteMutation = useMutation({
    async mutationFn({ id }: { id: number }) {
      return deleteInvite(id);
    },
    onSuccess() {
      client.refetchQueries(["companyInvites", auth?.company?.id]);
      toast({
        title: "Invitation supprimée",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError() {
      toast({
        title: "Une erreur est survenue",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const canInviteUser = auth?.policy?.canInviteUsers === true;
  return (
    <li className="hover:bg-background_light -mx-6 flex w-full cursor-pointer select-none items-center gap-2 rounded-md px-6 py-2 text-white">
      <div className="flex w-full shrink gap-2 text-left opacity-80">
        Invitation attente
        <div className="flex items-center justify-start gap-2.5 rounded-sm bg-fuchsia-700 px-[7px] py-1.5">
          <div className="text-[10px] font-medium tracking-tight text-white">
            {policy.data?.data.find((p) => p.id === invite.policyId)?.name}
          </div>
        </div>
      </div>
      <div className="w-80 shrink-0">{invite.email}</div>
      <div className="w-20 shrink-0">
        <Button
          variant="quiet"
          className="text-slate-white px-2"
          onClick={() => resendInviteMutation.mutate({ id: invite.id })}
          disabled={!canInviteUser || resendInviteMutation.isLoading}
        >
          <PaperAirplaneIcon
            title="Renvoyer l'invitation"
            className="h-5 w-5"
          />
        </Button>
        <Button
          variant="quiet"
          className="px-2 text-slate-200/60"
          onClick={() => deleteInviteMutation.mutate({ id: invite.id })}
          disabled={!canInviteUser || deleteInviteMutation.isLoading}
        >
          <TrashIcon className="h-5 w-5" />
        </Button>
      </div>
    </li>
  );
};

export default InviteItem;
