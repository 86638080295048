import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "@/service/auth.service";


const BrowserAuth: React.FC = () => {

  const navigate = useNavigate()
  useEffect(() => {
    if (!location.pathname.startsWith("/app")) return
    const interval = setInterval(() => {
      if (!getToken()) navigate("/auth/logout?redirect=" + location.pathname + location.search);
    }, 1_000)
    return () => clearInterval(interval)
  }, [navigate, location]);
  return null;
};

export default BrowserAuth;
