import { z } from "zod";

export const CompanyActivityArea = [
    "Media",
    "Blog",
    "Agence de voyage",
    "Hotel",
    "Office de tourisme",
    "Site de réservation, OTA",
    "Autre",
] as const;

export const schema = z.strictObject({
    zip_code: z.string({
        errorMap(issue) {
            if (issue.code === "too_small") return { message: "" }
            return {
                message: "Le code postal doit contenir 5 chiffres.",
            };
        },
    }).min(5).max(5).regex(/^[0-9]{5}$/),
    name: z
        .string({
            errorMap() {
                return {
                    message: "Le nom de l'entreprise doit contenir au moins 2 caractères.",
                };
            },
        })
        .min(2)
        .trim(),
    activity_area: z.enum(CompanyActivityArea, {
        errorMap() {
            return {
                message: "Vous devez sélectionner un secteur activité.",
            }
        }
    }),
    web: z.string({
        errorMap() {
            return {
                message: "Vous devez saisir un site web valide.",
            }
        }
    }).url(),
    phone: z
        .string({
            errorMap() {
                return {
                    message: "Vous devez saisir un numéro de téléphone valide.",
                };
            },
        })
        .trim()
        .regex(/^(0|\+\d+)[0-9 -]{9,}$/).optional(),
    email: z
        .string()
        .email().optional()
});