import React from "react";
import {
  useFloating,
  autoUpdate,
  flip,
  offset,
  shift,
  useRole,
  useDismiss,
  useInteractions,
  useListNavigation,
  useTypeahead,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
} from "@floating-ui/react";

interface IContextMenuProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
  reference: Element | null | undefined;
  placement?: "left" | "right" | "top" | "bottom";
  overlayClassName?: string;
}

const ContextMenu: React.FunctionComponent<IContextMenuProps> = ({
  children = [],
  isOpen,
  setIsOpen,
  reference,
  placement = "left",
  overlayClassName = "z-10",
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const listItemsRef = React.useRef<Array<HTMLButtonElement | null>>([]);
  const listContentRef = React.useRef(
    React.Children.map(children, (child) =>
      React.isValidElement(child) ? child.props.label : null,
    ) as Array<string | null>,
  );

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    elements: {
      reference,
    },
    middleware: [offset(10), flip(), shift({ padding: 10 })],
    placement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    onNavigate: setActiveIndex,
    activeIndex,
  });
  const typeahead = useTypeahead(context, {
    enabled: isOpen,
    listRef: listContentRef,
    onMatch: setActiveIndex,
    activeIndex,
  });

  const { getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  return (
    <FloatingPortal preserveTabOrder>
      {isOpen && (
        <FloatingOverlay lockScroll className={overlayClassName}>
          <FloatingFocusManager context={context} initialFocus={refs.floating}>
            <div
              className="bg-background_light rounded-md p-2 shadow-md focus:outline-none focus-visible:outline-none"
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {React.Children.map(
                children,
                (child, index) =>
                  React.isValidElement(child) &&
                  React.cloneElement(
                    child,
                    getItemProps({
                      tabIndex: activeIndex === index ? 0 : -1,
                      ref(node: HTMLButtonElement) {
                        listItemsRef.current[index] = node;
                      },
                      onClick() {
                        child.props.onClick?.();
                        setIsOpen(false);
                      },
                      onMouseUp() {
                        child.props.onClick?.();
                        setIsOpen(false);
                      },
                    }),
                  ),
              )}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};

export default ContextMenu;

export interface IMenuItemProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
}
export const MenuItem = React.forwardRef<HTMLButtonElement, IMenuItemProps>(
  ({ children, disabled, ...props }, ref) => {
    return (
      <button
        {...props}
        className="hover:bg-konnectz_accent flex w-full cursor-pointer items-center justify-start rounded-md px-2 py-1.5 text-left text-white focus:outline-none focus-visible:outline-none disabled:cursor-default disabled:hover:bg-inherit"
        ref={ref}
        role="menuitem"
        disabled={disabled}
      >
        {children}
      </button>
    );
  },
);
