import useR from "@/hooks/useR";
import React from "react";
import { useNavigate } from "react-router-dom";
import Options from "@/components/options";

const OnboardingWidget = () => {
  const { company, currentApiAccount } = useR();
  const navigate = useNavigate();

  return (
    <section className="mx-auto w-fit mt-10">
      <h3 className="text-title text-2xl font-bold sm:text-3xl">Mon premier widget</h3>
      <p className="mt-4 w-full text-subtitle">
        On y est presque! Indiquez les informations spécifiques à votre widget
        ci-dessous. N'oubliez pas de préciser le format, la catégorie
        d'événement et le département souhaité. À vous de jouer&nbsp;!
      </p>
      <Options
        id={currentApiAccount?.id}
        defaultName="Mon widget"
        defaultWebsite={company!.web || ""}
        next={() => navigate("/onboarding/finish")}
      />
    </section>
  );
};

export default OnboardingWidget;
