import Button from "@/components/button";
import PremiumCard from "@/components/premium-card";
import Spinner from "@/components/spinner";
import { Pricing, usePricing } from "@/hooks/usePricing";
import { useStartTrial } from "@/mutations/start-trial";
import { isPlanPremium } from "@/utils/isPlanPremium";
import React from "react";

const OnboardingPricing: React.FC = () => {
  const pricing = usePricing();
  const mutation = useStartTrial()
  function checkout(plan: Pricing) {
    return function () {
      mutation.mutate(plan)
    }
  }
  if (!pricing.data) return <Spinner />
  return (
    <section className="w-full shrink-0 pb-4 text-subtitle sm:max-w-2xl lg:max-w-2xl mx-auto">
      <div className="max-w-xl mx-auto text-center">
        <h2 className="text-title text-3xl font-semibold sm:text-4xl">
          Profitez de la pleine puissance de Konnectz
        </h2>
        <p className="text-subtitle mt-6">
          Accédez aux options de diffusion et aux fonctionnalités de publication
          et de programmation avancées.
        </p>
      </div>
      <div className="w-full mt-12 justify-center gap-6 sm:grid lg:grid-cols-2 sm:space-y-0 mx-auto duration-1000 delay-300 opacity-1">
        {pricing.data?.filter(isPlanPremium).map((plan) => (
          <PremiumCard plan={plan} key={plan.name} overridedCta={
            <Button
              className="w-full py-3"
              variant="tertiary"
              onClick={checkout(plan)}
            >
              Essayer 30 jours gratuitement
            </Button>
          } />
        ))}
      </div>
      <Button
        className="self-end ml-auto !mt-9 flex justify-center items-center gap-2 font-semibold"
        onClick={checkout(pricing.data.find(p => !isPlanPremium(p))!)}
        variant="quiet"
      >
        Rester sur la version gratuite
      </Button>
    </section>
  );
};

export default OnboardingPricing;
