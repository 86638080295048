import { deleteApiAccount } from "@/service/timenjoy.service";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: string;
};

const DeleteWidgetPopup = ({ open, setOpen, id }: Props) => {
  const client = useQueryClient();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const token = client.getQueryData<string>(["browserToken"]);
      if (!token) return;
      await deleteApiAccount(id);
    },
    onSuccess(data, variables, context) {
      client.setQueryData<string[]>(["apiAccounts"], (old?: string[]) => {
        return old?.filter((item) => item !== variables) || [];
      });
      setOpen(false);
      toast({
        title: "Succès",
        description: "Le widget a été supprimé",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Erreur",
        description: "Une erreur est survenue",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  function deleteAccount() {
    if (mutation.isLoading) return;
    mutation.mutate(id);
  }
  if (!open) return null;
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div
        className="fixed inset-0 w-full h-full bg-black opacity-40"
        onClick={() => setOpen(false)}
      ></div>
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-lg p-4 mx-auto bg-background_light rounded-md shadow-lg">
          <div className="mt-3">
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 text-red-700 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div className="mt-2 text-center">
              <h4 className="text-lg font-medium text-title">
                Voulez-vous vraiment supprimer ce widget ?
              </h4>
            </div>
          </div>
          <div className="items-center gap-2 mt-3 sm:flex">
            <button
              className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
              onClick={deleteAccount}
            >
              Supprimer
            </button>
            <button
              className="w-full mt-2 p-2.5 flex-1 text-title rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
              onClick={() => setOpen(false)}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWidgetPopup;
