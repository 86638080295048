import React from "react";
import useR from "./useR";
import { useQuery } from "@tanstack/react-query";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import { Selection } from "@/types/manager.types";
import { getAdvertisements } from "@/service/konnectz.service";

export function useAssociatedSelections(timenjoyId: string, enabled?: boolean) {
  const { selections, apiAccounts, client } = useR();
  const accounts = React.useMemo(() => {
    const apiAccountsData = apiAccounts.map(
      (id) => client.getQueryData<TimenjoyApiAccount>(["apiAccount", id])!,
    );
    return selections
      .filter((s) => s.data?.app_secret)
      .map((s) => {
        const account = apiAccountsData.find(
          (a) => a?.app_secret === encodeURIComponent(s.data?.app_secret ?? ""),
        );
        if (!account) {
          return null;
        }
        return { ...s.data, account };
      })
      .filter(Boolean) as (Selection & { account: TimenjoyApiAccount })[];
  }, [selections, apiAccounts]);
  const query = useQuery({
    queryKey: [
      "event:schedule",
      timenjoyId,
      accounts.map((s) => s.config).filter(Boolean) as string[],
    ],
    async queryFn({ queryKey: [, id, configs] }) {
      const [advertisement] = await getAdvertisements({
        timenjoyId: [id as string],
        include: ["configToAdvertisement"],
      });
      if (!advertisement) return null;
      // const uptoDate = advertisement.ConfigToAdvertisements.filter(
      //   (cta) => cta.to > Date.now()
      // );
      const fromConfigs = advertisement.ConfigToAdvertisements.filter((cta) =>
        configs.includes(cta.config),
      );
      advertisement.ConfigToAdvertisements = fromConfigs;
      return advertisement;
    },
    enabled,
  });
  const displayedIn = !query.data
    ? []
    : accounts
        .map((s) => ({
          ...s,
          schedules:
            query.data?.ConfigToAdvertisements.filter(
              (cta) => cta.config === s.config!,
            ) ?? [],
        }))
        .filter((s) => s.schedules.length);
  return [query, displayedIn] as const;
}
