import WidgetHeadline from "@/components/widget-headline";
import useAuth from "@/hooks/useAuth";
import useR from "@/hooks/useR";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TabPanel, TabPanels, TabGroup, TabList, Tab } from "@tremor/react";
import WidgetStats from "@/components/widget-stats";
import AdvertisementStatsList from "@/components/advertisement-stats-list";
import { useMainWorkPlaceSize } from "@/hooks/useMainWorkPlaceSize";

interface IAnalyticsProps { }


const Analytics: React.FunctionComponent<IAnalyticsProps> = (props) => {
  const [query, setQuery] = useSearchParams()
  const { permissions: { isPremium } } = useR();
  const size = useMainWorkPlaceSize()
  const { policy } = useAuth()
  const navigation = useNavigate();
  React.useEffect(() => {
    if (!isPremium || !policy?.canConsultStatistics) {
      navigation("/app");
    }
  }, [isPremium, policy?.canConsultStatistics]);

  if (!isPremium || !policy?.canConsultStatistics) return null

  return (
    <div className="w-full h-full text-subtitle flex flex-col" style={size}>
      <div className="w-full h-full flex flex-col px-16" style={size}>
        <div className="flex justify-between">
          <WidgetHeadline />
        </div>
        <div className="mt-8 mb-4 flex items-center">
          <h1 className="text-2xl font-semibold text-title select-none">
            Analytics
          </h1>
        </div>
        <TabGroup
          className="h-full shrink -mx-4 px-4 overflow-hidden grid grid-rows-[38px_1fr]"
          style={{ height: size.height - 132 }}
          defaultIndex={
            query.get("tab") === "1" ? 1 : 0
          }
          onIndexChange={(tab) => {
            setQuery(prev => {
              prev.set("tab", tab + '')
              prev.delete("time")
              return prev
            })
          }}>
          <TabList>
            <Tab>Widget</Tab>
            <Tab>Annonces</Tab>
          </TabList>
          <TabPanels className="h-full flex flex-col shrink" style={{ height: size.height - 132 - 38 }}>
            <TabPanel className="h-full">
              <WidgetStats />
            </TabPanel>
            <TabPanel className="h-full">
              <AdvertisementStatsList />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  )
};

export default Analytics;
