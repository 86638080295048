import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ADVERTISEMENT_TYPE, BuildingEvent, BuildingEventContext, BuildingEventContextType } from '@/hooks/useBuildingEvent';
import { getEventById } from "@/service/timenjoy.service";
import { useToast } from "@chakra-ui/react";
import Spinner from "@/components/spinner";
import { usePermission } from "@/hooks/usePermissions";
import { parseCData, extractCData } from "@/utils/c-data";

export function withBuildingEvent<T extends Record<string, any>>(
    Component: React.FC<T>
) {
    return function WithBuildingEvent(props: T) {
        const navigate = useNavigate();
        const params = useParams()
        const id = params.id as string
        const [event, update] = React.useState<Partial<BuildingEvent>>({
            published: false
        });
        const { canUseEventBuilder, isLoading: permissionLoading } = usePermission()
        const [valid, setValid] = React.useState(false);
        const toast = useToast();
        React.useEffect(() => {
            if (valid) return
            if (!canUseEventBuilder && !permissionLoading) {
                toast({
                    title: "Fonctionnalité réservée aux comptes premium",
                    description: "Vous pouvez créer un événement en vous abonnant à Konnectz Premium.",
                    status: "warning",
                    duration: 9000,
                    isClosable: true,
                })
                return navigate("/app/premium")
            }
            if (!canUseEventBuilder) return
            if (!id) return navigate("/app");
            if (id === "new") return setValid(true)
                // Fetch event from API
                ; (async () => {
                    try {
                        const fetchedEvent = await getEventById(id)
                        const type = fetchedEvent.categories.find(c => c.name.toLowerCase().includes('immo')) ?
                            ADVERTISEMENT_TYPE.IMMO :
                            fetchedEvent.categories.find(c => c.name.toLowerCase().includes('emploi')) ?
                                ADVERTISEMENT_TYPE.EMPLOI :
                                ADVERTISEMENT_TYPE.EVENT
                        const cData = parseCData(extractCData(fetchedEvent.description))
                        update({
                            ...fetchedEvent,
                            type,
                            surface: type === ADVERTISEMENT_TYPE.IMMO ? cData?.surface : undefined,
                            rooms: type === ADVERTISEMENT_TYPE.IMMO ? cData?.rooms : undefined,
                            price: type === ADVERTISEMENT_TYPE.IMMO ? cData?.price : undefined,
                            entreprise: type === ADVERTISEMENT_TYPE.EMPLOI ? cData?.entreprise : undefined,
                            address: fetchedEvent.coordinate.address,
                            lat: fetchedEvent.coordinate.lat,
                            lng: fetchedEvent.coordinate.lng,
                            raw_picture: fetchedEvent.picture,
                        })
                        setValid(true)
                    } catch (error) {
                        console.error(error)
                        toast({
                            title: "Error",
                            description: "L'événement n'a pas pu être récupéré. Veuillez réessayer plus tard.",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                        return navigate("/app")
                    }
                })()
        }, [id, canUseEventBuilder]);
        if (!valid) return <div className="w-full h-full flex items-center justify-center">
            <Spinner />
        </div>;
        return <BuildingEventContext.Provider value={{ ...event, update }}>
            <Component {...props} />
        </BuildingEventContext.Provider>;
    };
}

type Props = {
    children: React.ReactNode;
};

export const BuildingEventGuard: React.FC<Props> = withBuildingEvent(
    ({ children }) => <>{children}</>
);