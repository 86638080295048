import Button from "@/components/button";
import TextInput from "@/components/inputs/text";
import useInput from "@/hooks/useInput";
import useR from "@/hooks/useR";
import React from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "@/service/timenjoy.service";
import { UpdateUser } from "@/types/timenjoy.types";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import useValidation from "@/hooks/useValidation";
import { schema } from "./schema";
import { captureException } from "@/service/exceptions";

const OnboardingUser: React.FC = () => {
  const queryClient = useQueryClient();
  const { user } = useR();
  const toast = useToast();
  const firstname = useInput(user?.firstname);
  const lastname = useInput(user?.lastname);
  const phone = useInput(user?.phone);
  const navigate = useNavigate();
  const [isValid, validate] = useValidation(schema);

  const mutation = useMutation({
    async mutationFn({ firstname, lastname, phone }: UpdateUser) {
      return updateUser(user!.id, { firstname, lastname, phone });
    },
    onSuccess(data) {
      queryClient.setQueryData(["user"], data);
      navigate("/onboarding/invite");
    },
    onError(error, variables, context) {
      captureException(error, { extra: { variables, context } });
      console.error(error);
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  React.useEffect(() => {
    validate(
      {
        firstname: firstname.value,
        lastname: lastname.value,
        phone: phone.value,
      },
      true
    );
  });
  React.useEffect(() => {
    if (!user) navigate("/login");
  }, [user]);

  function submit(e?: React.FormEvent) {
    e?.preventDefault();
    const payload = {
      firstname: firstname.value,
      lastname: lastname.value,
      phone: phone.value,
    };
    if (validate(payload)) mutation.mutate(payload);
  }

  return (
    <div className="max-w-2xl m-auto w-full flex flex-col">
      <h3 className="text-title text-2xl font-bold sm:text-3xl">Mes Infos</h3>
      <p className="mt-4 max-w-lg text-subtitle">
        Bienvenue dans le monde Konnectz&nbsp;! Pour commencer, renseignez vos
        informations personnelles ci-dessous. Cela ne prendra que quelques
        secondes.
      </p>
      <form
        onSubmit={submit}
        className="max-w-2xl grid grid-cols-2 gap-x-8 gap-y-6 mt-8 text-subtitle"
      >
        <div className="space-y-5">
          <label className="font-medium">Nom</label>
          <TextInput {...lastname} placeholder="Nom" />
        </div>
        <div className="space-y-5">
          <label className="font-medium">Prénom</label>
          <TextInput {...firstname} placeholder="Prénom" />
        </div>
        <div className="space-y-5">
          <label className="font-medium">Téléphone</label>
          <TextInput {...phone} placeholder="Téléphone" />
        </div>
        <div className="space-y-5">
          <label className="font-medium">Email</label>
          <TextInput value={user?.email} disabled />
        </div>
      </form>
      <Button
        className="self-end ml-auto !mt-9 flex justify-center items-center gap-2 font-semibold"
        children={[
          <div />,
          <span>Suivant</span>,
          <ChevronRightIcon className="w-5 h-5" />,
        ]}
        disabled={!isValid}
        clickThroughDisabled
        onClick={() => submit()}
      />
    </div>
  );
};

export default OnboardingUser;
