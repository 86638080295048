import React from "react";
import TypeSelectInput from "../inputs/type-select";
import SearchButton from "../button/search-button";
import useInput from "@/hooks/useInput";

type Props = {
  close: () => void;
};

const SearchOverlay: React.FC<Props> = ({ close }) => {
  const search = useInput("");
  return (
    <div
      className="absolute max-h-screen inset-0 bg-neutral-200/10 flex justify-center items-start px-12 pt-9 pb-12"
      onClick={close}
    >
      <div
        className="bg-neutral-50 p-3 rounded-[32px] rounded-b-2xl shadow-lg max-h-full flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <SearchButton
          {...search}
          placeholder="Rechercher"
          className="max-w-screen-sm w-screen shadow-sm"
        />
        <div className="mt-3 pt-3 h-full grow shrink overflow-scroll">
          {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((e) => {
            return (
              <div className="cursor-pointer rounded-xl bg-white border border-neutral-200 p-2 mt-2 hover:bg-greenenjoy_dark hover:text-white">
                {e}
                <br />
                {e}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
