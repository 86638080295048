import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
export interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> { }

const TextArea: React.FC<TextAreaProps> = ({
  className,
  disabled,
  ...props
}) => {
  return (
    <div className="relative">
      {disabled ? (
        <div className="absolute inset-0 z-2 cursor-not-allowed" />
      ) : null}
      <textarea
        {...props}
        className={twMerge(
          clsx(
            "w-full p-3 text-title bg-neutral-500/50 outline-none border border-konnectz_background focus:border-indigo-600 shadow-sm rounded-lg",
            className
          )
        )}
      />
    </div>
  );
};

export default TextArea;
