import React, { useMemo } from "react";
import { ComputerDesktopIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { cleanName } from "@/utils/name";
import clsx from "clsx";
import useR from "@/hooks/useR";
import Popover from "../popover";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";
import WidgetSwitchItem from "../list-item/widget-switch-item";
import { TextInput } from "@tremor/react";
import useInput from "@/hooks/useInput";

export interface WidgetHeadlineProps {
  hideIfSingle?: true
  className?: string
};

const WidgetHeadline: React.FunctionComponent<WidgetHeadlineProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const search = useInput("")
  const [reference, setReference] = React.useState<Element | null | undefined>(
    null
  );
  const { currentApiAccount, apiAccounts, client } = useR();
  const { name, white_list } = currentApiAccount ?? {};

  React.useEffect(() => {
    setOpen(false)
  }, [currentApiAccount])

  React.useEffect(() => {
    if (!open) search.onChange("")
  }, [open])

  const widgets = useMemo(() => {
    return apiAccounts.map((apiAccount) => {
      return client.getQueryData<TimenjoyApiAccount>([
        "apiAccount",
        apiAccount,
      ]);
    }).filter(Boolean) as TimenjoyApiAccount[];
  }, [apiAccounts, client]);

  if (!currentApiAccount) return null;

  const filteredWidgets = widgets.filter((widget) => {
    return widget.name.toLowerCase().includes(search.value.toLowerCase())
  })

  if (props.hideIfSingle && widgets.length < 2) return null;

  return (
    <div
      className={clsx("flex items-center text-title relative min-w-[300px] z-10 w-fit py-2 px-4 -mb-2 -mx-4 rounded-md", props.className, widgets.length > 1 && "hover:bg-background_light cursor-pointer", props.className)}
      onClick={() => setOpen((a) => !a)}
      ref={setReference}
    >
      <div className="w-10 h-10 bg-emphasis rounded-full mr-4 flex justify-center items-center">
        <ComputerDesktopIcon className="w-[22px] h-[22px] " />
      </div>
      <div className="flex flex-col">
        <span className="font-semibold flex items-center gap-2">{cleanName(name)} {widgets.length > 1 && (
          <ArrowsRightLeftIcon className="w-4 h-4 text-emphasis relative top-0.5" />
        )}
        </span>
        <span className="text-sm">
          {white_list?.[0]?.url ?? "Pas de site connecté"}
        </span>
      </div>
      {widgets.length > 1 && (
        <Popover
          placement="bottom-start"
          isOpen={open}
          setIsOpen={setOpen}
          reference={reference}
          noArrow
        >
          <div
            className="shadow-md w-96 rounded-md border-background_light border bg-konnectz_background p-2 text-white flex flex-col gap-1 max-h-96 overflow-y-auto noscrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <TextInput icon={MagnifyingGlassIcon} {...search} placeholder="Rechercher..." />
            {!filteredWidgets.length && (
              <span className="text-sm text-center mt-1">Aucun widget trouvé</span>
            )}
            {filteredWidgets.map(widget => {
              return <WidgetSwitchItem {...widget} close={() => setOpen(false)} key={widget.id} />
            })}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default WidgetHeadline;


