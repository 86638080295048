import { z } from "zod";

export const schema = z.strictObject({
  zones: z.array(
    z.string({
      // errorMap() {
      //   return {
      //     message: "Vous devez choisir un département.",
      //   };
      // },
    }),
  ),
  compositions: z
    .array(z.string())
    .min(1, "Vous devez choisir au moins un département et une catégorie.")
    .optional(),
  name: z
    .string({
      errorMap() {
        return {
          message: "Vous devez nommer votre widget.",
        };
      },
    })
    .min(2),
  traffic: z
    .string({
      errorMap() {
        return {
          message: "Vous devez nommer votre widget.",
        };
      },
    })
    .optional(),
  type: z.string({
    errorMap() {
      return {
        message: "Vous devez choisir un type de widget.",
      };
    },
  }),
  website: z
    .string({
      errorMap() {
        return {
          message: "Vous devez saisir un site web valide.",
        };
      },
    })
    .url(),
  useCompanyAds: z.boolean().optional(),
});
