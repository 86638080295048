import { ADVERTISEMENT_TYPE, ADVERTISEMENT_TYPE_LABELS, fullEventSchema, useBuildingEvent } from '@/hooks/useBuildingEvent';
import useValidation from '@/hooks/useValidation';
import { displayDatetimeRange } from '@/utils/display-datetime-range';
import { CalendarDaysIcon, MapPinIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import Button from '../button';
import { useSaveEvent } from '@/mutations/save-event';
import Spinner from '../spinner';
import { useLocation } from 'react-router-dom';

interface IEventPreviewProps {
}

const EventPreview: React.FunctionComponent<IEventPreviewProps> = (props) => {
    const event = useBuildingEvent()
    const pathname = useLocation().pathname
    const [isValid, validate] = useValidation(fullEventSchema)
    React.useEffect(() => { validate(event, true) }, [event])
    const saveEvent = useSaveEvent()

    function submit() {
        if (validate(event))
            saveEvent.mutate(event)
    }

    return <div className='h-full flex flex-col gap-2 overflow-y-auto noscrollbar'>
        <h1 className='mb-6'>Résumé</h1>
        {event.picture ? <img
            src={event.picture} alt=""
            className='w-full aspect-[2_/_1] bg-background_light rounded-md border-none outline-none'
        /> : <div className='w-full aspect-[2_/_1] bg-background_light rounded-md border-none outline-none' />}
        <h1>
            {event.title}
        </h1>
        {event.type === ADVERTISEMENT_TYPE.IMMO ?
            (<div className='flex flex-wrap gap-4'>
                <h4 className='flex gap-2'>
                    Surface: {event.surface}
                </h4>
                <h4 className='flex gap-2'>
                    Nombre de pièces: {event.rooms}
                </h4>
                <h4 className='flex gap-2'>
                    Prix: {event.price}
                </h4>
            </div>) :
            event.type === ADVERTISEMENT_TYPE.EMPLOI ?
                (<h4 className='flex gap-2'>
                    Société: {event.entreprise}
                </h4>) :
                (<>
                    {event.date && event.datefin && <h4 className='flex gap-2'>
                        <CalendarDaysIcon className="w-5 h-5" /> {displayDatetimeRange(event.date, event.datefin)}
                    </h4>}
                </>)}
        {event.place && <h4 className='flex gap-2' title={event.address}>
            <MapPinIcon className="w-5 h-5" /> {event.place}
        </h4>}
        {(!event.type || event.type === ADVERTISEMENT_TYPE.EVENT) && (
            <p className='w-full overflow-hidden line-clamp-6' dangerouslySetInnerHTML={{ __html: event.description ?? (event.id ? 'Pas de description' : "") }} />
        )}
        {/* <div className="h-full shrink w-full" /> */}
        <div className='mt-4 line-clamp-1'>
            <a href={event.url_ticketing} target="_blank" rel="noreferrer">
                {event.url_ticketing}
            </a>
        </div>
        {(!event.type || event.type === ADVERTISEMENT_TYPE.EVENT) && (
            <div className='flex w-full flex-wrap gap-2 items-center' >
                {event.categories?.map(({ name, id }) => {
                    return (<div
                        key={id}
                        className="px-[7px] py-1.5 bg-fuchsia-700 rounded-sm justify-start items-center gap-2.5 flex"
                    >
                        <div className="text-white text-[10px] font-medium tracking-tight">
                            {name}
                        </div>
                    </div>)
                })}
            </div>
        )}
        <span className='bg-white text-konnectz_background rounded py-1 px-2 text-sm font-semibold mr-2 w-fit'>{ADVERTISEMENT_TYPE_LABELS[event.type ?? ADVERTISEMENT_TYPE.EVENT]}</span>
        <div className='w-full h-0 grow' />
        <Button
            variant='primary'
            className="w-full py-3 px-5 flex items-center justify-center gap-2"
            onClick={submit}
            disabled={saveEvent.isLoading || !isValid}
            clickThroughDisabled
        >
            {event.id ? "Mettre à jour" : "Enregistrer"}
            {saveEvent.isLoading && <Spinner className='h-4 w-4 fill-emphasis' />}
        </Button>
    </div>
};

export default EventPreview;
