import Button from "@/components/button";
import EmailInput from "@/components/inputs/email";
import PasswordInput from "@/components/inputs/password";
import useInput from "@/hooks/useInput";
import useValidation from "@/hooks/useValidation";
import { register } from "@/service/auth.service";
import { LoginInvalidPassword } from "@/service/timenjoy.service";
import { clearQueryClient } from "@/utils/clear-query-client";
import { useToast } from "@chakra-ui/react";
import { captureException } from "@/service/exceptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import deepEqual from "deep-equal";
import React, { useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const RegisterPage: React.FC = () => {
  const toast = useToast();
  const form = useRef<HTMLFormElement>(null);
  const [searchParams] = useSearchParams()
  const email = useInput(searchParams.get("email") ?? undefined);
  const password = useInput();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const lastSent = useRef({ email: "", password: "" });
  const [payloadValid, validate] = useValidation(z.object({
    email: z.string().email(),
    password: z.string().min(12),
  }))
  React.useEffect(() => {
    validate({ email: email.value, password: password.value }, true)
  }, [email, password])
  const registerMutation = useMutation({
    async mutationFn() {
      const payload = { email: email.value, password: password.value };
      lastSent.current = payload;
      return register(payload);
    },
    onError(error, variables, context) {
      toast.closeAll();
      if (error instanceof LoginInvalidPassword) {
        return toast({
          title: "Un utilisateur avec cet email existe déjà",
          status: "info",
          duration: 9000,
          isClosable: true,
        });
      }
      captureException(error, { extra: { variables, context } });
      toast({
        title: "Une erreur est survenue",
        description: "Veuillez réessayer ultérieurement",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
    onSuccess(data, variables, context) {
      const { user, company, apiAccounts, auth } = data;
      clearQueryClient(queryClient);
      queryClient.setQueryData(["userID"], user.id);
      queryClient.setQueryData(["user"], user);
      queryClient.setQueryData(["company"], company);
      queryClient.setQueryData(
        ["apiAccounts"],
        apiAccounts?.map((a) => a.id) ?? []
      );
      apiAccounts?.forEach((a) => {
        queryClient.setQueryData(["apiAccount", a.id], a);
      });
      queryClient.setQueryData(["auth"], auth);
      navigate("/app", { replace: true });
    },
    retry: false,
  });

  function submit(e?: React.FormEvent) {
    e?.preventDefault();
    if (registerMutation.isLoading) return;
    if (
      registerMutation.isError &&
      deepEqual(
        { email: email.value, password: password.value },
        lastSent.current
      )
    )
      return;
    registerMutation.mutate();
  }
  return (
    <main className="w-full h-full flex flex-col items-center justify-center pb-10 sm:pb-16">
      <div className="max-w-screen-2xl text-subtitle grid lg:grid-cols-2 gap-x-16 gap-y-8">
        <div className="max-w-sm h-min my-auto">
          <img
            src={import.meta.env.BASE_URL + "img/logo.png"}
            width={150}
          // className="mx-auto"
          />
          <div className="mt-5 space-y-4">
            <h3 className="text-title text-2xl font-bold sm:text-3xl">
              Bienvenue à bord
            </h3>
            <p className="">
              Bienvenue sur Konnectz, la plus grande base de données
              d’événements et d’activités pour les médias et acteurs du tourisme
              en France.
            </p>
            <p className="">
              Créer un compte gratuitement en quelques secondes et intégrer
              votre premier widget sur votre site, dès maintenant 👊🏻
            </p>
          </div>
        </div>
        <div className="max-w-sm lg:border lg:border-background_light lg:bg-background_light/50 lg:shadow-xl lg:rounded-lg lg:py-5 lg:px-8">
          <form ref={form} onSubmit={submit} className="space-y-5">
            <div>
              <label className="font-medium">Email</label>
              <EmailInput
                className="w-full mt-2 lg:!bg-konnectz_background"
                {...email}
              />
            </div>
            <div>
              <label className="font-medium">Mot de passe</label>
              <PasswordInput
                className="w-full mt-2 lg:!bg-konnectz_background"
                {...password}
              />
            </div>
            <input type="submit" className="hidden" />
          </form>
          <Button
            className="w-full !mt-8"
            children="S'inscrire"
            disabled={!payloadValid || registerMutation.isLoading || deepEqual(
              { email: email.value, password: password.value },
              lastSent.current
            )}
            clickThroughDisabled
            onClick={() => submit()}
          />
          <p className="text-center mt-4">
            Déjà un compte ?{" "}
            <Link
              to="/auth/login"
              className="font-medium text-emphasis hover:text-emphasis_light"
            >
              Je me connecte
            </Link>
          </p>
        </div>
      </div>
    </main>
  );
};

export default RegisterPage;
