export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result.replace("application/json", "image/jpeg"));
      } else {
        reject(new Error("Could not read blob"));
      }
    };
    reader.readAsDataURL(blob);
  });
}
