import { UseInputHandler } from "@/hooks/useInput";
import { standardize } from "@/utils/standardize";
import clsx from "clsx";
import { useEffect, useId, useRef, useState } from "react";

type Props = {
  value: string;
  onChange: UseInputHandler["onChange"];
  options?: string[];
  placeholder?: string;
  emptyMessage?: string;
  onSelect?: (value: string) => void;
  disabled?: boolean;
};

const TypeSelectInput: React.FC<Props> = ({
  options = [],
  placeholder = "Type to search",
  value,
  onChange: _onChange,
  onSelect: _onSelect,
  emptyMessage = "Not results available",
  disabled = false,
}) => {
  const id = useId();

  const [state, _setState] = useState(false);
  function setState(value: boolean) {
    if (disabled) return;
    _setState(value);
  }
  function onChange(value: string) {
    if (disabled) return;
    _onChange(value);
  }
  function onSelect(value: string) {
    if (disabled) return;
    _onSelect?.(value);
  }
  const [val, setVal] = useState("");
  useEffect(() => {
    setVal(value);
  }, [value]);
  useEffect(() => {
    if (!state) setVal(value);
  }, [state, value]);
  const listboxRef = useRef<HTMLUListElement>(null);

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setVal(e.target.value);
    const alrtEl = document.getElementById("li-alert")!;
    const handleAlert = () => {
      if (listboxRef.current && listboxRef.current.offsetHeight < 5)
        alrtEl.classList.remove("hidden");
      else alrtEl.classList.add("hidden");
    };
    handleAlert();
    setTimeout(() => handleAlert(), 100);
  };

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target as HTMLElement;
      if (!target?.closest(".label-button")) setState(false);
    };
  }, []);

  return (
    <div id={"id_" + id} className={clsx("relative max-w-md text-base", disabled && 'cursor-not-allowed')}>
      <div className="label-button flex items-center gap-1 px-2 py-1 bg-neutral-500/50 outline-none border border-konnectz_background focus-within:border-indigo-600 rounded-lg shadow-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-subtitle"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          placeholder={placeholder}
          className={clsx("w-full px-2 py-2 text-title bg-transparent rounded-md outline-none", disabled && 'cursor-not-allowed pointer-events-none')}
          value={val}
          onChange={handleSearch}
          onFocus={() => setState(true)}
        />
        {val ? (
          <button
            type="button"
            onClick={() => {
              onChange("");
              onSelect?.("");
              setState(false);
            }}
            className={clsx(disabled && 'cursor-not-allowed')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 text-title"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        ) : (
          <button type="button" onClick={() => setState(!state)} className={clsx(disabled && 'cursor-not-allowed')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 text-title"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
      </div>

      {state ? (
        <div className="relative w-full z-50">
          <ul
            ref={listboxRef}
            className="absolute w-full mt-3 overflow-y-auto bg-konnectz_background outline-none border border-konnectz_background focus-within:border-indigo-600 rounded-md shadow-sm max-h-64"
            role="listbox"
          >
            <li
              id="li-alert"
              className="hidden px-3 py-2 text-center text-title bg-neutral-500/50"
            >
              {emptyMessage}
            </li>
            {options
              .filter((o) =>
                standardize(o).includes(standardize(val))
              )
              .map((el, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    onChange(el);
                    onSelect?.(el);
                  }}
                  role="option"
                  aria-selected={value === el ? true : false}
                  className={`bg-neutral-500/50 menu-el-js flex items-center justify-between px-3 py-2 cursor-pointer duration-150 text-title hover:text-blue_konnectz hover:bg-indigo-5`}
                >
                  {el}
                  {value === el ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-emphasis"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                </li>
              ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TypeSelectInput;
