import React from "react";
import SelectionMenu, {
  IActionButtonProps,
} from "../../context-menu/selection-menu/v2";
import dayjs from "dayjs";
import clsx from "clsx";
import { Reorder } from "framer-motion";
import { displayDates } from "@/utils/display-dates";
import { useQuery } from "@tanstack/react-query";
import { getEventById } from "@/service/timenjoy.service";
import useR from "@/hooks/useR";

interface IListItemProps extends Omit<IActionButtonProps, "lockDrag"> {
  drag: boolean;
}

const SelectionItem: React.FunctionComponent<IListItemProps> = (props) => {
  const [dradLock, setDragLock] = React.useState(false);
  const { company } = useR();
  const event = useQuery({
    queryKey: ["event", props.advertisement.timenjoyId],
    queryFn: async () => {
      return getEventById(props.advertisement.timenjoyId);
    },
    enabled: !!props.advertisement.timenjoyId,
  });

  const isOwner = event.data?.company_events?.[0]?.company_id === company?.id;

  return (
    <Reorder.Item
      value={props.id}
      key={props.id}
      as="li"
      dragListener={props.drag && !dradLock}
      animate={false}
      className="hover:bg-secondary flex w-full cursor-pointer select-none items-center gap-2 rounded-md"
    >
      <div className="w-60 shrink-0 grow text-left">
        <div className="flex h-full w-full items-center justify-start pl-2">
          <img
            src={`https://konnectz.timenjoy.fr/pictures/${props.picture}.webp`}
            alt={props.name}
            className="my-1 h-10 w-20 rounded-sm"
          />
          <span className="ml-6 w-full text-ellipsis">{props.name}</span>
        </div>
      </div>
      <div
        className={clsx(
          "w-24 shrink-0 text-right",
          props.sponsorized || "hidden",
        )}
      >
        <div className="bg-konnectz_accent flex w-fit items-center justify-start rounded-sm px-2 py-1.5">
          <div className="text-xs font-medium tracking-tight text-white">
            Sponsorisé
          </div>
        </div>
      </div>
      <div
        className="w-40 shrink-0"
        title={`${dayjs(props.from).toISOString()} ${dayjs(props.to).toISOString()}`}
      >
        {displayDates(props.from, props.to)}
      </div>
      <div className="w-52 shrink-0 text-ellipsis">{props.location}</div>
      <div className="w-20 shrink-0">
        <div className="flex h-full w-full items-center justify-center pr-5">
          <SelectionMenu {...props} editable={isOwner} lockDrag={setDragLock} />
        </div>
      </div>
    </Reorder.Item>
  );
};

export default SelectionItem;
