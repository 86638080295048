import { TimenjoyEvent } from "@/types/timenjoy.types";
import React from "react";
import { z } from "zod";

export enum ADVERTISEMENT_TYPE {
  EVENT = "event",
  IMMO = "immo",
  EMPLOI = "emploi",
}

export const ADVERTISEMENT_TYPE_LABELS = {
  [ADVERTISEMENT_TYPE.EVENT]: "Événement",
  [ADVERTISEMENT_TYPE.IMMO]: "Immobilier",
  [ADVERTISEMENT_TYPE.EMPLOI]: "Emploi",
};
export interface BuildingEvent
  extends Omit<
    TimenjoyEvent,
    | "categories"
    | "translations"
    | "origin"
    | "inevitable"
    | "inevitableRate"
    | "price_order_id"
    | "chatbot"
    | "site"
    | "url_game"
    | "url_data_studio"
    | "createdAt"
    | "updatedAt"
    | "tags"
    | "company_events"
    | "coordinate"
    | "occurences"
    | "nextDate"
    | "nextDatefin"
    | "id"
  > {
  type?: ADVERTISEMENT_TYPE;
  id?: string;

  entreprise?: string;
  surface?: string;
  rooms?: string;
  price?: string;

  categories: {
    name: string;
    id: string;
  }[];
  address: string;
  lng: number;
  lat: number;
  raw_picture: string;
}

export interface BuildingEventContextType extends Partial<BuildingEvent> {
  update: React.Dispatch<React.SetStateAction<Partial<BuildingEvent>>>;
}

export const BuildingEventContext =
  React.createContext<BuildingEventContextType>({
    published: true,
    update() {},
  });

export const useBuildingEvent = () =>
  Object.freeze(React.useContext(BuildingEventContext));

export const contentEventSchema = z.object({
  title: z
    .string({
      required_error: "Vous devez ajouter un titre à l'événement",
    })
    .min(5, "Le titre doit faire au moins 5 caractères")
    .max(60, "Le titre ne doit pas dépasser 60 caractères"),
  description: z
    .string()
    .max(5000, "La description ne doit pas dépasser 5000 caractères")
    .nullish()
    .optional(),
  picture: z
    .string({
      required_error:
        "Vous devez ajouter une image de couverture à l'événement",
    })
    .url("Image invalide"),
});

export const locationEventSchema = z.object({
  date: z
    .string({
      required_error: "Vous devez ajouter une date de début à l'événement",
    })
    .datetime("Date invalide"),
  datefin: z
    .string({
      required_error: "Vous devez ajouter une date de fin",
    })
    .datetime("Date invalide"),
  place: z.string({
    required_error: "Vous devez ajouter un lieu à l'événement",
  }),
  address: z.string({
    required_error: "Vous devez ajouter une adresse à l'événement",
  }),
  lat: z.number({
    required_error: "Vous devez ajouter une adresse à l'événement",
  }),
  lng: z.number({
    required_error: "Vous devez ajouter une adresse à l'événement",
  }),
});

export const infosEventSchema = z.object({
  categories: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      }),
      {
        required_error:
          "Vous devez ajouter au moins une catégorie à l'événement",
      }
    )
    .min(1, "Vous devez ajouter au moins une catégorie à l'événement"),
  url_ticketing: z
    .string({
      required_error: "Vous devez ajouter une url à l'événement",
    })
    .url("Url invalide"),
  published: z.boolean().default(false),
});

export const fullEventSchema = contentEventSchema
  .and(locationEventSchema)
  .and(infosEventSchema)
  .and(
    z.object({
      id: z.string().optional(),
    })
  );
