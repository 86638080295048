import { FloatingOverlay, FloatingPortal } from '@floating-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import ContextMenu from '../../modals/context-menu';
import { twMerge } from 'tailwind-merge';

interface IInfoBubbleProps {
    children: React.ReactElement | React.ReactElement[];
    className?: string;
}

const InfoBubble: React.FunctionComponent<IInfoBubbleProps> = (props) => {
    const [reference, setReference] = React.useState<Element | null>(null);
    const [open, setOpen] = React.useState(false);
    return <>
        <InformationCircleIcon className={twMerge("w-4 h-4 ml-1 text-emphasis cursor-pointer", props.className)} onClick={() => setOpen(a => !a)} ref={setReference} />
        <ContextMenu isOpen={open} setIsOpen={setOpen} reference={reference} overlayClassName='z-50'>
            {props.children}
        </ContextMenu>
    </>
};

export default InfoBubble;
