import {
  BaseComposition,
  CreateSelectionPayload,
  Selection,
  UpdateSelectionPayload,
  Zone,
} from "@/types/manager.types";
import { getToken } from "./auth.service";
import { AUTH_URL } from "./environment";

// const MANAGER_URL = "https://tnj-smart-manager.oriun.fr/api";
const MANAGER_URL = `${AUTH_URL}/manager`;

export async function getZones() {
  const request = await fetch(`${MANAGER_URL}/zone/list`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      select: ["compositions"],
    }),
  });
  if (!request.ok) {
    throw new Error("Error while fetching zones");
  }
  const response = await request.json();
  return response.filter((z: Zone) => z.base_compositions.length) as Zone[];
}

export async function getCompositionsForZone(...zoneId: string[]) {
  const request = await fetch(`${MANAGER_URL}/base_composition/list`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      zoneId: zoneId.length ? zoneId : undefined,
    }),
  });
  if (!request.ok) {
    throw new Error("Error while fetching compositions for zone");
  }
  const response = await request.json();
  response.sort((a: BaseComposition, b: BaseComposition) => {
    if (a.name === "Les meilleurs événements") {
      return -1;
    }
    if (b.name === "Les meilleurs événements") {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return response as BaseComposition[];
}

export async function createSelection(payload: CreateSelectionPayload) {
  const request = await fetch(`${MANAGER_URL}/selection`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!request.ok) {
    throw new Error("Error while creating selection");
  }
  const response = await request.json();
  return response as Selection;
}

export async function createExclusionConfig(selectionId: string) {
  const request = await fetch(`${MANAGER_URL}/selection/${selectionId}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!request.ok) {
    throw new Error("Error while creating selection");
  }
  const response = await request.json();
  return response as Selection;
}

export async function associateCompanyConfig(
  selectionId: string,
  companyId: string,
) {
  const request = await fetch(`${MANAGER_URL}/company_config`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      selectionId,
      companyId,
    }),
  });
  if (!request.ok) {
    throw new Error("Error while associating selection");
  }
  return true;
}

export async function dissociateCompanyConfig(
  selectionId: string,
  companyId: string,
) {
  const request = await fetch(
    `${MANAGER_URL}/company_config?selectionId=${selectionId}&companyId=${companyId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    },
  );
  if (!request.ok) {
    throw new Error("Error while associating selection");
  }
  return true;
}

export async function updateSelection(
  id: string,
  payload: UpdateSelectionPayload,
) {
  const request = await fetch(`${MANAGER_URL}/selection/${id}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!request.ok) {
    throw new Error("Error while creating selection");
  }
  const response = await request.json();
  if (!response.length) {
    throw new Error("Error while updating selection");
  }
  return response[0] as Selection;
}

export async function getWidgetTypes() {
  const request = await fetch(`${MANAGER_URL}/widget_types`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  if (!request.ok) {
    throw new Error("Error while fetching widget types");
  }
  const response = await request.json();
  return response as string[];
}

import { createCache, Cache } from "async-cache-dedupe";

const cache = createCache({
  ttl: 30 * 60, // seconds
  // stale: 5 * 60, // number of seconds to return data after ttl has expired
}) as Cache & {
  getSelectionByAppSecret: typeof _getSelectionByAppSecret;
};

async function _getSelectionByAppSecret(app_secret: string) {
  // console.log("_getSelectionByAppSecret", app_secret);
  if (!app_secret) return null;
  const request = await fetch(`${MANAGER_URL}/selection/list`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      app_secret: [decodeURIComponent(app_secret)],
      select: ["compositions"],
    }),
  });
  if (!request.ok) {
    throw new Error("Error while fetching widget types");
  }
  const [response] = await request.json();

  if (!response) {
    return null;
  }

  return response as Selection;
}

cache.define(
  "getSelectionByAppSecret",
  {
    references: (args) => [`selection:${args}`],
  },
  _getSelectionByAppSecret,
);
cache.clear("getSelectionByAppSecret");
export async function refreshSelectionByAppSecret(app_secret: string) {
  // console.log("refreshSelectionByAppSecret", app_secret);
  if (!app_secret) throw new Error("App secret is required");
  await cache.invalidateAll(`selection:${app_secret}`);
  await cache.clear("getSelectionByAppSecret");
  // console.log("invalidated", `selection:${app_secret}`);
  return cache.getSelectionByAppSecret(app_secret);
}

export async function getSelectionByAppSecret(app_secret: string) {
  if (!app_secret) throw new Error("App secret is required");
  // console.log("getSelectionByAppSecret", app_secret);
  const response = await cache.getSelectionByAppSecret(app_secret);
  if (!response) {
    throw new Error("Selection not found");
  }
  return response;
}
