import * as React from "react";

interface MainWorkPlaceSizeContextType {
  width: number;
  height: number;
}
export const MainWorkPlaceSizeContext =
  React.createContext<MainWorkPlaceSizeContextType>({
    width: 0,
    height: 0,
  });

export const useMainWorkPlaceSize = () =>
  React.useContext(MainWorkPlaceSizeContext);

export const MainWorkPlaceSizeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [size, setSize] = React.useState({ width: 0, height: 0 });
  const ref = React.useRef<HTMLDivElement>(null);
  const child = React.useMemo(() => {
    return React.cloneElement(children as any, { ref });
  }, [children]);
  React.useEffect(() => {
    const element = ref.current;
    if (!element) return;
    const resizeObserver = new ResizeObserver(() => {
      const rect = element.getBoundingClientRect();
      if (!rect) return;
      const parentPadding = window.getComputedStyle(element).padding;
      let [top, right, bottom, left] = parentPadding
        .split(" ")
        .map((v) => parseInt(v, 10));
      right ??= top;
      bottom ??= top;
      left ??= right;
      setSize({
        width: rect.width - left - right,
        height: rect.height - top - bottom,
      });
    });
    resizeObserver.observe(element);
  }, []);
  return (
    <MainWorkPlaceSizeContext.Provider value={size}>
      {child}
    </MainWorkPlaceSizeContext.Provider>
  );
};
